import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import UploadIcon from '@mui/icons-material/Upload';
import {
    applicationType2List, applicationTypeOptions,
    CASE_FIELDS,
    CASE_TYPE_DESIGN,
    CASE_TYPE_INFRINGEMENT,
    CASE_TYPE_OPPOSITION,
    CASE_TYPE_PATENT,
    CASE_TYPE_TRADEMARK,
    caseTypeOptions,
    compactTable,
    PCTBased, UtilityModel
} from "../CaseList";
import {
    AutocompleteInput,
    Button,
    ChipField,
    Datagrid,
    DateField,
    DateInput,
    FileField,
    FileInput,
    Form,
    FormDataConsumer,
    FunctionField,
    ListContextProvider,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    useDataProvider,
    useGetList,
    useList,
    useNotify,
    useRecordContext
} from "react-admin";
import {Chip, Tooltip, Typography} from '@mui/material';
import {useFetchEPOPatentFamily} from "./useFetchEPOPatentFamily";
import {noRefetch, TenantContext} from "../../react-admin-overrides/AppLayout";
import {Box, Stack} from "@mui/system";
import {CreateInDialogButton} from "@react-admin/ra-form-layout";
import {useTenantId} from "../../data/useTenantId";
import {capitalizeFirstLetter, dateFormat, inputDateFormat} from "../actions/Actions";
import {useUserId} from "../../auth/utils";
import {alternateBackgroundColorPerCaseRef} from "./importCases";
// @ts-ignore
import * as XLSX from 'xlsx/xlsx.mjs';
import {ANNUITY, RENEWAL} from "../actions/utils";
import {
    DesignCaseInputFields,
    OppositionInputFields,
    PatentCaseInputFields,
    SimpleCaseTypeInputFields,
    TrademarkCaseInputFields
} from "../crud/CaseInputFields";
import moment from "moment";
import {entitySizeOptions} from "../../common/common-utils";
import {CASE_STATUS_CLOSED, CASE_STATUS_GRANTED, CASE_STATUS_PENDING} from "../crud/utils";
import { useForm } from "react-hook-form";
import { downloadImportReport } from "./importReportExporter";
import DownloadIcon from '@mui/icons-material/GetApp';

type MatchingData = {
    application_number: string,
    country_code: string,
}
const useMatchCases = () => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<Array<any> | null>(null)
    const [error, setError] = useState<any>(null)

    const checkMatchingCases = (data: Array<MatchingData>, tenant_id: string) => {
        setLoading(true);
        Promise.allSettled(data.map(({ application_number, country_code }) => {
            return dataProvider.getList("cases", {
                pagination: { page: 1, perPage: 1 },
                filter: { application_number, "tenant_id@_eq": tenant_id, country_code: country_code },
                sort: { field: "case_ref", order: "ASC" }
            })
                .then((response: any) => {
                    return response.data;
                })
                .catch((error: any) => {
                    return error;
                })
        })).then((results: any) => {
            setData(results.map((result: any) => result.value?.[0]));
        });
    }
    return { loading, data, error, checkMatchingCases }
};


const countryApplicationNumberTransform: { [key: string]: { pre?: (_: string) => string, post?: (_: string) => string} }
    = {
    "cn": {
        pre: (applicationNumber: string) => {
            return applicationNumber.split(".")[0];
        }
    },
    "ep": {
        pre: (applicationNumber: string) => {
            return applicationNumber.split(".")[0];
        }
    }
}

function getApplicationNumber(line: any) {
    const applicationNumber = String(line["Application Number"]) || "";
    if (["UNKNOWN", ""].includes(applicationNumber.toUpperCase())) {
        return null;
    }
    const countryCode = String(line["Country Code"])?.toLowerCase() || "";
    const { pre, post } = countryApplicationNumberTransform[countryCode] || {};
    const preTransform = pre || ((_: string) => _);
    const postTransform = post || ((_: string) => _);

    const preTransformed = preTransform(applicationNumber);
    const defaultTransformed = preTransformed
        .replaceAll(countryCode, "")
        .replaceAll(countryCode.toUpperCase(), "")
        .replaceAll(" ", "")
        .replaceAll("/", "")
        // .replaceAll(/.\d/g, "")
        .replaceAll("-", "")
        .replaceAll(",", "")
        .split(".")[0];

    const postTransformed = postTransform(defaultTransformed.replaceAll(".", ""));
    return postTransformed;

}

function getRegistrationNumber(line: any) {
    const registrationNumber = line["Patent Number"] ? String(line["Patent Number"]) : "";
    if (["UNKNOWN", "", "N/A"].includes(registrationNumber?.toUpperCase())) {
        return null;
    }

    return registrationNumber
        .replaceAll(" ", "")
        .replaceAll("/", "")
        .replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll(",", "");
}

function getCaseType(line: any) {
    return ["Patent", "Utility Model"].includes(line["IP Type"]) ? CASE_TYPE_PATENT :
        ["Design"].includes(line["IP Type"]) ? CASE_TYPE_DESIGN : null;
}

export const ImportFromFile = (props: any) => {
    const { tenant, refetch: refetchSelectedTenantData }  = useContext(TenantContext) || {};
    const defaultTenantId = useTenantId();
    const userId = useUserId();
    const dataProvider = useDataProvider();
    const [tenantCaseSerialNumber, setTenantCaseSerialNumber] = useState(tenant?.next_case_ref_serial_number || 0);
    const [selectedTenant, setSelectedTenant] = useState(null);
    const [inputTenantId, setInputTenantId] = useState(defaultTenantId);
    const [sheetData, setSheetData] = useState<Array<any> | null>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const notify = useNotify();
    const {
        loading: isFetchingMatches,
        data: matchingCaseData,
        error: matchingCaseError,
        checkMatchingCases
    } = useMatchCases();
    const [formInput, setFormInput] = useState({
        case_team_id: null,
        family: true,
        files: null,
    });

    const {
        data: caseData,
        rawXml,
        error: searchError,
        loading: isSearching,
        makeApiCall: fetchPatentFamily,
        resetState: resetSearchResultsState
    } = useFetchEPOPatentFamily(tenantCaseSerialNumber);

    useEffect(() => {
        dataProvider.getOne("tenants", { id: inputTenantId }).then((response: any) => {
            setTenantCaseSerialNumber(response.data.next_case_ref_serial_number || 0);
            setSelectedTenant(response.data);
        });
    }, [inputTenantId]);


    useEffect(() => {
        console.log("files changed", formInput);
    }, [formInput.files]);

    useEffect(() => {
        console.log("sheetData changed", sheetData);
        if((sheetData || []).length > 0) {

        }
    }, [sheetData]);

    useEffect(() => {
        console.log("matchingCaseData changed", matchingCaseData);
        // console.log("matchingCaseData changed", matchingCaseData, (matchingCaseData || []).filter(removeFalsy));
        const matchingCases = (sheetData || [])
            .map((line: any, index: number) => ({
                ...line,
                matched_case: matchingCaseData?.[index]
            }))
            // .filter((line: any) => line.matched_case);

        setSheetData(matchingCases);

        // let duplicated: any[] = [];
        // matchingCases.forEach((line: any) => {
        //     const matches = matchingCases.filter((line2: any) => line.application_number === line2.application_number)
        //     if (matches.length > 1) {
        //         duplicated.push(line);
        //     }
        // })

        // console.log("Duplicate check", duplicated);


        // setSheetData((sheetData || []).map((line: any, index: number) => ({
        //     ...line,
        //     matched_case: matchingCaseData?.[index],
        // })));
    }, [matchingCaseData]);

    const onParseFile = (formData: any) => {
        console.log("onParseFile", formData);
        setSheetData(null);
        setIsLoading(true);
        const file = formData.file.rawFile;
        file.arrayBuffer()
            .then((arrayBuffer: any) => {
                const workbook = XLSX.read(arrayBuffer, { cellDates: true, cellStyles: true })
                const ws = workbook.Sheets[workbook.SheetNames[0]];
                const sheetData = XLSX.utils.sheet_to_json(ws, {skipHidden: true})
                    .filter((line: any) => formData?.case_type ?  line["IP Type"]?.startsWith(formData.case_type) : true)
                    .filter((line: any) => formData?.status ?  line["AQS Mgt Status"] === "WAIT" : true)
                    .filter((line: any) => formData.client_ref_starts_with ? line["Client Reference"]?.startsWith(formData.client_ref_starts_with) : true)
                    .map((line: any, index: number) => {
                        const registrationDate = line["Grant Date"] ? moment(line["Grant Date"]).format(inputDateFormat) : null;
                        return ({
                            id: index,
                            ...line,
                            country_code: line["Country Code"]?.toLowerCase(),
                            application_number: getApplicationNumber(line),
                            importObject: {
                                tenant_id: formData["tenant_id"],
                                tenant_case_ref: line["Client Reference"],
                                case_type: getCaseType(line),
                                case_ref: `${getCaseType(line)}${tenantCaseSerialNumber}${line["Country Code"]?.toUpperCase()}${line["Origin"] === "WO" ? "PC" : line["Origin"] || ""}00`,
                                country_code: line["Country Code"]?.toLowerCase(),
                                application_number: getApplicationNumber(line),
                                invention_title: line["Title"],
                                entity_size: line["EntityStatus"] ? capitalizeFirstLetter(line["EntityStatus"]?.toLowerCase() || "") : null,
                                expiration_date: line["Expiration Date"] ? moment(line["Expiration Date"]).format(inputDateFormat) : null,
                                application_type: line["IP Type"] === UtilityModel ? line["IP Type"] : PCTBased, // TODO: Vurder
                                [CASE_FIELDS.INTERNATIONAL_FILING_DATE]: line["Application Date"] ? moment(line["Application Date"]).format(inputDateFormat) : null,
                                [CASE_FIELDS.REGISTRATION_DATE]: registrationDate,
                                [CASE_FIELDS.REGISTRATION_NUMBER]: getRegistrationNumber(line),
                                application_type_2: applicationType2List.includes(line["Application Type"]) ? line["Application Type"] : null,
                                // Flener case team if tenant is Flener
                                case_team_id:
                                    formData["case_team_id"] ? formData["case_team_id"] :
                                    inputTenantId === "6730e5a1-e930-44fa-9791-dddf42fc4358" ? "f582b855-ec1a-4644-bfba-56b0a58bd67c" :
                                    inputTenantId === "5434df47-0298-4df3-84bc-d889d46af200" ? "bdd68dc5-b63d-48a1-8118-35be3c08560e" : "03f770bd-9039-465f-bf87-e4261ff84f6d",
                                status: registrationDate ? CASE_STATUS_GRANTED : CASE_STATUS_PENDING,
                            }
                        });
                    })

                setSheetData(sheetData);
                const applicationNumbers = (sheetData || []).map((line: any) => ({ application_number: line.application_number, country_code: line.country_code }));
                checkMatchingCases(applicationNumbers, inputTenantId || "");
                // checkMatchingCases(applicationNumbers.slice(0, 25));
                setIsLoading(false);
            });
    }
    const onTenantChaged = (value: any) => {
        if (value && value !== inputTenantId) {
            setInputTenantId(value);
        }
    }

    const createAll = () => {
        const createObjects = (sheetData || [])
            .filter((line: any) => !line.matched_case)
            .map((line: any, index: number) => line.importObject.case_type === CASE_TYPE_DESIGN ? designImportObject(line.importObject, inputTenantId || "") : line.importObject)
            .map((line: any, index: number) => ({ ...line, case_ref: `${line.case_type}${tenantCaseSerialNumber + index}${line.country_code.toUpperCase()}${line.origin ? line.origin.toUpperCase() : ""}00` }));
        console.log(createObjects);

        const nextSerialNumber = tenantCaseSerialNumber + createObjects.length;
        const confirmed = window.confirm(`Are you sure you want to create all ${createObjects.length} cases?`);

        if (confirmed) {
            notify("Creating cases", { type: "info" });
            Promise.allSettled(createObjects.map((createObject: any) => {
                return dataProvider.create("cases", {
                    data: createObject
                })
                    .then((response: any) => {
                        return response.data;
                    })
                    .catch((error: any) => {
                        return error;
                    })
            })).then((results: any) => {
                console.log("update results", results);
                const errors = results.filter((result: any) => result.status === "rejected");
                const successes = results.filter((result: any) => result.status === "fulfilled");
                notify(`Creating cases done. ${successes.length} created. ${errors.length} errors.`, { type: "info" });

                const applicationNumbers = (sheetData || []).map((line: any) => line.application_number);
                checkMatchingCases(applicationNumbers, inputTenantId || "");

                dataProvider.update("tenants", {
                    id: inputTenantId,
                    data: {
                        next_case_ref_serial_number: nextSerialNumber
                    },
                    previousData: selectedTenant,
                }).then((response: any) => {
                    setTenantCaseSerialNumber(response.data.next_case_ref_serial_number || 0);
                    setSelectedTenant(response.data);
                });
            });
        }
    }

    const onExport = () => {
        if (!sheetData) return;
        const exportData = sheetData
            .map((line: any) => {
                const matchingResult = dateComparison(line.matched_case, line.matched_case?.next_maintenance_case_action?.[0], line["Next Due Date"], line["AQS Last payment deadline"]);
                const comment = matchingResult?.comment ? `${matchingResult.comment}\n${line.matched_case?.import_comments || ""}` : line.matched_case?.import_comments || "";
                const bDueDate = line.matched_case ? line.matched_case.next_maintenance_case_action?.[0]?.due_date || "" : "";
                const bGraceDate = line.matched_case ? line.matched_case.next_maintenance_case_action?.[0]?.with_fine_due_date || "" : "";
                const dueDateCompare =
                    bDueDate || line["Next Due Date"] ?
                    `B: ${bDueDate ? moment(bDueDate).format(dateFormat) : ""}\nA: ${line["Next Due Date"] ? moment(line["Next Due Date"]).format(dateFormat) : ""}`
                        : ""
                const graceDateCompare =
                    bGraceDate || line["AQS Last payment deadline"] ?
                    `B: ${bGraceDate ? moment(bGraceDate).format(dateFormat) : ""}\nA: ${line["AQS Last payment deadline"] ? moment(line["AQS Last payment deadline"]).format(dateFormat) : ""}`
                        : ""
                return {
                    ...line,
                    "Breeze Case Ref": line.matched_case ? line.matched_case.case_ref : "",
                    "Breeze status": line.matched_case ? line.matched_case.status : "",
                    "Breeze Applicant": line.matched_case ? line.matched_case.applicant : "",
                    "Breeze Application Number": line.matched_case ? line.matched_case.application_number : "",
                    "Breeze Registration Number": line.matched_case ? line.matched_case.registration_number : "",
                    "Breeze Status": line.matched_case ? line.matched_case.status : "",
                    "Breeze Next Due Date": line.matched_case ? bDueDate : "",
                    "Breeze Grace Due Date": line.matched_case ? bGraceDate : "",
                    "Due Date Compare": line.matched_case ? dueDateCompare : "",
                    "Due Date Match": bDueDate || line["Next Due Date"] ?
                        moment(bDueDate).format(dateFormat) === moment(line["Next Due Date"]).format(dateFormat) ? "success" : "warning"
                        : "",
                    "Grace Date Compare": line.matched_case ? graceDateCompare : "",
                    "Grace Date Match": bGraceDate || line["AQS Last payment deadline"] ?
                        moment(bGraceDate).format(dateFormat) === moment(line["AQS Last payment deadline"]).format(dateFormat) ? "success" : "warning"
                        : "",
                    "Breeze Comment": comment || "",
                    "Breeze Comment + JY instructions": line["Breeze Comment + JY instructions"],
                    "Breeze 020924": line["Breeze 020924"],
                    matchingResult,
                };
            })
            .map((line: any) => {
                const { id, importObject, country_code, application_number, matched_case, ...rest } = line;
                return rest;
            });

        downloadImportReport(exportData);
    }

    const submitSearch = (formData: any) => {
        setFormInput(formData);
    }
    const dataUnavailable = false;

    // console.log(sheetData);
    if (sheetData) {
        // const headers = [];
        // let nextColumn = "A1";
        // while (ws[nextColumn]?.v) {
        //     headers.push(ws[nextColumn].v);
        //     // generate next column of the excel header, meaning after A1 comes B1, then C1, etc.
        //     nextColumn = String.fromCharCode(nextColumn.charCodeAt(0) + 1) + "1";
        //     // console.log(nextColumn);
        // }
        // console.log(headers);
    }
    // console.log(caseData);

    const unmatched = (sheetData || []).filter((line: any) => !line.matched_case);
    const matched = (sheetData || []).filter((line: any) => line.matched_case);

    return (
        <div>
            <Typography variant={"h2"} component={"h1"} mb={5}>Import from Excel file</Typography>
            <Form record={formInput} onSubmit={onParseFile} >
                <Box sx={{ maxWidth: 300 }}>
                    <FileInput
                        sx={{ '& .RaFileInput-dropZone': { height: "100%", backgroundColor: "#eee", display: "flex", alignItems: "center", justifyContent: "center" }, height: "100%" }}
                        source="file" label="Flener Excel file" accept={{"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"]}} isRequired>
                        <FileField source="src" title="title"/>
                    </FileInput>
                    <TextInput source={"client_ref_starts_with"}></TextInput>
                    <TextInput source={"case_type"} defaultValue={""}></TextInput>
                    <TextInput source={"status"} defaultValue={""}></TextInput>
                    <ReferenceInput reference="tenants" source="tenant_id" label="Organisation" >
                        <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Organisation"} validate={required()} defaultValue={defaultTenantId} fullWidth onChange={onTenantChaged} />
                    </ReferenceInput>
                    <Box sx={{ maxWidth: 300 }}>
                        {
                            inputTenantId && (
                                <ReferenceInput source="case_team_id" reference="case_teams" filter={{ tenant_id: inputTenantId }} >
                                    <SelectInput source="case_team_id" validate={required()} optionText={"name"} fullWidth/>
                                </ReferenceInput>
                            )
                        }
                    </Box>
                    <Box mb={5}>Next case_ref serial number: {tenantCaseSerialNumber}</Box>
                    {/*{*/}
                    {/*    inputTenantId && (*/}
                    {/*        <ReferenceInput source="case_team_id" reference="case_teams" filter={{ tenant_id: inputTenantId }} >*/}
                    {/*            <SelectInput source="case_team_id" validate={required()} optionText={"name"} fullWidth/>*/}
                    {/*        </ReferenceInput>*/}
                    {/*    )*/}
                    {/*}*/}

                </Box>
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (!formData.file) {
                            return null;
                        }

                        return (
                            <>
                                <LoadingButton
                                    loading={isLoading}
                                    loadingPosition="start"
                                    startIcon={<UploadIcon />}
                                    variant="outlined"
                                    disabled={dataUnavailable}
                                    type={"submit"}
                                >
                                    Parse cases from file
                                </LoadingButton>
                            </>
                        )

                    }
                    }
                </FormDataConsumer>
                <Box mb={5}></Box>
                {
                    sheetData &&
                    <Stack mb={5}>
                        <Box>Number of lines: {sheetData?.length}</Box>
                        <Box>Number of matched cases: {matched?.length}</Box>

                        <Box>Lines without match: {unmatched?.length} {unmatched?.length > 0 ? <Button label={`Create All ${unmatched?.length}`} onClick={createAll} variant={"outlined"}/> : null}</Box>
                    </Stack>
                }
                <Stack direction={"row"}>
                    <Button label={"Excel Export"} onClick={onExport} endIcon={<DownloadIcon></DownloadIcon>}></Button>
                </Stack>
                <ImportPreviewDataGrid data={sheetData} inputTenantId={inputTenantId} ></ImportPreviewDataGrid>
            </Form>
        </div>
    );
};

const confirmedEndOfMonthDueDateCountries = ["NO", "EP", "DK", "DE", "SE"];
const annuitiesAfterGrantCountries = ["CN", "US", "KR", "JP", "IN", "MX", "CR", "RU", "TW", "IL"];
const renewalsAfterGrantCountries = ["CA"];
const dateComparison = (caseData: any, caseAction: any, sheetDueDate: string, sheetGraceDate: string) => {
    if (!caseData || !caseAction) return null;

    const ourDueDate = caseAction?.due_date;
    const ourDueDateMoment = caseAction?.due_date  ? moment(ourDueDate) : caseAction?.due_date;
    const ourGraceDate = caseAction?.with_fine_due_date;
    const ourGraceDateMoment = caseAction?.with_fine_due_date ? moment(ourGraceDate) : caseAction?.with_fine_due_date;
    const sheetDueDateMoment = moment(sheetDueDate);
    const sheetGraceDateMoment = moment(sheetGraceDate);

    if (!sheetDueDate && !ourDueDate && caseData.status === CASE_STATUS_PENDING) {
        if (caseData?.case_type === CASE_TYPE_PATENT && annuitiesAfterGrantCountries.includes(caseData.country_code?.toUpperCase())) {
            return {
                status: "MATCH",
                comment: "Annuities are to be paid only after grant - please inform us (grant date and registration number) when the application is granted",
                color: "success",
            }
        }
        if (caseData?.case_type === CASE_TYPE_DESIGN && renewalsAfterGrantCountries.includes(caseData.country_code?.toUpperCase())) {
            return {
                status: "MATCH",
                comment: "Renewals are to be paid only after grant - please inform us (grant date and registration number) when the application is granted",
                color: "success",
            }
        }
    }

    if (!ourDueDateMoment && !ourGraceDateMoment && !sheetDueDateMoment && !sheetGraceDateMoment) {
        return {
            status: "MATCH",
            comment: caseData?.case_type === CASE_TYPE_DESIGN ? "No renewals" : "No annuities",
            color: "success",
        }
    }

    if (!sheetDueDateMoment || !sheetGraceDateMoment) {
        return {
            status: "UNKNOWN",
            comment: caseData?.case_type === CASE_TYPE_DESIGN ?
                "No renewal in AQS sheet" :
                "No annuity in AQS sheet",
            color: "error",
        }
    }

    const dueDateEqual = ourDueDateMoment?.format(dateFormat) === sheetDueDateMoment?.format(dateFormat);
    const graceDateEqual = ourGraceDateMoment?.format(dateFormat) === sheetGraceDateMoment?.format(dateFormat);

    if (dueDateEqual && graceDateEqual) {
        return {
            status: "MATCH",
            comment: "Exact match",
            color: "success",
        }
    }
    
    const dueDateWithinSameCalendarMonth = ourDueDateMoment?.format("YYYY-MM") === sheetDueDateMoment?.format("YYYY-MM");
    const graceDateWithinSameCalendarMonth = ourGraceDateMoment?.format("YYYY-MM") === sheetGraceDateMoment?.format("YYYY-MM");

    if (
        confirmedEndOfMonthDueDateCountries.includes(caseData.country_code?.toUpperCase()) &&
        dueDateWithinSameCalendarMonth &&
        graceDateWithinSameCalendarMonth
    ) {
        return {
            status: "MATCH",
            comment: caseData?.case_type === CASE_TYPE_DESIGN ?
                "Renewal rule: payment within the last day of month" :
                "Annuity rule: payment within the last day of month",
            color: "success",
        }
    }

    const importDate = moment("2024-08-01");
    if (
        sheetDueDateMoment?.isBefore(importDate) || sheetGraceDateMoment?.isBefore(importDate)
    ) {
        return {
            status: "OLD",
            comment: caseData?.case_type === CASE_TYPE_DESIGN ?
                "The renewal due date given in your excel file has passed, and we assume that this previous renewal has been paid. We have thus activated the next renewal period. Please confirm whether this is correct" :
                "The annuity due date given in your excel file has passed, and we assume that this previous annuity has been paid. We have thus activated the annuity for next year. Please confirm whether this is correct",
            color: "warning",
        }
    }

    return null;
    // return {
    //     status: "UNKNOWN",
    //     comment: "Unknown",
    //     color: "error",
    // }
}

const ImportPreviewDataGrid = (props: any) => {
    const { data, inputTenantId } = props;
    const listContext = useList({ data });

    if (!data) return null;

    return (
        <ListContextProvider value={listContext} >

            <Datagrid key={"id"} sort={{ field: "case_ref", order: "ASC" }} sx={compactTable} rowSx={rowSx} bulkActionButtons={false}>
                {/*<Datagrid key={"case_ref"} data={[...data].slice(0, 50)} sort={{ field: "case_ref", order: "ASC" }} bulkActionButtons={false} sx={compactTable} rowSx={rowSx}>*/}
                {/*<SystemCase tenantId={inputTenantId} label={"Breeze Match"}></SystemCase>*/}

                <FunctionField label={"Breeze Case ref"} render={(record: any) => {
                    const caseMatch = record?.matched_case;
                    return caseMatch ? (
                        <div>
                            <Chip label={caseMatch.case_ref} color={"primary"}
                                  clickable
                                  component={"a"}
                                  href={`/cases/${caseMatch.id}/show`}
                                  target={"_blank"}
                                  rel="noreferrer"
                            ></Chip>
                        </div>
                    ) :
                        <Box>
                            <Box><Chip label={"No match"}></Chip></Box>
                            <CreateCaseButton record={record} tenantId={inputTenantId}></CreateCaseButton>
                        </Box>
                }
                }></FunctionField>
                <FunctionField label={"Breeze status"} render={(record: any) => record?.matched_case?.status ?
                    <Tooltip title={`${record?.matched_case.status === CASE_STATUS_CLOSED ? `${record?.matched_case.status}, ${moment(record?.matched_case.closed_at).format(dateFormat)}, reason: ${record?.matched_case.closed_event_description}` : ""}`} arrow placement={"right"}>
                        <Chip
                            label={
                                `${record?.matched_case.status} ${record?.matched_case.status === CASE_STATUS_GRANTED ? moment(record?.matched_case.registration_date).format(dateFormat) : record?.matched_case.status === CASE_STATUS_CLOSED ? moment(record?.matched_case.closed_at).format(dateFormat) : ""}`
                            }></Chip>
                    </Tooltip>
                    :
                    record?.matched_case ?
                        <Tooltip title={`There is no cases status registered on the matched case ${record?.matched_case?.case_ref}`} arrow placement={"right"}>
                            <Chip label="No case status" variant={"outlined"} color={"warning"}></Chip>
                        </Tooltip> : null
                }></FunctionField>
                <FunctionField label={"Due Date Match"} render={(record: any) => {
                    const breezeDate = record?.matched_case?.next_maintenance_case_action?.[0]?.due_date;
                    const sheetDate = record["Next Due Date"];
                    return record?.matched_case && (breezeDate || sheetDate) ? (
                        <Chip sx={{height: 'auto',}}
                              label={
                                  <Box>
                                      <div>Due date:</div>
                                      <div>B: {breezeDate ? moment(breezeDate).format(dateFormat) : ""}</div>
                                      <div>A: {sheetDate ? moment(sheetDate).format(dateFormat) : ""}</div>
                                  </Box>
                              }
                              color={moment(breezeDate).format(dateFormat) === moment(sheetDate).format(dateFormat) ? "success" : "warning"}
                        ></Chip>
                    ) : null
                }
                }></FunctionField>

                <FunctionField label={"Grace Date Match"} render={(record: any) => {
                    const breezeDate = record?.matched_case?.next_maintenance_case_action?.[0]?.with_fine_due_date;
                    const sheetDate = record["AQS Last payment deadline"];
                    return record?.matched_case && (breezeDate || sheetDate) ? (
                        <Chip sx={{height: 'auto',}}
                              label={
                                  <Box>
                                      <div>Grace:</div>
                                      <div>B: {breezeDate ? moment(breezeDate).format(dateFormat) : ""}</div>
                                      <div>A: {sheetDate ? moment(sheetDate).format(dateFormat) : ""}</div>
                                  </Box>
                              }
                              color={moment(breezeDate).format(dateFormat) === moment(sheetDate).format(dateFormat) ? "success" : "warning"}
                        ></Chip>
                    ) : null
                }
                }></FunctionField>
                <FunctionField label={"Breeze Match"} render={(record: any) => {
                    const match = dateComparison(record?.matched_case, record?.matched_case?.next_maintenance_case_action?.[0], record["Next Due Date"], record["AQS Last payment deadline"]);
                    if (record?.matched_case?.case_ref === "P3807CNPC00") console.log("P3807CNPC00", record?.matched_case);
                    return match ? (
                        <Chip sx={{height: 'auto', '& .MuiChip-label': {
                                display: 'block',
                                whiteSpace: 'normal',
                            },}}
                              label={
                                  <Box>
                                      {/*<div>{match.status}</div>*/}
                                      <div>{match.comment}</div>
                                  </Box>
                              }
                                // @ts-ignore
                              color={match.color}
                        ></Chip>
                    ) : null
                }
                }></FunctionField>
                <TextField source={"matched_case.import_comments"} label={"Breeze comments"}></TextField>
                <TextField source={"Breeze Comment + JY instructions"} label={"JY Instructions"}></TextField>
                <TextField source={"Breeze 020924"} label={"Breeze 020924"}></TextField>
                <TextField source={"matched_case.applicant"} label={"Breeze applicant"}></TextField>

                <TextField source={"AQS Mgt Status"} label={"AQS Mgt Status"}></TextField>
                <FunctionField label={""} render={(record: any) => record?.[""]?.toLowerCase() === "remove" ?
                        <Chip label={record?.[""]} color={"error"}></Chip> :
                        <Chip label={record?.[""]}></Chip>
                }></FunctionField>

                <TextField source={"Client Reference"} label={"Client Reference"}></TextField>
                <TextField source={"Family Ref"} label={"Family Ref"}></TextField>
                <TextField source={"AQS Ref"} label={"AQS Ref"}></TextField>
                <TextField source={"Country Code"} label={"Country Code"}></TextField>
                <TextField source={"Origin"} label={"Origin"}></TextField>
                <TextField source={"IP Type"} label={"IP Type"}></TextField>
                <TextField source={"Application Type"} label={"Application Type"}></TextField>
                <TextField source={"EntityStatus"} label={"EntityStatus"}></TextField>
                <MomentDateField source={"Application Date"} label={"Application Date"}></MomentDateField>
                <TextField source={"Application Number"} label={"Application Number"}></TextField>
                <ChipField source={"application_number"} label={"Breeze AN"} emptyText={" "}></ChipField>
                <MomentDateField source={"Publication Date"} label={"Publication Date"}></MomentDateField>
                <TextField source={"Publication Number"} label={"Publication Number"}></TextField>
                <MomentDateField source={"Grant Date"} label={"Grant Date"}></MomentDateField>
                <TextField source={"Patent Number"} label={"Patent Number"}></TextField>
                <ChipField source={"importObject.registration_number"} label={"Breeze PN"} emptyText={" "}></ChipField>
                <TextField source={"National Number"} label={"National Number"}></TextField>
                <MomentDateField source={"Suspension Date"} label={"Suspension Date"}></MomentDateField>
                <MomentDateField source={"Abandon Date"} label={"Abandon Date"}></MomentDateField>
                <MomentDateField source={"Expiration Date"} label={"Expiration Date"}></MomentDateField>
                <MomentDateField source={"Next Due Date"} label={"Next Due Date"}></MomentDateField>
                <MomentDateField source={"AQS Last payment deadline"} label={"AQS Last payment deadline"}></MomentDateField>
                <TextField source={"Title"} label={"Title"}></TextField>
            </Datagrid>

        </ListContextProvider>
    );
}

const MomentDateField = (props: any) => {
    const record = useRecordContext();
    if (!record) return null;

    const date = record[props.source];
    return <Box sx={{ textWrap: "nowrap" }}>{date ? moment(date).format(dateFormat) : ""}</Box>;
}

const AnnuityMatchField = (props: any) => {
    const caseRecord = props.record;
    const caseActionRecord = caseRecord?.next_maintenance_case_action?.[0];
    const sheetNextDueDate = props.sheetNextDueDate;
    const sheetWithFineDueDate = props.sheetWithFineDueDate;

    // const dueDateComparison = caseActionRecord && moment(caseActionRecord.due_date).format(dateFormat) === moment(sheetNextDueDate).format(dateFormat) ?
    //     "MATCH" :
    //     caseActionRecord && moment(caseActionRecord.due_date).format(dateFormat) !== moment(sheetNextDueDate).format(dateFormat) ?
    //         "WARNING" :
    //         sheetNextDueDate ?
    //             "NO_BREEZE_DUE_DATE" :


    if (!caseActionRecord && !sheetNextDueDate && !sheetWithFineDueDate) return (
        <Tooltip title={`No next annuity in Breeze or Excel/Anaqua`} arrow placement={"right"}>
            <Chip
                label={"No next annuity"}
                color={"info"}
            ></Chip>
        </Tooltip>
    );



    return caseActionRecord ?
        <Stack mt={2} spacing={2}>
            <Box>
                <Chip
                    sx={{
                        height: 'auto',
                    }}
                    label={
                        <Box>
                            <div>Due date:</div>
                            <div>B: {caseActionRecord.due_date ? moment(caseActionRecord.due_date).format(dateFormat) : ""}</div>
                            <div>A: {sheetNextDueDate ? moment(sheetNextDueDate).format(dateFormat) : ""}</div>
                        </Box>
                    }
                    color={
                        moment(caseActionRecord.due_date).format(dateFormat) === moment(sheetNextDueDate).format(dateFormat) ? "success" : "warning"}
                ></Chip>
            </Box>
            <Box>
                <Chip
                    sx={{height: 'auto',}}
                    label={
                        <Box>
                            <div>With fine:</div>
                            <div>B: {caseActionRecord.with_fine_due_date ? moment(caseActionRecord.with_fine_due_date).format(dateFormat) : ""}</div>
                            <div>A: {sheetWithFineDueDate ? moment(sheetWithFineDueDate).format(dateFormat) : ""}</div>
                        </Box>
                    }
                    color={
                        moment(caseActionRecord.with_fine_due_date).format(dateFormat) === moment(sheetWithFineDueDate).format(dateFormat) ? "success" : "warning"}
                ></Chip>
            </Box>
        </Stack>
        :
        (sheetNextDueDate || sheetWithFineDueDate) ?
            <Tooltip title={`Ingen due date på Breeze-caset, men due date i Excel`} arrow placement={"right"}>
                <Stack spacing={2}>
                    <Box>
                        <Chip
                            sx={{height: 'auto',}}
                            label={
                                <Box>
                                    <div>Due date</div>
                                    <div>A: {sheetNextDueDate ? moment(sheetNextDueDate).format(dateFormat) : ""}</div>
                                    <div>With fine:</div>
                                    <div>A: {sheetWithFineDueDate ? moment(sheetWithFineDueDate).format(dateFormat) : ""}</div>
                                </Box>
                            }
                            color={"error"}
                        ></Chip>
                    </Box>
                </Stack>
            </Tooltip>
                :
            <Tooltip title={`No next annuity in Breeze or Excel/Anaqua`} arrow placement={"right"}>
                <Chip
                    label={"No next annuity"}
                    color={"info"}
                ></Chip>
            </Tooltip>
}

const SystemCase = (props: any) => {
    const record = useRecordContext();

    // const { data } = useGetList('cases', {
    //     pagination: { perPage: 1, page: 1 },
    //     filter: { tenant_id: props.tenantId, "country_code@_eq": record["Country Code"]?.toLowerCase(), "application_number": record?.application_number },
    //     sort: { field: "case_ref", order: "ASC" }
    // }, noRefetch);



    // const caseMatch = data?.[0];
    const caseMatch = record?.matched_case;

    return (
        <FunctionField label={props?.label} render={(record: any) =>
            caseMatch ?
                    <div>
                        <Chip label={caseMatch.case_ref} color={"primary"}
                              clickable
                              component={"a"}
                              href={`/cases/${caseMatch.id}/show`}
                              target={"_blank"}
                              rel="noreferrer"
                        ></Chip>
                        {
                            caseMatch.status ?
                                <Tooltip title={`${caseMatch.status === CASE_STATUS_CLOSED ? `${caseMatch.status}, ${moment(caseMatch.closed_at).format(dateFormat)}, reason: ${caseMatch.closed_event_description}` : ""}`} arrow placement={"right"}>
                                    <Chip
                                        label={
                                        `${caseMatch.status} ${caseMatch.status === CASE_STATUS_GRANTED ? moment(caseMatch.registration_date).format(dateFormat) : caseMatch.status === CASE_STATUS_CLOSED ? moment(caseMatch.closed_at).format(dateFormat) : ""}`
                                    }></Chip>
                                </Tooltip>
                                :
                                <Tooltip title={`There is no cases status registered on the matched case ${caseMatch.case_ref}`} arrow placement={"right"}>
                                    <Chip label="No case status" variant={"outlined"} color={"warning"}></Chip>
                                </Tooltip>
                        }


                        {
                            (caseMatch.case_type === CASE_TYPE_PATENT || caseMatch.case_type === CASE_TYPE_DESIGN || caseMatch.case_type === CASE_TYPE_TRADEMARK) &&
                            <AnnuityMatchField record={caseMatch} sheetNextDueDate={record["Next Due Date"]} sheetWithFineDueDate={record["AQS Last payment deadline"]}/>
                        }
                    </div>
                 :
                <Box>
                    <Box><Chip label={"No match"}></Chip></Box>
                    <CreateCaseButton record={record} tenantId={props.tenantId}></CreateCaseButton>
                </Box>
        }/>
    );
};

const designImportObject = (patentImportObject: any, tenant_id: string) => {
    const commonImportFields = { ...patentImportObject, tenant_id: tenant_id };
    return patentImportObject.case_type === CASE_TYPE_DESIGN ? {
        ...commonImportFields,
        filing_date: commonImportFields.international_filing_date,
        international_filing_date: null,
        application_type: null,
    } : commonImportFields;
}

const ApplicationTypeWatchingInput = (props: any) => {
    const { setValue, watch, formState } = useForm();

    React.useEffect(() => {
        const subscription = watch((value, { name, type }) =>
            console.log(value, name, type)
        )
        return () => subscription.unsubscribe()
    }, [watch])

    console.log(watch("application_type", () => console.log("sad")), formState);
    return (
        <SelectInput source={CASE_FIELDS.APPLICATION_TYPE} choices={applicationTypeOptions} fullWidth />
    );
}

const CreateCaseButton = (props: any) => {
    const record = useRecordContext();
    if (!record) return null;

    const importObject = designImportObject(record.importObject, props.tenantId);
    return (
        <CreateInDialogButton record={importObject} resource="cases">
            <Box sx={{minWidth: "600px"}}>
                <SimpleForm
                    sx={{ maxWidth: 300 }}
                    toolbar={
                    <Toolbar>
                        <SaveButton label="Create case" alwaysEnable />
                    </Toolbar>
                }>
                    <SelectInput source={CASE_FIELDS.CASE_TYPE} choices={caseTypeOptions} fullWidth />
                    <TextInput source={"tenant_case_ref"} label={"Client ref"} fullWidth/>
                    <SelectInput source={"entity_size"} choices={entitySizeOptions} fullWidth />
                    {
                        /*
                        <ApplicationTypeWatchingInput></ApplicationTypeWatchingInput>
                    <DateInput source={CASE_FIELDS.FILING_DATE}  />
                    <DateInput source={CASE_FIELDS.INTERNATIONAL_FILING_DATE}  />
                        * */
                    }

                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_PATENT) return <PatentCaseInputFields />
                            if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_TRADEMARK) return <TrademarkCaseInputFields />
                            if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_DESIGN) return <DesignCaseInputFields />
                            if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_OPPOSITION) return <OppositionInputFields />
                            if (formData[CASE_FIELDS.CASE_TYPE] === CASE_TYPE_INFRINGEMENT) return <OppositionInputFields />
                            return <SimpleCaseTypeInputFields />;
                        }}
                    </FormDataConsumer>
                </SimpleForm>
            </Box>
        </CreateInDialogButton>
    );
}

const rowSx = (record: any, index: number) => {
    const status = record["AQS Mgt Status"];
    return {};
    // return {
    //     backgroundColor:
    //         status === "OFF" ? '#ffcd38' :
    //             status === "WAIT" ? '#efefef' : 'white',
    // };
};
