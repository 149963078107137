import {
    Datagrid,
    FunctionField,
    ReferenceField,
    ReferenceManyField, SaveButton, SimpleForm, SortPayload,
    TextField, Toolbar,
    useListContext,
    useRecordContext
} from "react-admin"
import moment from "moment";
import {CompleteSubActionButton} from "./CompleteSubActionButton";
import {PrepareEmailButton} from "./PrepareEmailButton";
import {Box, Chip} from "@mui/material";
import {EditInDialogButton} from "@react-admin/ra-form-layout";
import React from "react";

const caseSubActionsRowSx = (data: any[]) => (record: any, index: number) => {
    const currentAction = data.slice(1, data.length).find((action: any) => !action.completed_at);
    const color = () => {
        if (record.is_fake) return '#e0e0e0';
        if (record.completed_at) return '#f1f1f1';
        if (currentAction.id === record.id) return '#e1f5fe';
        return 'white';
    }
    return {
        backgroundColor: color(),
    };
};

export const caseSubActionSort: SortPayload = {field: "action_sub_rule.code", order: "ASC"};

export const SubActionsList = (props: any) => {
    const caseActionRecord = useRecordContext();
    console.log(caseActionRecord);
    const fakeCaseSubAction = caseActionRecord ? {
        is_fake: true,
        action_sub_rule: {
            code: caseActionRecord.action_rule?.action_code || caseActionRecord.action_code,
            status: caseActionRecord.action_rule?.status || caseActionRecord.status,
            action_name: caseActionRecord.action_rule?.action_name || caseActionRecord.action_name,
        },
        responsible_user_id: caseActionRecord.responsible_user_id,
        case_action: {
            due_date: caseActionRecord.due_date,
        },
        completed_at: " ",
    } : {};
    return (
        <div>
            <ReferenceManyField label="Sub actions" reference="case_sub_actions" target="case_action_id"
                                sort={caseSubActionSort}>
                <SubActionsDatagrid fakeCaseSubAction={fakeCaseSubAction}/>
            </ReferenceManyField>
        </div>
    )
}

export const SubActionsDatagrid = (props: any) => {
    const {data, isLoading} = useListContext();
    console.log(props.fakeCaseSubAction);
    const allData = [props.fakeCaseSubAction, ...(data || [])];
    const mainDueDate = allData[0]?.case_action?.due_date;
    return (
        <Datagrid bulkActionButtons={false} rowSx={caseSubActionsRowSx(allData)} data={allData} hover={false}>
            <TextField source="action_sub_rule.code" label={"Code"}/>
            <TextField source="action_sub_rule.status" label={"Status"}/>
            <TextField source="action_sub_rule.action_name" label={"Action name"}/>
            <TextField source="internal_start_date" label={"Start date"}/>
            <TextField source="internal_due_date" label={"Service date"}/>
            <TextField source="case_action.due_date" label={"Due date"}/>
            <ReferenceField reference="users" source={"responsible_user_id"} label={"Responsible"}>
                <TextField source={"name"}/>
            </ReferenceField>
            <FunctionField
                label="Completed"
                render={(record: any) => {
                    if (record.is_fake) return null;
                    if (record.completed_at) return <Box>
                        {`Completed ${moment(record.completed_at).format("lll")} by ${record.user?.name || ""}`}
                        <EditInDialogButton mutationMode={"pessimistic"} title={`Undo task`} label={"Undo"} >
                            <SimpleForm
                                record={{}}
                                defaultValues={{
                                    completed_at: null,
                                    completed_by: null
                                }}
                                toolbar={
                                    <Toolbar>
                                        <SaveButton label={"Undo completion"} alwaysEnable/>
                                    </Toolbar>
                                }
                            >
                            </SimpleForm>
                        </EditInDialogButton>
                    </Box>;
                    return record.action_sub_rule?.email_template_id ?
                        <>
                            <div><PrepareEmailButton caseId={record.case_action?.case_id}
                                                     emailTemplateId={record.action_sub_rule?.email_template_id}
                                                     dueDate={mainDueDate}/></div>
                            <div><CompleteSubActionButton/></div>
                        </> : <CompleteSubActionButton/>
                }}
            />
        </Datagrid>
    );
}