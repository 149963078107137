import * as React from "react";
import {ListContextProvider, useList} from "react-admin";

export const ListContextRawData = (props: { data: Array<any>, resource?: string, children: React.ReactNode }) => {
    const listContext = useList({ data: props.data });
    return (
        <ListContextProvider value={listContext}>
            {props.children}
        </ListContextProvider>
    )
}