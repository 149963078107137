import * as React from "react";
import {useState, useContext, useEffect} from "react";
import {LoadingButton} from "@mui/lab";
import SearchIcon from '@mui/icons-material/Search';
import UploadIcon from '@mui/icons-material/Upload';
import {CitationData, ImportCaseData} from "../../functions/fetchEPOFamilyDataForCase";
import {applicationTypeOptions, CASE_FIELDS, compactTable} from "../CaseList";
import {
    AutocompleteInput, BooleanInput, Datagrid, Form,
    FormDataConsumer, FunctionField, List,
    ListContextProvider, ReferenceInput, regex, required, SelectInput, TextField, TextInput,
    useCreate, useDataProvider, useList,
    useRecordContext, useResourceContext
} from "react-admin";
import {Alert, AlertTitle, Button, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import {useFetchEPOPatentFamily} from "./useFetchEPOPatentFamily";
import {useImportCases} from "./useImportCases";
import {TenantContext} from "../../react-admin-overrides/AppLayout";
import {Box, Stack } from "@mui/system";
import { ShowInDialogButton } from "@react-admin/ra-form-layout";
import {ButtonDialog} from "../../utils/ButtonDialog";
import {useTenantId} from "../../data/useTenantId";
import {ApplicationNumberField} from "../crud/AppplicationNumberField";
import {allCountries} from "../../utils/countries";
import { useWatch } from "react-hook-form";
import {dateFormat} from "../actions/Actions";
import {CitationsField} from "../crud/CitationsField";
import {useUserId} from "../../auth/utils";

export type CasePreview = {
    application_number?: string;
    country_code?: string;
    application_type?: string;
    case_type: string;
    case_ref?: string;
    filing_date?: string;
    international_filing_date?: string;
    abstract?: string;
    invention_title?: string;
    priority_country_code?: string;
    priority_application_number?: string;
    first_priority_filing_date?: string;
    applicants?: Array<{
        name: string,
        epoName?: string,
        match?: any,
    }>,
    applicant?: any;
    applicantName?: string;
    applicantEpoName?: string;
    applicant_name_id?: string;
    applicant2_name_id?: string;
    applicant3_name_id?: string;
    based_on_pct?: string;
    citations?: CitationData[];
    priorityClaims?: Array<{
        priority_country_code?: string;
        priority_application_number?: string;
        priority_filing_date?: string;
    }>;
    designatedStates?: string,
    lapsedStates?: string[],
    registrationDate?: string,
    registrationEPONumber?: string,
    status?: string,
    application_type_2?: string,
    publication_date?: string,
    case_team_id?: string,
    closed_at?: string,
    closed_event_description?: string,
    epo_family_id?: string,
};

const pctApplicationNumberRegex = /PCT\/[A-Za-z][A-Za-z]\d\d\d\d\/\d\d\d\d\d\d/i;
export const casesImportSessionUrl = (importSessionId: string) => `/cases?displayedFilters=%7B%22import_session_id%22%3Atrue%7D&filter=%7B%22import_session_id%22%3A%22${importSessionId}%22%7D`;

export const ImportCases = (props: any) => {
    const { tenant, refetch: refetchSelectedTenantData }  = useContext(TenantContext) || {};
    const defaultTenantId = useTenantId();
    const userId = useUserId();
    const dataProvider = useDataProvider();
    const [tenantCaseSerialNumber, setTenantCaseSerialNumber] = useState(tenant?.next_case_ref_serial_number || 0);
    const [inputTenantId, setInputTenantId] = useState(defaultTenantId);

    const {
        data: caseData,
        rawXml,
        error: searchError,
        loading: isSearching,
        makeApiCall: fetchPatentFamily,
        resetState: resetSearchResultsState
    } = useFetchEPOPatentFamily(tenantCaseSerialNumber);

    const {
        data: importResponse,
        error: importError,
        loading: isImportingCases,
        makeApiCall: importCases,
        resetState: resetImportResultState,
    } = useImportCases(refetchSelectedTenantData);

    const [formInput, setFormInput] = useState({
        // pctApplicationNumber: "",
        // pctApplicationNumber: "PCT/NO2020/050066",
        // pctApplicationNumber: "PCT/EP2018/069529",
        // pctApplicationNumber: "PCT/EP2015/060661",
        // pctApplicationNumber: "PCT/EP2019/064756",
        pctApplicationNumber: "PCT/EP2021/055181",
        // pctApplicationNumber: "PCT/EP2021/068752",
        // pctApplicationNumber: "PCT/NO2020/050280",
        case_team_id: null,
        family: true,
    });

    useEffect(() => {
        dataProvider.getOne("tenants", { id: inputTenantId }).then((response: any) => {
            setTenantCaseSerialNumber(response.data.next_case_ref_serial_number || 0);
        });
    }, [inputTenantId, importResponse]);

    const onImport = (formData: any) => {
        if (caseData) {
            const preparedForImport = caseData
                .map(c => {
                    const inputAppType = formData?.[c.case_ref || ""]?.application_type;
                    const inputApplicantNameId = formData?.[c.case_ref || ""]?.applicant_name_id;
                    const inputApplicant2NameId = formData?.[c.case_ref || ""]?.applicant2_name_id;
                    const inputApplicant3NameId = formData?.[c.case_ref || ""]?.applicant3_name_id;
                    return {
                        ...c,
                        application_type: inputAppType || c.application_type,
                        applicant_name_id: inputApplicantNameId || c?.applicants?.[0]?.match?.id || null,
                        applicant2_name_id: inputApplicant2NameId || c?.applicants?.[1]?.match?.id || null,
                        applicant3_name_id: inputApplicant3NameId || c?.applicants?.[2]?.match?.id || null,
                    };
                })
                .map(c => ({...c, case_team_id: formData?.case_team_id }));
            importCases(preparedForImport, inputTenantId || "", tenantCaseSerialNumber, userId, formData.pctApplicationNumber);
        }
    }
    const onTenantChaged = (value: any) => {
        if (value && value !== inputTenantId) {
            setInputTenantId(value);
        }
    }

    const submitSearch = (formData: any) => {
        resetSearchResultsState();
        resetImportResultState();
        setFormInput(formData);
        fetchPatentFamily(formData.pctApplicationNumber, formData.family);
    }
    const dataUnavailable = !caseData || isImportingCases || isSearching;

    const downloadXML = () => {
        if (!rawXml) {
            return;
        }

        const file = new File([rawXml], `EPO XML Response – ${formInput.pctApplicationNumber}.txt`, {
            type: 'text/plain',
        });

        function download() {
            const link = document.createElement('a')
            const url = URL.createObjectURL(file)

            link.href = url
            link.download = file.name
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)
        }

        download();
    }
    const matchedApplicants = (caseData || [])
        .map((c: any) => c.matchedApplicants)
        .flat()
        .reduce((acc: any, curr: any) => {
            if (!acc.includes(curr.name)) {
                return acc.concat([curr.name]);
            }
            return acc;
        }, []);
    return (
        <div>
            <p>Enter PCT number on the format PCT/EP2018/069529 or country code + application number for national cases (ex. EP17181955 or BR112019023900 from the same family as the PCT example)</p>
            <p>You can also search for applicant name (ie. "searas") or multiple applicant names by comma separating (ie. "searas,tco")</p>

            <Form record={formInput} onSubmit={submitSearch}>
                <Box sx={{ maxWidth: 300 }}>
                    <ReferenceInput reference="tenants" source="tenant_id" label="Organisation" >
                        <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Organisation"} validate={required()} defaultValue={defaultTenantId} fullWidth onChange={onTenantChaged} />
                    </ReferenceInput>
                    <Box mb={5}>Next case_ref serial number: {tenantCaseSerialNumber}</Box>
                </Box>
                <TextInput source={"pctApplicationNumber"} validate={[required()]} label={"Search"} parse={(val: any) => val ? val.trim() : val} sx={{width: 300}} multiline />
                <BooleanInput source={"family"} label={"Find whole family"}/>
                <LoadingButton
                    loading={isSearching}
                    loadingPosition="start"
                    type={"submit"}
                    startIcon={<SearchIcon />}
                    variant="outlined"
                    style={{marginLeft: 20, marginTop: 14}}
                >
                    Find cases
                </LoadingButton>
            </Form>

            <p></p>
            {
                searchError && searchError.length > 0 &&
                <p>
                    An error occurred: {JSON.stringify(searchError)}
                </p>
            }
            {
                importError &&
                <p>
                    An error occurred: {JSON.stringify(importError)}
                </p>
            }
            {
                importResponse && importResponse.data &&
                    <p>
                        Import success! Number of cases created: {importResponse?.data?.insert_import_sessions_one?.cases_aggregate?.aggregate?.count}
                        <br/>
                        <Button
                            component={Link}
                            to={casesImportSessionUrl(importResponse?.data?.insert_import_sessions_one?.id || "")}
                        >
                            View in cases
                        </Button>
                    </p>
            }
            {
                importResponse && importResponse.errors &&
                <p>
                    Import error:
                    {importResponse.errors.map((e:any) => (
                        <p>{e.message}</p>
                    ))}
                </p>
            }

            {
                caseData && caseData.length === 0 &&
                <p>No cases found</p>
            }
            {
                caseData && caseData.length > 0 &&
                <p>
                    Matched the following Applicant names from the Breeze database: <br/>
                    {
                        matchedApplicants.length > 0 ? matchedApplicants.join(", ") : <span>**No matches**</span>
                    }
                </p>
            }
            {
                caseData && <p>{caseData?.length}</p>
            }
            <Form record={formInput} onSubmit={onImport}>
                <Box sx={{ maxWidth: 300 }}>
                    {
                        inputTenantId && (
                            <ReferenceInput source="case_team_id" reference="case_teams" filter={{ tenant_id: inputTenantId }} >
                                <SelectInput source="case_team_id" validate={required()} optionText={"name"} fullWidth/>
                            </ReferenceInput>
                        )
                    }
                </Box>
            {
                caseData && caseData.length > 0 && !importResponse && caseData.length > 100 &&
                <Box>
                    <Alert  variant="outlined" severity="info" sx={{marginBottom: 2}}>
                        <AlertTitle>Over 100 cases</AlertTitle>
                        Since there are more than 100 cases, they won't be shown here but you can still import them
                    </Alert>
                    <LoadingButton
                        loading={isImportingCases}
                        loadingPosition="start"
                        startIcon={<UploadIcon />}
                        variant="outlined"
                        disabled={dataUnavailable}
                        type={"submit"}
                    >
                        Import {caseData.length} cases
                    </LoadingButton>
                </Box>

            }
            {
                caseData && caseData.length > 0 && !importResponse && caseData.length <= 100 &&
                <>

                        <LoadingButton
                            loading={isImportingCases}
                            loadingPosition="start"
                            startIcon={<UploadIcon />}
                            variant="outlined"
                            disabled={dataUnavailable}
                            type={"submit"}
                        >
                            Import {caseData.length} cases
                        </LoadingButton>
                        <p></p>
                        <Button onClick={downloadXML}>Download XML response from EPO</Button>
                        <ImportCasesDataGrid data={caseData}></ImportCasesDataGrid>
                </>
            }
            </Form>
        </div>
    );
};

const ImportCasesDataGrid = (props: any) => {
    const listContext = useList({ data: props.data });

    return (
        <ListContextProvider value={listContext}>
            <Datagrid key={"case_ref"} resource={"cases"} sort={{ field: "case_ref", order: "ASC" }} bulkActionButtons={false} sx={compactTable} rowSx={alternateBackgroundColorPerCaseRef} >
                <TextField source={"case_ref"}></TextField>
                <TextField source={"epo_family_id"}></TextField>
                {/*<TextField source={"application_number"}></TextField>*/}
                <ApplicationNumberField source={"application_number"}></ApplicationNumberField>
                <ApplicationTypeInput caseRefSource={"case_ref"} defaultValueSource={"application_type"}/>
                <FunctionField
                    label="Priority claim"
                    render={(record: any) => record[CASE_FIELDS.PRIORITY_APPLICATION_NUMBER] ? "With priority" : "Without priority"}
                />
                <TextField source={"country_code"}></TextField>
                <TextField source={"filing_date"}></TextField>
                <TextField source={"international_filing_date"}></TextField>
                <TextField source={"priority_country_code"}></TextField>
                <TextField source={"priority_application_number"}></TextField>
                <FunctionField
                    label={"Priority claims"}
                    render={(record: any) => <span>{(record?.priorityClaims || []).map(
                        (c:any) => <Chip label={`${c.priority_country_code} ${c.priority_application_number} ${c.priority_filing_date}`} sx={{ marginBottom: 1 }}></Chip>)
                    }</span> }
                ></FunctionField>
                <TextField source={"first_priority_filing_date"}></TextField>
                <TextField source={"based_on_pct"}></TextField>
                <ApplicantInput source={"applicant_name_id"} caseRefSource={"case_ref"} defaultSearchSource={"applicantName"} label={"Applicant"}></ApplicantInput>
                {/*<TextField source={"applicant.name"} sx={{fontWeight: "bold"}} label={"Applicant"}></TextField>*/}
                {/*<TextField source={"applicantName"}></TextField>*/}
                {/*<TextField source={"applicantEpoName"}></TextField>*/}
                <FunctionField
                    label={"Applicants"}
                    render={(record: any) => <div>{(record?.applicants || []).map((a: any) => <div>{`${a.name} (${a.epoName})`}</div>)}</div>}
                ></FunctionField>
                <FunctionField
                    label={"Matched Applicants"}
                    render={(record: any) => <div>{(record?.matchedApplicants || []).map((a: any) => <div>{a.name}</div>)}</div>}
                ></FunctionField>
                <TextField source={"designatedStates"}></TextField>
                <TextField source={"registrationDate"} label={"Reg. date"}></TextField>
                <TextField source={"registrationEPONumber"} label={"Reg. number"}></TextField>
                <TextField source={"status"}></TextField>
                <TextField source={"closed_at"}></TextField>
                <TextField source={"closed_event_description"}></TextField>
                <CitationsField />
                <FunctionField
                    label={"National Phase"}
                    render={(record: any) => <span>{(record?.nationalPhase || []).map(
                        (c:any) => <div>{c.countryCode} {c.applicationNumber}</div>)
                    }</span> }
                ></FunctionField>
                <FunctionField
                    label={"Title"}
                    render={(record: any) => <span title={record?.invention_title}>{record?.invention_title?.length > 50 ? record?.invention_title?.substr(0, 50) + "..." : record?.invention_title}</span> }
                ></FunctionField>
                <FunctionField
                    label={"Abstract"}
                    render={(record: any) => <span title={record?.abstract}>{record?.abstract?.length > 50 ? record?.abstract?.substr(0, 50) + "..." : record?.abstract}</span> }
                ></FunctionField>
                <TextField source={"publication_date"}></TextField>
                <FunctionField
                    label={"Legal events"}
                    render={(record: any) => (
                        record.legal?.length > 0 ?
                            <ButtonDialog buttonTitle={"Legal events"} dialogTitle={"Legal events"}>
                                <div><pre>{JSON.stringify(record?.legal, null, 2)}</pre></div>
                            </ButtonDialog> : null
                    )}
                ></FunctionField>
            </Datagrid>
        </ListContextProvider>
    );
}

export const alternateBackgroundColorPerCaseRef = (record: any, index: number) => {
    const res = record.case_ref.match(/P\d+/)[0] || "other";
    const serialNumber = parseInt(res.replace("P", ""), 10);
    return {
        backgroundColor: serialNumber % 2 === 0 ? '#f1f1f1' : 'white',
    };
};

const ApplicationTypeInput = (props: any) => {
    const { caseRefSource, defaultValueSource, ...rest } = props;
    const record = useRecordContext();
    const value = useWatch({ name: `${record?.[caseRefSource]}.application_type` });

    const caseRef = record?.[caseRefSource];
    const defaultValue = record?.[defaultValueSource] || null;
    return (
        <>
            <SelectInput
                source={`${caseRef}.application_type`}
                choices={applicationTypeOptions}
                sx={{ width: 200 }}
                defaultValue={defaultValue}
                label={"Application type"}
                optionValue={"id"}
                validate={required()}
                optionText={({ name }: any) => <span>{name === defaultValue ? `${name} (default / from EPO)`: name}</span>}
            />
            {value && value !== defaultValue && <Chip color={"warning"} size={"small"} label={`Overridden`}></Chip>}
        </>
    );
};

const ApplicantInput = (props: any) => {
    const { caseRefSource, source, defaultSearchSource, ...rest } = props;
    const dataProvider = useDataProvider();

    const record = useRecordContext();
    const caseRef = record?.[caseRefSource];
    const matchedApplicant1 = record?.applicants?.[0]?.match?.id;
    const matchedApplicant2 = record?.applicants?.[1]?.match?.id;
    const matchedApplicant3 = record?.applicants?.[2]?.match?.id;
    const numApplicants = record?.applicants?.length || 0;
    const onCreate = (val: any) => {
        const newCategoryName = prompt('Confirm creation of new Applicant', val);
        if (!newCategoryName) {
            return;
        }
        return dataProvider.create("names", { data: { name: newCategoryName } }).then((response: any) => ({ id: response.data.id, name: response.data.name }));
    };
    return (
        <div>
            <ReferenceInput
                label={"Applicant"}
                {...rest}
                reference="names"
                source={`${caseRef}.applicant_name_id`}
                perPage={1000}
            >
                <AutocompleteInput
                    label={"Applicant"}
                    {...rest}
                    filterToQuery={(searchText: string) => ({ name: `${searchText}` })}
                    optionText={"name"}
                    defaultValue={matchedApplicant1}
                    sx={{ width: 300 }}
                    source={`${caseRef}.applicant_name_id`}
                    onCreate={onCreate}
                />
            </ReferenceInput>
            {
                numApplicants > 1 &&
                <ReferenceInput
                    label={"Applicant 2"}
                    {...rest}
                    reference="names"
                    source={`${caseRef}.applicant2_name_id`}
                    perPage={1000}
                >
                    <AutocompleteInput
                        {...rest}
                        label={"Applicant 2"}
                        filterToQuery={(searchText: string) => ({ name: `${searchText}` })}
                        optionText={"name"}
                        defaultValue={matchedApplicant2}
                        source={`${caseRef}.applicant2_name_id`}
                        sx={{ width: 300 }}
                        onCreate={onCreate}
                    />
                </ReferenceInput>
            }

            {
                numApplicants > 2 &&
                <ReferenceInput
                    {...rest}
                    reference="names"
                    source={`${caseRef}.applicant3_name_id`}
                    perPage={1000}
                >
                    <AutocompleteInput
                        {...rest}
                        label={"Applicant 3"}
                        filterToQuery={(searchText: string) => ({ name: `${searchText}` })}
                        optionText={"name"}
                        defaultValue={matchedApplicant3}
                        source={`${caseRef}.applicant3_name_id`}
                        sx={{ width: 300 }}
                        onCreate={onCreate}
                    />
                </ReferenceInput>
            }
        </div>
    );
}
