import {useState} from "react";
import {CitationData, ImportCaseData} from "../../functions/fetchEPOFamilyDataForCase";
import {
    Direct,
    PCTBased,
    ValidatedEPC
} from "../CaseList";
import {CasePreview} from "./importCases";
import {useDataProvider} from "react-admin";
import {CASE_STATUS_CLOSED, CASE_STATUS_GRANTED, CASE_STATUS_PENDING} from "../crud/utils";
import {pctStates} from "../../utils/countries";
// @ts-ignore
import mergeWith from "lodash.mergewith";
import moment from "moment";
import {dateFormat} from "../annuities/Annuities";

const caseRefFactory = () => {
    let usedCaseRefs: { [key: string]: boolean} = {};

    const generate = (tenantCaseSerialNumber: number | string, country: string, includePC: boolean) => {
        let caseSerialNumber = 0;
        let caseRef = `P${tenantCaseSerialNumber}${country}${caseSerialNumber.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;

        while (usedCaseRefs[caseRef]) {
            caseSerialNumber++;
            caseRef = `P${tenantCaseSerialNumber}${country}${caseSerialNumber.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
        }

        usedCaseRefs[caseRef] = true;
        const pcPosition = caseRef.indexOf(String(tenantCaseSerialNumber)) + String(tenantCaseSerialNumber).length + 2;
        return includePC ? caseRef.slice(0, pcPosition) + "PC" + caseRef.slice(pcPosition) : caseRef;
    }

    return generate;
};

const washedEpodocApplicantName = (applicant: string | undefined) => {
    const name = applicant || "";
    return name
        .replaceAll(",", "")
        // Remove country info like "[NO]" that seems to be present for some cases in the family but not for all.
        .replaceAll(/\[[A-Za-z][A-Za-z]\]/g, "")
        .trim()
        // Remove "AS" (Aksjeselskap / Company) and ASA (Group Company)
        .replaceAll(/ ASA$/g, "")
        .replaceAll(/ AS$/g, "")
        .replaceAll(/ A\/S$/g, "")
        .trim()
        .toUpperCase();
};

const convertToEPODocDBNumber = (formInputApplicationNumber: string) => {
    if(formInputApplicationNumber.startsWith("PCT") || formInputApplicationNumber.startsWith("pct") ) {
        const split = formInputApplicationNumber.split("/");
        const epoDocdbFormat = `${split[1]}${split[2]}W`;
        return epoDocdbFormat
    }
    return formInputApplicationNumber;
}
function customizer(objValue: any, srcValue: any) {
    if (objValue?.length) {
        return objValue.length > 0 ? objValue : srcValue;
    }
}

const status = (aStatus: string | undefined, bStatus: string | undefined) =>
    aStatus === CASE_STATUS_CLOSED || bStatus === CASE_STATUS_CLOSED ? CASE_STATUS_CLOSED :
        aStatus === CASE_STATUS_GRANTED || bStatus === CASE_STATUS_GRANTED ? CASE_STATUS_GRANTED :
        aStatus === CASE_STATUS_PENDING || bStatus === CASE_STATUS_PENDING ? CASE_STATUS_PENDING : aStatus || bStatus;

const removeDuplicatesInFamily = (acc: Array<CasePreview>, current: CasePreview) => {
    const found = acc.find(item => current.application_number === item.application_number && current.country_code === item.country_code);
    if (!found) {
        return acc.concat([current]);
    } else {
        return acc
            .filter(item => item !== found)
            .concat([
                mergeWith(
                    {
                        status: status(current.status, found.status),
                        applicants: [
                            {
                                epoName: found.applicants?.[0]?.epoName || current.applicants?.[0]?.epoName,
                                name: found.applicants?.[0]?.name || current.applicants?.[0]?.name,
                            },
                            {
                                epoName: current.applicants?.[1]?.epoName || found.applicants?.[1]?.epoName,
                                name: current.applicants?.[1]?.name || found.applicants?.[1]?.name,
                            },
                            {
                                epoName: current.applicants?.[2]?.epoName || found.applicants?.[2]?.epoName,
                                name: current.applicants?.[2]?.name || found.applicants?.[2]?.name,
                            },
                        ].filter(applicant => applicant.name || applicant.epoName),
                    },
                    current,
                    found,
                    customizer
                )
            ]);
    }
};

const fetchDistinctFamilies = (applicant: string) => fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/epoDistinctFamilies?applicant=${applicant}`)
    .then(res => {
        return res;
    })
    .then((res) => res.json())
    .then((data) => data.distinctPatentFamilies.map((f: any) => `${f?.country}${f?.docNumber}`))


const isApplicantSearch = (inputValue: string) => {
    return inputValue.match(/\D{5,}/) && !inputValue.startsWith("PCT");
}
export const useFetchEPOPatentFamily = (tenantCaseSerialNumber: number) => {
    const dataProvider = useDataProvider();

    const [loading, setLoading] = useState(false)
    const [nextCaseSerialNumber, setNextCaseSerialNumber] = useState<number | null>(null)
    const [data, setData] = useState<CasePreview[] | null>(null)
    const [rawXml, setRawXml] = useState<any>(null)
    const [error, setError] = useState<any>(null)

    const resetState = () => {
        setLoading(false);
        setError(null);
        setData(null);
        setNextCaseSerialNumber(null);
    }

    const makeApiCall = async (searchString: string, isFamilySearch: boolean) => {
        setLoading(true);
        setError(null);
        setData(null);
        setNextCaseSerialNumber(null);

        const epoDocdbNumber = convertToEPODocDBNumber(searchString);

        const searchPatents = isApplicantSearch(searchString) ?
            Promise.allSettled(
                searchString
                    .split(",")
                    .map((applicantName: string) => fetchDistinctFamilies(applicantName.trim()))
            ).then((resultArray) => {
                return resultArray
                    .filter((resultObj) => resultObj.status === "fulfilled")
                    // @ts-ignore
                    .map((resultObj) => resultObj.value)
                    .flat()
            })
            :
            Promise.resolve([epoDocdbNumber]);

        let nextCaseRefSerialnumber = tenantCaseSerialNumber;
        const caseRef = caseRefFactory();

        Promise.allSettled((await searchPatents).map((docdbNumber: string) =>
            fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/fetchEPOFamilyDataForCase?epoDocdbNumber=${docdbNumber}&publication=${isApplicantSearch(searchString) ? "true" : "false"}`)
                .then(res => {
                    if (!res.ok) {
                        throw new Error(`HTTP status ${res.status}`, {
                            cause: {
                                status: res.status,
                                statusText: res.statusText
                            }
                        });
                    }

                    return res;
                })
                .then((res) => res.json())
                .then((body) => {
                    const data = body.data as ImportCaseData;
                    const country_code = data.priority?.document.country || "";
                    let priorityCase: CasePreview = {
                        application_number: data.priority?.document.docNumber,
                        case_type: "P",
                        application_type: Direct,
                        country_code: country_code,
                        filing_date: data.priority?.document.date,
                    };
                    const pctFilingDate = data.familyMembers
                        .find(member => member.application?.kind === "W")?.application?.date;

                    function matchesSearchApplicationNumber(c: CasePreview, searchApplicationNumber: string) {
                        if (c.application_number?.startsWith("PCT")) {
                            return c.application_number === searchApplicationNumber;
                        }
                        return c.application_number === searchApplicationNumber.substring(2, searchApplicationNumber.length) && c.country_code === searchApplicationNumber.substring(0, 2);
                    }

                    const allData = (data.familyMembers as ImportCaseData["familyMembers"])
                        // .filter(member => member.application?.docNumber !== data.priority?.document.docNumber)
                        .map<CasePreview>((member, index, family) => {
                            const familyPCT = family.find(member => member.application?.kind === "W");
                            const isPriority = member.application?.docNumber === data.priority?.document.docNumber
                            const isPCT = member.application?.kind === "W";
                            const docNumber = member.application?.docNumber;
                            const countryCode = isPCT ? "PC" : member.application?.country || "";
                            const isPCTMemberState = !!pctStates[countryCode.toLowerCase()];
                            const isSameApplication = (np: any) => docNumber && np.applicationNumber && np.countryCode === countryCode && (np.applicationNumber?.includes(docNumber) || docNumber?.includes(np.applicationNumber));
                            const existsInNationalPhaseApplicationsFromEPO = familyPCT && familyPCT.nationalPhase?.some(isSameApplication);
                            const hasPriorityOutsideThe12Months = member.priorityClaim?.document?.date && moment(member.application?.date).diff(member.priorityClaim?.document?.date, "months") > 12;
                            const matchesNationalPhase = existsInNationalPhaseApplicationsFromEPO || hasPriorityOutsideThe12Months;
                            const application_type =
                                isPriority || isPCT ?
                                    Direct :
                                    isPCTMemberState && familyPCT /*&& matchesNationalPhase */?
                                        PCTBased :
                                        Direct;
                            return ({
                                application_number: isPCT ? `PCT/${member.application?.country}${docNumber?.substr(0, 4)}/${docNumber?.substr(4, docNumber?.length)}` : docNumber,
                                case_type: "P",
                                application_type,
                                country_code: countryCode,
                                filing_date: isPriority ?
                                    priorityCase.filing_date :
                                    member.application?.date,
                                    // (isPCT ? member.application?.date : undefined),
                                international_filing_date: isPriority || isPCT ? undefined : pctFilingDate,
                                abstract: member.abstract,
                                invention_title: member.inventionTitle,
                                applicantName: member.applicants?.[0]?.name,
                                applicantEpoName: member.applicants?.[0]?.epoName,
                                applicants: member.applicants,
                                citations: member.citations,
                                designatedStates: member.designatedStates,
                                lapsedStates: member.lapsedStates,
                                registrationDate: member.registrationDate,
                                registrationEPONumber: member.registrationEPONumber,
                                status: member.closed ? CASE_STATUS_CLOSED : (member.registrationDate ? CASE_STATUS_GRANTED : CASE_STATUS_PENDING),
                                closed_at: member.closed?.closed_at,
                                closed_event_description: member.closed?.closed_event_description,
                                publication_date: (member.publication?.kind?.startsWith("B") || member.publication?.kind?.startsWith("C")) ? member.publication?.date : undefined,
                                legal: member.legal,
                                nationalPhase: member.nationalPhase,
                                epo_family_id: member.id,
                                intention_to_grant_date: member.intention_to_grant_date,
                                priorityClaims: member.priorityClaims.map((claim) => ({
                                    priority_country_code: claim?.document?.country,
                                    priority_application_number: claim?.document?.docNumber,
                                    priority_filing_date: moment(claim?.document?.date).format(dateFormat)
                                })).filter(claim => !(claim.priority_country_code === countryCode && claim.priority_application_number === docNumber)),
                                ...!isPriority ? {
                                    // priority_country_code: priorityCase.country_code,
                                    // priority_application_number: priorityCase.application_number,
                                    // first_priority_filing_date: priorityCase.filing_date,
                                    priority_country_code: member.priorityClaim?.document?.country,
                                    priority_application_number: member.priorityClaim?.document?.docNumber,
                                    first_priority_filing_date: member.priorityClaim?.document?.date,
                                } : {},
                            });
                        })

                    const pctApplicationNumber = allData.find((caseObj) => caseObj.application_number?.startsWith("PCT"))?.application_number;
                    const tmpImportCaseData = allData.filter((c) => isFamilySearch || matchesSearchApplicationNumber(c, searchString));
                    const caseDataWithoutDesignations = tmpImportCaseData.map<CasePreview>(caseObj => ({
                        ...caseObj,
                        based_on_pct: caseObj.application_type === PCTBased ? pctApplicationNumber : undefined
                    }))
                        .reduce<Array<CasePreview>>(removeDuplicatesInFamily, []);

                    const caseDataWithoutValidationStatesCases = caseDataWithoutDesignations
                        // .filter(c => {
                        //     const onlyOne = caseDataWithoutDesignations.filter(x => x.application_number === c.application_number).length === 1;
                        //     return !c.publication_date || onlyOne;
                        // })
                        .map(c => {
                            const grant = caseDataWithoutDesignations.find(x => x.application_number === c.application_number && x.publication_date);

                            const registrationDate = grant?.registrationDate || c.registrationDate;
                            const registrationEPONumber = grant?.registrationEPONumber || c.registrationEPONumber;
                            const status = c.status === CASE_STATUS_CLOSED ? CASE_STATUS_CLOSED :
                                (registrationDate ? CASE_STATUS_GRANTED : CASE_STATUS_PENDING)

                            return {
                                ...c,
                                publication_date: grant ? grant.publication_date : undefined,
                                registrationDate,
                                registrationEPONumber,
                                status,
                                case_ref: caseRef(nextCaseRefSerialnumber, c.country_code || "", c.country_code !== "PC" && c.application_type === PCTBased),
                            }
                        }).sort((a, b) => {
                            const aDate = a.filing_date && moment(a.filing_date).isValid() ? moment(a.filing_date) : null;
                            const bDate = b.filing_date && moment(b.filing_date).isValid() ? moment(b.filing_date) : null;

                            if (aDate && bDate) return aDate.diff(bDate);
                            if (!aDate && !bDate) return 0;
                            return (aDate) ? -1 : 1;
                        });
                    nextCaseRefSerialnumber++;

                    const validationStatesCases: Array<Array<CasePreview>> = caseDataWithoutValidationStatesCases
                        .filter(c => {
                            const hasDesignatedStates = c.designatedStates;
                            const granted = c.status === CASE_STATUS_GRANTED;
                            return hasDesignatedStates && granted;
                        })
                        .map(c =>
                            (c.designatedStates || "").trim().split(" ")
                                .filter(countryCode => !(c.lapsedStates || []).includes(countryCode))
                                .map(countryCode => {
                                    if (c.country_code === "EP") {
                                        return {
                                            ...c,
                                            designatedStates: undefined,
                                            country_code: countryCode,
                                            application_type_2: ValidatedEPC,
                                            case_ref: c.case_ref.split("EP")[0] + countryCode.toUpperCase() + "EP" + c.case_ref.split("EP")[1]
                                        };
                                    }

                                    return {
                                        ...c,
                                        country_code: countryCode,
                                        designatedStates: undefined,
                                        application_type: PCTBased,
                                        based_on_pct: pctApplicationNumber,
                                        application_number: undefined,
                                    }
                                })
                        );

                    const caseData = [...caseDataWithoutValidationStatesCases.filter(removeValidatedCases(validationStatesCases.flat())), ...validationStatesCases.flat()];

                    // console.log("case data", caseData);
                    // console.log("designated states", validationStatesCases);

                    const differentApplicants = [...(new Set (
                        caseData
                            .map(c => c.applicants)
                            .flat()
                            .map(c => [washedEpodocApplicantName(c?.epoName || ""), washedEpodocApplicantName(c?.name || "")])
                            .flat()
                            .filter(name => name)))]

                    // console.log("Applicants: ", differentApplicants);

                    return Promise.all(differentApplicants.map((applicantName) => dataProvider.getList<{id: string, name: string, epodocName: string}>("names", {
                        filter: {
                            "name,epodocName": applicantName,
                            // name: applicantName,
                        },
                        pagination: {
                            page: 1, perPage: 1
                        },
                        sort: {
                            field: "id", order: "ASC"
                        }
                    }))).then((dataArray) => {
                        // console.log("SUCCESS", dataArray);
                        const priority = caseData.find((c) => c.application_type === Direct);
                        const priorityArray = priority ? [priority] : [];
                        const restCasees = caseData.filter(c => c.case_ref !== priority?.case_ref);
                        return [...priorityArray, ...restCasees].map(c => {
                            const findApplicant = (applicantName: string, applicantEpoName: string) => dataArray.find(
                                (res) =>
                                    washedEpodocApplicantName(res?.data?.[0]?.epodocName) === washedEpodocApplicantName(applicantEpoName) ||
                                    washedEpodocApplicantName(res?.data?.[0]?.name) === washedEpodocApplicantName(applicantEpoName) ||
                                    washedEpodocApplicantName(res?.data?.[0]?.epodocName) === washedEpodocApplicantName(applicantName) ||
                                    washedEpodocApplicantName(res?.data?.[0]?.name) === washedEpodocApplicantName(applicantName)
                            )?.data?.[0];

                            const matchedApplicant = findApplicant(c?.applicantName || "", c?.applicantEpoName || "");

                            return {
                                ...c,
                                applicant: matchedApplicant,
                                applicants: (c.applicants || [])
                                    .map(applicant => ({
                                        ...(applicant || {}),
                                        match: findApplicant(applicant?.name || "", applicant?.epoName || "") || null
                                    })),
                                matchedApplicants: c.applicants
                                    ?.map(applicant => findApplicant(applicant?.name || "", applicant?.epoName || ""))
                                    ?.filter(applicant => applicant),
                                applicant_name_id: matchedApplicant?.id,
                                rawXml: body.rawXml
                            };
                        });
                    }).catch(error => {
                        throw error;
                    });

                }).catch(error => {
                console.log("ERROR", error);
                throw error;
            })
        )).then((resultArray) => {
            setLoading(false);
            const fulfilled = resultArray
                .filter((resultObj) => resultObj.status === "fulfilled")
            // @ts-ignore
                .map((resultObj) => resultObj.value)
                .reduce((acc: Array<Array<CasePreview>>, current: Array<CasePreview>) => {
                    const firstPriorityApplication = current[0];
                    const found = acc.find((item) => {
                        const flat = acc.flat();
                        return flat.some((c) => c.application_number === firstPriorityApplication.application_number && c.country_code === firstPriorityApplication.country_code);
                        }
                    );
                    if (found) {
                        return acc;
                    } else {
                        return acc.concat([current]);
                    }
                }, []);
            setData(
                fulfilled
                    .sort((a: any, b: any) => a[0].case_ref.localeCompare(b[0].case_ref))
                    .flat()
            );

            setNextCaseSerialNumber(nextCaseRefSerialnumber);

            // console.log("FULFILLED", fulfilled);
            setRawXml(fulfilled?.[0]?.[0]?.rawXml);

            const rejected = resultArray
                .filter((resultObj) => resultObj.status === "rejected")
                // @ts-ignore
                .map((resultObj) => resultObj.reason);
            setError(rejected);

            console.log("REJECTED", rejected);
        }).catch((error:any) => {
            console.error(error);
            setError(error);
            setLoading(false);
        });
    };

    return { loading, data, error, makeApiCall, resetState, rawXml, nextCaseSerialNumber }
};

const removeValidatedCases = (validatedCases: Array<any>) => (value: any) => {
    return !(validatedCases || []).some(c => c.application_number === value.application_number && c.country_code === value.country_code && c.priority_application_number === value.priority_application_number);
}
