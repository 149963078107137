import * as React from 'react';
import {
    useListContext,
    useRecordContext,
    List,
    TextField,
    DateField,
    ReferenceField,
    EditButton,
    SimpleForm,
    TextInput, Edit, ReferenceManyField, Show, ReferenceInput, AutocompleteInput, Create, SimpleShowLayout, SelectInput, Toolbar, SaveButton
} from 'react-admin';
import {CaseTitle} from "./CaseTitle";
import {CASE_FIELDS, CASE_TYPE_PATENT, CASE_TYPE_TRADEMARK} from "../CaseList";
import {PatentCaseInputFields, TrademarkCaseInputFields} from "./CaseInputFields";
import {useTenantId} from "../../data/useTenantId";
import {ApplicantNameInput} from "./ApplicantNameInput";

const cardStyle = {
    width: 300,
    minHeight: 300,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top'
};

const caseRoles = ["Inventor", "Organisation", "Account", "Correspondence", "Assignee"];

export const CaseRoleList = (props: any) => {
    const { data, isLoading } = useListContext();
    const caseRecord = useRecordContext();
    const tenantId = useTenantId();
    console.log(props, caseRecord);
    console.log(data);

    if (isLoading) return null;

    return (
        <Edit resource={"cases"} redirect={false} actions={false}>
            <SimpleForm maxWidth={"sm"} toolbar={
                <Toolbar>
                    <SaveButton />
                </Toolbar>
            }>
                <ApplicantNameInput />
                <ReferenceInput source="applicant2_name_id" reference="names">
                    <AutocompleteInput filterToQuery={(searchText: string) => ({ name: `${searchText}` })} fullWidth optionText={"name"} label={"Applicant 2"}/>
                </ReferenceInput>
                <ReferenceInput source="applicant3_name_id" reference="names">
                    <AutocompleteInput filterToQuery={(searchText: string) => ({ name: `${searchText}` })} fullWidth optionText={"name"} label={"Applicant 3"}/>
                </ReferenceInput>
                <ReferenceInput source="client_id" reference="clients">
                    <AutocompleteInput filterToQuery={(searchText: string) => ({ "name": `${searchText}` })} fullWidth/>
                </ReferenceInput>
                <ReferenceInput source="owner_id" reference="names" filter={{ "type@_neq": "Agent" }}>
                    <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} fullWidth/>
                </ReferenceInput>
                <ReferenceInput source="inventor_id" reference="names" filter={{ "type@_neq": "Agent" }}>
                    <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} fullWidth/>
                </ReferenceInput>
                <ReferenceInput source="primary_agent_id" reference="names" filter={{ type: "Agent" }}>
                    <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} fullWidth/>
                </ReferenceInput>
                <ReferenceInput source="local_agent_id" reference="names" filter={{ type: "Agent" }}>
                    <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} fullWidth />
                </ReferenceInput>
                <ReferenceInput source="case_team_id" reference="case_teams" filter={{ tenant_id: tenantId }}>
                    <SelectInput optionText={"name"} fullWidth/>
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};
