import React, {FunctionComponent} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {Spinner} from "../utils/Spinner";

const EnsureAuth: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const auth0Data = useAuth0<any>();

    if (auth0Data.isLoading) return <Spinner/>;

    if (!auth0Data.isAuthenticated) {
        console.log("Not loading and not authenticated -> Try to getAccessToken -> redirect to login if we can't get access token");
        auth0Data.getAccessTokenSilently()
            .then((v) => {
                console.log("Got access token", v);
            })
            .catch((e) => {
                console.log("Access token renewal failed", e);
                auth0Data.loginWithRedirect({
                    appState: {
                        returnTo: window.location.pathname + window.location.search
                    }
                });
            });
        return <Spinner/>;
    }

    if (auth0Data.error) {
        // TODO: Show error message
        return <div>Oops... {auth0Data.error.message}</div>;
    }

    const onClick = () => {
        console.log("Click", auth0Data);
        auth0Data.getAccessTokenSilently({ cacheMode: "off" })
            .then((v) => {
                console.log("Got access token", v);
            })
            .catch((e) => {
                console.log("Access token renewal failed", e);
            });
    }

    // Loading done and we're authenticated -> Render App
    return (
        <div>
        {/*<div onClick={onClick}>*/}
            {children}
        </div>
    );
};

export default EnsureAuth;