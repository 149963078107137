import React from "react";
import { Box } from "@mui/system";
import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { ReferenceInput, required, SelectInput, SimpleForm, TextInput, useRecordContext } from "react-admin"
import {ActionCountryRuleFields} from "./ActionCountryRuleEdit";

export const CreateCountryRulesDialogButton = () => {
    const record = useRecordContext();
    console.log(record);
    return (
        <CreateInDialogButton resource={"action_country_rules"} title={"Create country rule"}
                              label={"Create country rule"}>
            <Box sx={{minWidth: "600px"}}>
                <SimpleForm>
                    <TextInput source={"action_rule_id"} hidden disabled defaultValue={record?.id} style={{display: "none"}}></TextInput>
                    {/*<ReferenceInput source="action_rule_id" reference="action_rules" >*/}
                    {/*    <SelectInput source={"action_name"} validate={required()} optionText={(action) => `${action.action_code || ""} – ${action.action_name}`}/>*/}
                    {/*</ReferenceInput>*/}
                    <ActionCountryRuleFields />
                </SimpleForm>
            </Box>
        </CreateInDialogButton>
    )
}
