import { BulkUpdateFormButton } from "@react-admin/ra-form-layout";
import moment from "moment";
import {DateTimeInput, TextInput, SimpleForm, SaveButton, Toolbar, useRecordContext, useListContext, ListContextProvider } from "react-admin";
import {useUserId} from "../../auth/utils";


export const MarkPaidButton = () => {
    const userId = useUserId();
    const listContext = useListContext();
    const {selectedIds, data, onUnselectItems} = useListContext();
    const now = moment();
    const selected = (data || []).filter((d: any) => selectedIds.includes(d.id));
    const selectedCaseActionIds = selected.map((d: any) => d.case_action_id);
    return (
        <ListContextProvider value={{...listContext, selectedIds: selectedCaseActionIds, resource: "case_actions" }} >
            <BulkUpdateFormButton resource={"case_actions"} label={"Mark as paid"} icon={<></>}>
                <SimpleForm
                    toolbar={
                        <Toolbar>
                            <SaveButton label={"Save"} alwaysEnable />
                        </Toolbar>
                    }
                    sx={{maxWidth: 400}} defaultValues={{
                    completed_by: userId,
                    completed_at: now,
                    annuity_payment_paid: true,
                }}>
                    <DateTimeInput source={"completed_at"} label={"Paid at"} defaultValue={moment().format()} parse={(val: any) => moment(val).format()} ></DateTimeInput>
                    <TextInput source={"completion_notes"} label={"Notes"} multiline fullWidth></TextInput>
                </SimpleForm>
            </BulkUpdateFormButton>
        </ListContextProvider>
    );
}
