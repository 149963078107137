import {Box} from '@mui/system';
import React, {useCallback, useEffect} from 'react';
import {
    BulkDeleteButton,
    Button,
    Create,
    Datagrid,
    DateField,
    FileField,
    FileInput,
    FunctionField,
    List,
    ReferenceField,
    SimpleForm,
    TextField,
    Title,
    useDataProvider,
    useRecordContext,
    useRefresh,
    Edit,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import {useMutation} from '@tanstack/react-query';
import {useIsAdmin, useUserId} from '../auth/utils';
import {useTenantId} from "../data/useTenantId";
import {DocketButton} from "./DocketButton";
import {DownloadDocumentButton} from "../cases/crud/CaseShow";
import {ButtonDialog} from "../utils/ButtonDialog";
import Typography from '@mui/material/Typography';
import { EditInDialogButton } from '@react-admin/ra-form-layout';

export const Docketing = (props: any) => {
    const dataProvider = useDataProvider();
    const tenantId = useTenantId();
    const refresh = useRefresh();
    const userId = useUserId();
    const isAdmin = useIsAdmin();
    const {mutate} = useMutation({
        mutationFn: (data: any) => dataProvider.create("docketing_drafts", {
            data: data
        })
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            refresh();
        }, 5000); // Refresh every 5 seconds

        return () => clearInterval(intervalId);
    }, [refresh]);

    const save = useCallback(
        async (values: any) => {
            const {files, ...rest} = values;
            console.log(files, rest);

            const response = await fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getUploadUrl`)
                .then(res => res.json());

            console.log(response)
            const file = files.rawFile;
            const buffer = await file.arrayBuffer();
            let blobData = new Blob([buffer], {type: 'application/pdf'})
            console.log('Uploading to: ', response.uploadURL)
            const result = await fetch(response.uploadURL, {
                method: 'PUT',
                body: blobData
            })
            console.log('Result: ', result)

            const bucket_file_name = response.Key;

            const fileParams = {
                bucket_file_name,
                original_file_name: files.title
            }

            try {
                await mutate({
                    ...fileParams,
                    tenant_id: tenantId,
                    created_by: userId,
                });
                refresh();
            } catch (e) {
                const error = e as {
                    body: {
                        errors: [] | {}
                    }
                };
                if (error.body.errors) {
                    return error.body.errors;
                }
            }
        },
        [mutate],
    );


    return (
        <Box>
            <Title title={"Docketing"}/>
            <Create title="Create a document" {...props} resource={"docketing_drafts"}
                    sx={{maxWidth: "1000px", mx: "auto"}}>
                <SimpleForm onSubmit={save}>
                    <FileInput source="files" label="Documents" accept={{"application/pdf": [".pdf"]}} isRequired
                               sx={{'& .RaFileInput-dropZone': {backgroundColor: "#eee"}}}
                    >
                        <FileField source="src" title="title"/>
                    </FileInput>
                </SimpleForm>
            </Create>
            {isAdmin && <List
            resource={"incoming_mail"}
            exporter={false}
            sort={{field: "created_at", order: "ASC"}}
            >
                <Datagrid bulkActionButtons={<BulkDeleteButton mutationMode={"pessimistic"}/>}>
                    <TextField source={"subject"} label={"Subject"}></TextField>
                    <DateField source={"created_at"} label={"Received"} showTime></DateField>
                    <FunctionField
                        label="Case ref"
                        render={(record: any) =>
                            record.case_id ? (
                                <>
                                <ReferenceField
                                    source="case_id"
                                    reference="cases"
                                    record={record}
                                >
                                    <TextField source="case_ref"/>
                                </ReferenceField>
                                <EditInDialogButton label="Override case" >
                                    <SimpleForm>
                                        <ReferenceInput source="case_id" reference="cases" title="subject">
                                            <AutocompleteInput optionText="case_ref" />
                                        </ReferenceInput>
                                    </SimpleForm>
                                </EditInDialogButton>
                                </>
                            ) : <>
                                <Typography>No case reference found in email </Typography>
                                <EditInDialogButton label="Set case" >
                                    <SimpleForm>
                                        <ReferenceInput source="case_id" reference="cases" title="subject">
                                            <AutocompleteInput optionText="application_number" />
                                        </ReferenceInput>
                                    </SimpleForm>
                                </EditInDialogButton>
                            </>
                        }
                    />
                    {/*<TextField source={"from"} label={"From"}></TextField>
                    <TextField source={"to"} label={"To"}></TextField>*/}
                    <ReferenceField source="tenant_id" reference="tenants">
                        <TextField source="name"/>
                    </ReferenceField>
                    <FunctionField
                        source="body"
                        label="Body"
                        render={(record: any) =>
                            record.body?.substring(0, 50) + (record.body?.length > 50 ? "..." : "")
                        }
                    />
                    {//<TextField source={"attachments"} label={"Attachments"}></TextField>
                    }
                    <FunctionField
                        source="attachments"
                        label="Attachments"
                        render={(record: any) => {
                            try {
                                const attachments = JSON.parse(record.attachments || '[]');
                                return attachments.map((attachment: any, index: number) => (
                                    <div key={index}>{attachment.original_file_name }</div>
                                ));
                            } catch (e) {
                                return record.attachments; // Fallback to raw text if parsing fails
                            }
                        }}
                    />
                    <TextField source={"from"}></TextField>
                    <TextField source={"to"}></TextField>
                </Datagrid>
            </List>}
            <List
                resource={"docketing_drafts"}
                exporter={false}
                sort={{field: "created_at", order: "DESC"}}
                filter={{
                    completed_at: {
                        format: "hasura-raw-query",
                        value: {_is_null: true},
                    },
                    ...(isAdmin ? {} : { "tenant_id@_eq": tenantId })
                }}
            >
                <Datagrid bulkActionButtons={<BulkDeleteButton mutationMode={"pessimistic"}/>}>
                    <TextField source={"original_file_name"} label={"File name"}></TextField>
                    <DateField source={"created_at"} showTime></DateField>
                    <ReferenceField source="created_by" reference="users">
                        <TextField source="name"/>
                    </ReferenceField>
                    {/*<DateField source={"completed_at"} showTime></DateField>*/}
                    {/*<TextField source={"completed_by"} ></TextField>*/}
                    <FunctionField
                        label={"Text"}
                        render={(record: any) => <span
                            title={record?.document_text}>{record?.document_text?.length > 1000 ? record?.document_text?.substr(0, 1000) + "..." : record?.document_text}</span>}
                    ></FunctionField>
                    {/*<TextField source={"ai_response"} ></TextField>*/}
                    <FunctionField source={"ai_response"} label={"AI Actions"} sx={{ maxWidth: 400 }} render={(record: any) => {
                        const parsed = JSON.parse(record["ai_response"]);
                        const actions = parsed?.actions;
                        const hasActions = actions && actions.length;
                        return (
                            <Box>
                                {
                                    hasActions ?
                                        actions.map((action: any) => {
                                            const attributes = action.attributes || {};
                                            return (
                                                <Box mb={1}>
                                                    <strong>{action.action_code}:</strong>
                                                    {(Object.keys(attributes)).map((attr: string) => <Box>{` ${attr}: ${attributes[attr]}`}</Box>)}
                                                </Box>
                                            );
                                        }) :
                                        <Box mb={1}><strong>No actions</strong></Box>
                                }
                                <Box><strong>Explanation:</strong> {parsed?.explanation || ""}</Box>
                            </Box>
                        );
                    }}></FunctionField>
                    <FunctionField
                        label={false}
                        render={(record: any) => (
                            record?.document_text ?
                                <ButtonDialog buttonTitle={"Extracted text"} dialogTitle={"Extracted text"}>
                                    <Box sx={{whiteSpace: "pre-wrap"}}>{record?.document_text}</Box>
                                </ButtonDialog> : null
                        )}
                    ></FunctionField>
                    <FunctionField
                        label={false}
                        render={(record: any) => (
                            record?.ai_response ?
                                <ButtonDialog buttonTitle={"Full AI response"} dialogTitle={"Full AI Response"}>
                                    <div><pre>{JSON.stringify(JSON.parse(record["ai_response"]), null, 2)}</pre></div>
                                </ButtonDialog> : null
                        )}
                    ></FunctionField>
                    <DocketButton/>
                    <ProcessButton/>
                    <DownloadDocumentButton/>
                </Datagrid>
            </List>
        </Box>
    );
}

const ProcessButton = (props: any) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();

    const processPdf = (event: any) => {
        console.log(event);
        event?.preventDefault();

        dataProvider.processDocument(record?.id).then((res: any) => {
            console.log(res);
            alert("Document successfully sent for background processing. \n\n This means the content of the pdf will be read (no OCR) and AI will extract action rules and corresponding attributes. \n\n The process takes around 30 seconds");
        });
    }
    return <Button label={"Process"} onClick={processPdf}></Button>;
}

