import { Button, Link, ReferenceInput, required, SimpleForm, TextInput, useDataProvider, useRecordContext, FormDataConsumer, AutocompleteArrayInput, ReferenceArrayInput, AutocompleteInput, useGetOne, DateInput, useGetList, ChipField, SelectInput, RadioButtonGroupInput } from "react-admin";
import React, {FunctionComponent, useState } from "react";
import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { Box, Stack } from "@mui/system";
import {useUserId} from "../auth/utils";
import moment from "moment";
import { useNavigate } from "react-router";
import {useTenantId} from "../data/useTenantId";
import {noRefetch, useTenantContext} from "../react-admin-overrides/AppLayout";
import {Chip, Divider, Typography} from '@mui/material';
import {PDFViewer} from "../utils/PDFViewer";
import {CitationSpecificFields, DOCUMENT_FIELDS, documentTypes} from "../documents/documents";

export const DocketButton: FunctionComponent = () => {
    const record = useRecordContext();
    return (
        <CreateInDialogButton resource={"case_documents"} title={"Docket file on case"}
                              label={"Docket"}
                              maxWidth={false}
                              fullWidth
                              record={{
                                  original_file_name: record?.original_file_name,
                                  bucket_file_name: record?.bucket_file_name,
                              }}>
            <DialogContent docketRecord={record}/>
        </CreateInDialogButton>
    );
};

const DialogContent = (props: any) => {
    const record = props.docketRecord;
    const dataProvider = useDataProvider();
    const userId = useUserId();
    const navigate = useNavigate();
    const { tenant } = useTenantContext() || {};
    const tenantId = tenant?.id;
    const [matchedCaseId, setMatchedCaseId] = useState<string>();
    console.log(record);

    const { data: allActionRules, total, isLoading, error } = useGetList(
        'action_rules',
        {
            pagination: { page: 1, perPage: 100 },
            filter: { "action_type@_nin": ["Annuity", "Renewal"] },
            sort: { field: 'action_code', order: 'DESC' }
        }
    );

    const aiResponse = record?.ai_response ? JSON.parse(record?.ai_response) : {};
    const matchedActionRules = allActionRules && aiResponse ? aiResponse.actions || [] : [];
    const actionRuleIds = matchedActionRules.map((actionRule: any) => (allActionRules || []).find((ar: any) => ar.action_code === actionRule.action_code)?.id);
    const applicationNumber = matchedActionRules.find((ar: any) => ar.attributes?.application_number)?.attributes?.application_number;
    const registrationNumber = matchedActionRules.find((ar: any) => ar.attributes?.registration_number)?.attributes?.registration_number;
    const mergedActionRules = matchedActionRules.map((actionRule: any) => {
        const actionRuleData = (allActionRules || []).find((ar: any) => ar.action_code === actionRule.action_code);
        return ({ ...actionRuleData, ...actionRule });
    });
    const matched050OfficeAction = matchedActionRules.find((ar: any) => ar.action_code?.startsWith("0500"));

    console.log(mergedActionRules);
    const registrationFilter = registrationNumber ? { registration_number: registrationNumber } : {};

    if(applicationNumber) {
        dataProvider.getList(
            'cases',
            {
                pagination: { page: 1, perPage: 10 },
                filter: {
                    application_number: (typeof applicationNumber === "string" ? applicationNumber : "").split(".")[0], tenant_id: tenantId,
                    ...registrationFilter
                },
                sort: { field: 'case_ref', order: 'DESC' }
            }
        ).then((res: any) => {
            console.log(res);
            const caseId = res.data?.[0]?.id;
            if(caseId && res.data?.length === 1) {
                setMatchedCaseId(caseId);
            }
        });
    }

    console.log(matchedActionRules, applicationNumber, registrationNumber);
    const submit = (formData: any) => {
        console.log(formData);
        console.log(record);
        const actionRuleIds = formData.action_rule_ids;
        const partialActionRules = actionRuleIds.map((id: string) => ({...formData.case_action?.[id], id}));

        const { case_actions, action_rule_ids, case_action, ...caseDocument } = formData;

        dataProvider.createActions({
            docketing: {
                caseDocument,
                docketing_draft_id: record.id,
                completed_at: moment(),
                completed_by: userId,
            },
            partialActionRules,
            caseId: caseDocument.case_id,
        }).then((res: any) => {
            console.log(res);
            if (res.data) {
                navigate(`/cases/${caseDocument.case_id}/show/documents`);
            } else {
                console.error(res.error);
            }
        }).catch(console.error);
    }

    return (
        <Box>
            <Stack direction={"row"} spacing={2} marginX={2}>
                <Box width={"50%"}><PDFViewer bucketFileName={record.bucket_file_name} ></PDFViewer></Box>
                <Box width={"50%"}>
                    {
                        !isLoading &&
                        <SimpleForm onSubmit={submit} maxWidth={"600px"}>
                            <SelectInput source={DOCUMENT_FIELDS.TYPE} validate={required()} fullWidth choices={documentTypes} defaultValue={matched050OfficeAction ? "OFFICE_ACTION" : undefined}/>
                            <TextInput source="title" fullWidth validate={required()} defaultValue={(record?.original_file_name || "").replaceAll(".pdf", "")}/>
                            {/*<TextInput source="description" fullWidth multiline/>*/}
                            { applicationNumber && <Chip label={`Application Number: ${applicationNumber}`} />}
                            { registrationNumber && <Chip label={`Registration Number: ${registrationNumber}`} />}
                            { applicationNumber && <Typography variant="caption" display="block">Searched for application number {applicationNumber} in the client's ({tenant?.name}) cases (more advanced search will be added later)</Typography>}
                            <ReferenceInput source="case_id" reference="cases">
                                <AutocompleteInput
                                    validate={required()}
                                    fullWidth
                                    defaultValue={matchedCaseId}
                                    optionText={(caseRecord) => `${caseRecord.case_ref || ""} – ${caseRecord.application_number || ""} – ${caseRecord.catchword || ""}`}
                                    filterToQuery={(searchText: string) => ({
                                        "case_ref,application_number": `${searchText}`,
                                        tenant_id: tenantId
                                    })}
                                />
                            </ReferenceInput>
                            {/*<SelectActionRuleInput />*/}
                            <ReferenceArrayInput
                                label="Action rules"
                                source="action_rule_ids"
                                filter={{ 'action_type': 'OTHER' }}
                                reference="action_rules">
                                <AutocompleteArrayInput
                                    source={"action_rule_ids"}
                                    defaultValue={actionRuleIds}
                                    fullWidth
                                    optionText={(actionRule) => `${actionRule.action_code || ""} – ${actionRule.action_name}`}
                                    resource={"action_rules"}
                                    filterToQuery={(searchText: string) => ({
                                        'action_name,action_code': `${searchText}`
                                    })}/>
                            </ReferenceArrayInput>
                            <Stack divider={<Divider  />} spacing={5}>
                                <FormDataConsumer<{ action_rule_ids: string[] }>>
                                    {({ formData, ...rest }) =>
                                        formData.action_rule_ids && formData.action_rule_ids.map((id: string) => {
                                            return <ActionRuleInputFields action_rule_id={id} matched_action_rule_data={mergedActionRules.find((ar:any) => ar.id === id)}/>
                                        })}
                                </FormDataConsumer>
                            </Stack>
                            <CitationSpecificFields/>
                        </SimpleForm>
                    }
                </Box>
            </Stack>

        </Box>
    );
}

const ActionRuleInputFields: FunctionComponent<{action_rule_id: string, matched_action_rule_data: any}> = ({ action_rule_id, matched_action_rule_data }) => {
    const { data, isLoading, error, refetch } = useGetOne(
        "action_rules",
        {
            id: action_rule_id,
        },
        noRefetch
    );

    if (isLoading) return null;
    if (error || !data) {
        console.log(error, data);
        return null;
    }

    console.log(data);
    return (
        <Stack>
            <ActionRuleTitle actionRule={data} />
            {data.extract_field === "letter_date" &&
                <DateInput source={`case_action.${action_rule_id}.letter_date`} label={"Letter date"} validate={required()} defaultValue={matched_action_rule_data?.attributes?.date_of_office_action}/>
            }
            <DateInput source={`case_action.${action_rule_id}.due_date`} label={"Due date"} validate={required()} defaultValue={matched_action_rule_data?.attributes?.due_date}/>
        </Stack>
    );
}

const ActionRuleTitle = (props: any) => {
    const { actionRule } = props;
    if (!actionRule) return null;
    return <span>{`${actionRule.action_code} – ${actionRule.action_name}`}</span>
}
