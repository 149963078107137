import * as React from "react";
import {
    AutocompleteInput,
    BulkDeleteButton,
    Count,
    Datagrid,
    DateField,
    Filter,
    FunctionField,
    Link,
    List,
    ReferenceInput,
    SelectInput,
    TextField,
    WithRecord
} from 'react-admin';
import {useIsAdmin} from "../auth/utils";
import {Flag} from "../utils/Flag";
import {countries, euFlagCountries} from "../utils/countries";
import {Box, Stack} from "@mui/system";
import moment from "moment";
import {CaseRefField} from "../cases/crud/CaseRefField";
import { Tabs, Tab } from "@mui/material";
import {INSTRUCTION_WAIT} from "../cases/annuities/Annuities";
import {HANDLER_BREEZE} from "../cases/instructions/SetBreezeAsHandlerButton";
import {SendOrderConfirmationEmailButton} from "./SendOrderConfirmationEmailButton";
import {SendAgentInstructionsEmailButton} from "./SendAgentInstructionsEmailButton";
import { Page } from "../utils/Page";

const Filters = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Filter {...props}>
            <ReferenceInput source="tenant_id" reference="tenants" alwaysOn>
                <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Organisation"
                                   fullWidth/>
            </ReferenceInput>
            <ReferenceInput label="Order" reference="orders" source="id" >
                <AutocompleteInput filterToQuery={(searchText: string) => ({id: `${searchText}`})} label="Order"
                                   optionText={(record: any) => `${record.order_type} – ${moment(record.created_at).format("DD.MM.YY HH:mm")}`}
                                   fullWidth/>
            </ReferenceInput>
            <SelectInput
                source={"order_type"}
                choices={["PCT", "EP"].map((type) => ({id: type, name: type}))}
                alwaysOn
            />
        </Filter>
    );
};

const receivedFilter = {
    "confirmed_at@_is_null": true,
};

const confirmedFilter = {
    "pct_order#order#confirmed_at@_is_null": false,
};

const invoiceFilter = {
    "ep_order#order#confirmed_at@_is_null": true,
};

const filters: { [key: string]: any } = {
    "received": receivedFilter,
    "confirmed": confirmedFilter,
    "invoice": invoiceFilter,
}

const bulkActionButtons = (tabValue: string) => {
    return (
        <>
            { tabValue === "received" && <BulkDeleteButton mutationMode="pessimistic" /> }
        </>
    );
}

const statuses = {
    received: "received",
    confirmed: "confirmed",
    agentConfirmation: "agent-confirmation",
    docsFromAgent: "docs-from-agent",
    docsFromClient: "docs-from-client",
    invoice: "invoice",
    invoiceHistory: "invoice-history",
}

const statusesArray = Object.values(statuses);
export const OrdersList = (props: any) => {
    const [value, setValue] = React.useState<string>("received");
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    }

    const filter =  filters[value] || {};
    const handledByOrder = [statuses.received];
    const handledByOrderLines = statusesArray.filter((status) => !handledByOrder.includes(status));

    return (
        <Page title={"Orders"}>
            <Tabs
                value={value}
                onChange={handleChange}
            >
                <Tab
                    value={statuses.received}
                    label={<Box>Received <Count filter={receivedFilter}></Count></Box>}
                />
                <Tab value={statuses.confirmed} label={<Box>Confirmed <Count filter={confirmedFilter}></Count></Box>} />
                <Tab value={statuses.agentConfirmation} label={<Box>Awaiting Agent Confirmation <Count filter={confirmedFilter}></Count></Box>} />
                <Tab value={statuses.docsFromAgent} label={<Box>Awaiting Docs Agent <Count filter={confirmedFilter}></Count></Box>} />
                <Tab value={statuses.docsFromClient} label={<Box>Awaiting Docs Client <Count filter={confirmedFilter}></Count></Box>} />
                <Tab value={statuses.invoice} label={<Box>To be invoiced <Count filter={invoiceFilter}></Count></Box>} />
                <Tab value={statuses.invoiceHistory} label="Invoice history" />
            </Tabs>
            {
                handledByOrder.includes(value) &&
                <List {...props} sort={{ field: "created_at", order: "ASC" }} filters={<Filters/>} filter={filter}>
                    <Datagrid
                        bulkActionButtons={bulkActionButtons(value)}
                    >
                        <TextField label="Organisation" source={"tenant.name"} ></TextField>
                        <FunctionField label={"Order #"} render={orderNumber} sortBy={"id"} />
                        <DateField source={"created_at"} showTime />
                        <DateField source={"updated_at"} showTime />
                        <TextField source={"order_type"} />

                        <WithRecord label={"Case ref."} render={(record: any) => {
                            const orderType = record.order_type === "PCT" ? "pct_order" : "ep_order";
                            return (
                                <TextField source={`${orderType}.case.case_ref`} />
                            );
                        }} />
                        <WithRecord label={"App. #"} render={(record: any) => {
                            const orderType = record.order_type === "PCT" ? "pct_order" : "ep_order";
                            return (
                                <TextField source={`${orderType}.case.application_number`}  />
                            );
                        }} />

                        <TextField label="Submitted by" source={"user.name"} ></TextField>
                        <TextField label={"User ref"} source={"pct_order.user_reference"} ></TextField>
                        <TextField label={"User comments"} source={"pct_order.user_comments"} ></TextField>
                        <TextField source={"portal_price"} ></TextField>
                        <TextField source={"price_currency"} ></TextField>
                        <FunctionField label={"Answers"} render={(record: any) => {
                            return (
                                <>
                                    {
                                        record.pct_order?.answers?.map((answer: any) => (
                                            <Stack mb={2}>
                                                <Box>{answer.q}</Box>
                                                <Box fontWeight={"600"}>{answer.a}</Box>
                                            </Stack>
                                        ))
                                    }
                                    {
                                        record.pct_order?.pct_order_lines?.filter((line: any) => line.answers?.length > 0).map((order_line: any) => (
                                            <>
                                                <Box fontWeight={"600"}><Flag countryCode={order_line.country_code}></Flag> {" " + countries[order_line.country_code]}</Box>
                                                {order_line.answers?.map((answer: any) => (
                                                    <Stack mb={2}>
                                                        <Box>{answer.q}</Box>
                                                        <Box fontWeight={"600"}>{answer.a}</Box>
                                                    </Stack>
                                                ))}
                                            </>
                                        ))
                                    }
                                </>
                            );
                        }}></FunctionField>
                        <WithRecord label={"Selected countries"} render={(record: any) => {
                            const pctOrEpOrder = record.order_type === "PCT" ?
                                record.pct_order : record.ep_order;
                            const orderLines = record.order_type === "PCT" ?
                                pctOrEpOrder.pct_order_lines : pctOrEpOrder.ep_order_lines;

                            return (
                                <FunctionField label={"Selected countries"} render={(record: any) => {
                                    return orderLines?.map((line: any) => (
                                        <Stack mb={2}>
                                            <Stack direction={"row"} spacing={2}>
                        <span>
                            <Flag countryCode={euFlagCountries.includes(line.country_code) ? "eu" : line.country_code}></Flag>{"  " + countries[line.country_code]}
                        </span>
                                                {
                                                    line.price &&
                                                    <span>({line.price} {line.price_currency})</span>
                                                }
                                            </Stack>
                                            <Box sx={{ textWrap: "nowrap" }}>Official: {line.official_fee}. Service: {line.service_fee}. Translation: {line.translation_fee || "N/A"}</Box>
                                        </Stack>
                                    ));
                                }}></FunctionField>
                            );
                        }} />

                        <WithRecord label={"Cases"} render={(record: any) => {
                            const pctOrEpOrder = record.order_type === "PCT" ?
                                record.pct_order : record.ep_order;
                            return (
                                <FunctionField label={"Cases"} render={(record: any) => {
                                    return pctOrEpOrder?.case?.national_phase_cases.map((line: any) => (
                                        <Stack direction={"row"} spacing={2}>
                        <span>
                            <Link to={`/cases/${line.id}/show`} target={"_blank"}>{line.case_ref}</Link>
                        </span>
                                        </Stack>
                                    ));
                                }}></FunctionField>
                            );
                        }} />

                        { value === "received" && <SendOrderConfirmationEmailButton />}
                        {/*{ value === "confirmed" && <SendAgentInstructionsEmailButton />}*/}
                    </Datagrid>
                </List>
            }

            {
                handledByOrderLines.includes(value) &&
                <List resource={"ep_order_lines"} sort={{ field: "created_at", order: "ASC" }} filter={filter}>
                    <Datagrid
                        bulkActionButtons={bulkActionButtons(value)}
                    >
                        <TextField label="Organisation" source={"pct_order.order.tenant.name"} ></TextField>
                        <FunctionField label={"Order #"} render={(record: any) => record?.pct_order?.order?.id?.split("-")[0]?.toUpperCase()} sortBy={"id"} />
                        <DateField source={"created_at"} showTime />
                        <DateField source={"updated_at"} showTime />
                        <TextField source={"pct_order.order.order_type"} />

                        <WithRecord label={"Case ref."} render={(record: any) => {
                            return (
                                <TextField source={`case.case_ref`} />
                            );
                        }} />
                        <WithRecord label={"App. #"} render={(record: any) => {
                            return (
                                <TextField source={`case.application_number`}  />
                            );
                        }} />

                        <TextField label="Submitted by" source={"pct_order.order.user.name"} ></TextField>
                        <TextField label={"User ref"} source={"user_reference"} ></TextField>
                        <TextField label={"User comments"} source={"user_comments"} ></TextField>
                        <TextField source={"pct_order.order.portal_price"} ></TextField>
                        <TextField source={"pct_order.order.price_currency"} ></TextField>
                        <FunctionField label={"Answers"} render={(record: any) => {
                            return (
                                <>
                                    {
                                        record.pct_order?.answers?.map((answer: any) => (
                                            <Stack mb={2}>
                                                <Box>{answer.q}</Box>
                                                <Box fontWeight={"600"}>{answer.a}</Box>
                                            </Stack>
                                        ))
                                    }
                                    {
                                        record.pct_order?.pct_order_lines?.filter((line: any) => line.answers?.length > 0).map((order_line: any) => (
                                            <>
                                                <Box fontWeight={"600"}><Flag countryCode={order_line.country_code}></Flag> {" " + countries[order_line.country_code]}</Box>
                                                {order_line.answers?.map((answer: any) => (
                                                    <Stack mb={2}>
                                                        <Box>{answer.q}</Box>
                                                        <Box fontWeight={"600"}>{answer.a}</Box>
                                                    </Stack>
                                                ))}
                                            </>
                                        ))
                                    }
                                </>
                            );
                        }}></FunctionField>
                        <WithRecord label={"Selected countries"} render={(record: any) => {
                            const pctOrEpOrder = record.order_type === "PCT" ?
                                record.pct_order : record.ep_order;
                            const orderLines = record.order_type === "PCT" ?
                                pctOrEpOrder.pct_order_lines : pctOrEpOrder.ep_order_lines;

                            return (
                                <FunctionField label={"Selected countries"} render={(record: any) => {
                                    return orderLines?.map((line: any) => (
                                        <Stack mb={2}>
                                            <Stack direction={"row"} spacing={2}>
                        <span>
                            <Flag countryCode={euFlagCountries.includes(line.country_code) ? "eu" : line.country_code}></Flag>{"  " + countries[line.country_code]}
                        </span>
                                                {
                                                    line.price &&
                                                    <span>({line.price} {line.price_currency})</span>
                                                }
                                            </Stack>
                                            <Box sx={{ textWrap: "nowrap" }}>Official: {line.official_fee}. Service: {line.service_fee}. Translation: {line.translation_fee || "N/A"}</Box>
                                        </Stack>
                                    ));
                                }}></FunctionField>
                            );
                        }} />

                        <WithRecord label={"Cases"} render={(record: any) => {
                            const pctOrEpOrder = record.order_type === "PCT" ?
                                record.pct_order : record.ep_order;
                            return (
                                <FunctionField label={"Cases"} render={(record: any) => {
                                    return pctOrEpOrder?.case?.national_phase_cases.map((line: any) => (
                                        <Stack direction={"row"} spacing={2}>
                        <span>
                            <Link to={`/cases/${line.id}/show`} target={"_blank"}>{line.case_ref}</Link>
                        </span>
                                        </Stack>
                                    ));
                                }}></FunctionField>
                            );
                        }} />

                        { value === "received" && <SendOrderConfirmationEmailButton />}
                        {/*{ value === "confirmed" && <SendAgentInstructionsEmailButton />}*/}
                    </Datagrid>
                </List>
            }

        </Page>
    );
};

export const orderNumber = (record: any) => record?.id?.split("-")[0]?.toUpperCase();

