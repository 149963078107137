import {WizardForm} from "@react-admin/ra-form-layout";
import * as React from "react";
import {
    BooleanInput,
    Button,
    CheckboxGroupInput,
    choices,
    Create,
    Datagrid,
    FileField,
    FileInput,
    FormDataConsumer,
    Link,
    required,
    SelectInput,
    TextField,
    TextInput,
    Title,
    useGetList,
    useRecordContext,
    WithRecord,
    WrapperField
} from "react-admin";
import {useIsAdmin, useUserId} from "../../auth/utils";
import {Page} from "../../utils/Page";
import {allCountries, countries, countriesToOptions, pctStates} from "../../utils/countries";
import {Alert, AlertTitle, Box, Dialog, DialogContent, DialogTitle, Stack, Tooltip, Typography} from "@mui/material";
import {FieldValues} from "react-hook-form";
import {
    CountryFlagField,
    countryOption,
    LocalAgentField,
    LocalAgentInput,
    CurrencyField,
    SelectCountriesWizardToolbar
} from "./common";
import {defaultAgentIdFunction} from "./SelectCountriesEP";
import {Flag} from "../../utils/Flag";
import {toChoices, yesNoChoices} from "../../common/common-utils";
import {noRefetch, useTenantContext} from "../../react-admin-overrides/AppLayout";
import {ApplicationNumberField} from "../crud/AppplicationNumberField";
import {useHasuraRequest} from "../../utils/useHasuraRequest";
import {submitOrdersMutation} from "./order_mutation";
import {Spinner} from "../../utils/Spinner";
import {CloseDialogIconButton} from "../../utils/CloseDialogIconButton";
import { ErrorDialog } from "../../utils/ErrorDialog";
import {SuccessDialog} from "../../utils/SuccessDialog";
import {PCTPricesExportToExcel} from "./pct-prices-exporter";
import DownloadIcon from '@mui/icons-material/GetApp';
import HelpIcon from '@mui/icons-material/InfoOutlined';
import {dateFormat} from "../actions/Actions";
import { CaseLink } from "../crud/CaseRefField";
import {ListContextRawData} from "../../utils/ListContextRawData";

const questions = [
    "Have you requested international preliminary examination and filed amendments under PCT article 34?",
    "Have you filed amendments under PCT article 19?",
    "Does the application contain sequence listings?",
    "Does the invention relate to or use biological material deposited under rule 28 EPC?",
    "Do you want to file voluntarily amended claims?",
]

const questionInventorRights = {
    question: "How does the applicant have the rights to the invention?",
    source: "question_invention_rights",
}

const commonStates = ["no", "us", "ep", "ca"];

const calcTranslationFee = (caseRecord: any, priceEntry: any): number => {
    const translation_requirements = priceEntry.translation_requirements;
    const translation_fee_per_word = priceEntry.translation_fee_per_word;

    if (translation_requirements === "Claims") return Math.ceil(caseRecord.word_count_claims_only * translation_fee_per_word);
    if (translation_requirements === "Whole application") return Math.ceil(caseRecord.word_count * translation_fee_per_word);

    return 0;
};

const calcOfficialFee = (caseRecord: any, priceEntry: any): any => {
    if(!priceEntry) return {  };

    const basic_filing_fee_eur = (priceEntry.basic_filing_fee * priceEntry.official_fee_currency_factor);

    const extra_claims = Math.max((caseRecord.claims_count || 0) - (priceEntry.basic_fee_num_included_claims || 0), 0);
    const extra_claims_fee_eur = (extra_claims * priceEntry.basic_fee_per_extra_claim * priceEntry.official_fee_currency_factor);

    const extra_independent_claims = Math.max((caseRecord.independent_claims_count || 0) - (priceEntry.basic_fee_num_included_ind_claims || 0), 0);
    const extra_independent_claims_fee_eur = (extra_independent_claims * priceEntry.basic_fee_per_extra_ind_claim * priceEntry.official_fee_currency_factor);

    const extra_pages = Math.max((caseRecord.page_count || 0) - priceEntry.basic_fee_num_included_pages, 0);
    const extra_pages_fee_eur = (extra_pages * priceEntry.basic_fee_per_extra_page * priceEntry.official_fee_currency_factor);

    const examination_fee = ((priceEntry.examination_fee || 0) * priceEntry.official_fee_currency_factor);

    const official_fee_eur = Math.ceil(basic_filing_fee_eur + extra_claims_fee_eur + extra_independent_claims_fee_eur + extra_pages_fee_eur + examination_fee);

    return {
        official_fee_eur: official_fee_eur && official_fee_eur < 10 ? 10 : official_fee_eur,
        basic_filing_fee: priceEntry.basic_filing_fee,
        official_fee_currency_factor: priceEntry.official_fee_currency_factor,
        official_fee_currency: priceEntry.basic_filing_fee_currency,
        basic_filing_fee_eur,
        extra_claims_fee_eur,
        extra_independent_claims_fee_eur,
        extra_pages_fee_eur,
        applicant_employees: priceEntry.applicant_employees,
        examination_fee
    };
};

export const SelectCountriesPCT = (props: any) => {
    const caseRecord = useRecordContext();
    const { loading: isSubmittingOrder, data: orderData, error: orderError, makeApiCall } = useHasuraRequest();
    const userId = useUserId();
    const isAdmin = useIsAdmin();
    const { tenant } = useTenantContext()  || {};
    const { data, isLoading, error } = useGetList('pct_country_prices', {
        pagination: { perPage: 10000, page: 1 },
    }, noRefetch);

    const { data: eurData } = useGetList('exchange_rates', {
        pagination: { perPage: 1, page: 1 },
        filter: { currency_code: "EUR" },
        sort: { field: "date", order: "DESC" }
    }, noRefetch);

    const { data: usdData } = useGetList('exchange_rates', {
        pagination: { perPage: 1, page: 1 },
        filter: { currency_code: "USD" },
        sort: { field: "date", order: "DESC" }
    }, noRefetch);

    if (!caseRecord) return null;
    if (!data) return null;
    if (!eurData) return null;

    // const oneEURToNOK = 1 / eurData[0].exchange_rate;
    const oneEURToNOK = (1 / eurData[0].exchange_rate) * 1.05;
    // const oneEURToUSD = oneEURToNOK * (usdData?.[0]?.exchange_rate || 0);
    const oneEURToUSD = (oneEURToNOK * (usdData?.[0]?.exchange_rate || 0)) * 1.05;
    const currencyConvert = { currency_code: tenant?.currency_code || "USD", factor: tenant?.currency_code === "NOK" ? oneEURToNOK : oneEURToUSD };

    console.log("DATA", data);

    const onSubmit = (formData: any) => {
        console.log("SUBMIT", formData);
        const countriesDatagrid = createCountriesDatagrid(formData, countryPriceCalc, defaultAgentId)
        const totalPrice = sumField(countriesDatagrid, "total");

        const pct_order_lines = (formData.countries || []).map((country_code: string) => {
            const { agent, ...countryAnswers } = formData[country_code] || {};
            const questionsConfig = countryQuestions[country_code];
            const answers = questionsConfig ?
                questionsConfig.map((question: any, index: number) => ({
                    q: question.question,
                    a: countryAnswers[question.source],
                }))
                : [];


            const priceConfig = countriesDatagrid.find((record: any) => record.country_code === country_code);
            const prices = priceConfig ? {
                price: priceConfig.total,
                price_currency: "EUR",
                translation_fee: priceConfig.translation_fees,
                service_fee: priceConfig.service_fees,
                official_fee: priceConfig.official_fees,
            } : {};
            return {
                country_code,
                agent_id: formData[country_code]?.agent,
                answers,
                ...prices
            };
        });

        const order = {
            tenant_id: tenant?.id,
            created_by: userId,
            order_type: "PCT",
            portal_price: totalPrice,
            pct_orders: {
                data: {
                    case_id: caseRecord.id,
                    answers: [
                        ...questions.map((question, index) => ({
                            q: question,
                            a: formData[`question_${index}`],
                        })),
                        {
                            q: questionInventorRights.question,
                            a: formData[questionInventorRights.source],
                        }
                    ],
                    user_reference: formData.your_reference,
                    user_comments: formData.comments,
                    pct_order_lines: {
                        data: pct_order_lines
                    }
                }
            }
        }
        console.log("ORDER", order);

        makeApiCall({
            query: submitOrdersMutation,
            variables: {
                orders: [order]
            }
        });
    }

    const designatedStates = caseRecord.designated_states?.split(" ");
    const hasPrices = (country: {id: string, name: string}) => data.find((p: any) => p.country_code === country.id);
    const withoutPrices = (country: {id: string, name: string}) => !data.find((p: any) => p.country_code === country.id);

    const pctStatesWithPrices = countriesToOptions(pctStates)
        .filter(hasPrices);
    const pctStatesWithoutPrices = countriesToOptions(pctStates)
        .filter(withoutPrices);

    const countryChoicesWithPrices = designatedStates ?
        pctStatesWithPrices
            .filter(country => designatedStates.includes(country.id.toUpperCase())) :
        pctStatesWithPrices;

    const countryChoicesWithoutPrices = designatedStates ?
        pctStatesWithoutPrices
            .filter(country => designatedStates.includes(country.id.toUpperCase())) :
        pctStatesWithoutPrices;

    const commonCountries = countriesToOptions(pctStates).filter(country => commonStates.includes(country.id));
    const countryPriceCalc = priceCalc(caseRecord, data);
    const defaultAgentId = defaultAgentIdFunction(data);

    return (
        <Page title={`PCT National Phase: ${caseRecord.application_number}`} center body={
            <p>
                <Stack my={2} textAlign={"center"} alignItems={"center"}>
                    <Stack direction={"row"} spacing={1} divider={<span>–</span>}>
                        { caseRecord.application_number ? <ApplicationNumberField /> : null}
                        { caseRecord.tenant_case_ref ? <CaseLink caseId={caseRecord.id as string} linkText={caseRecord.tenant_case_ref} target={"_blank"}/> : null }
                        { caseRecord.case_ref ? <CaseLink caseId={caseRecord.id as string} linkText={caseRecord.case_ref} target={"_blank"}/> : null }
                    </Stack>
                    { caseRecord.invention_title && <Box>{caseRecord.invention_title}</Box> }
                    { caseRecord.applicant && <Box>{caseRecord.applicant}</Box> }
                    {/*<Tooltip title=></CaseImagePreview>} arrow>*/}
                    {/*    <span>{caseRecord.application_number}</span>*/}
                    {/*</Tooltip>*/}
                </Stack>
            </p>
        }>
            <Title title="Breeze IP – PCT National Phase" />
            {
                isSubmittingOrder && <Spinner></Spinner>
            }
            {
                orderError &&
                <ErrorDialog ></ErrorDialog>
            }

            {
                orderData &&
                <SuccessDialog></SuccessDialog>
            }
            <Box maxWidth={"lg"}>
                <Create resource={"orders"}>
                    <WizardForm
                        toolbar={<SelectCountriesWizardToolbar />}
                        onSubmit={onSubmit}
                    >
                        <WizardForm.Step label="Select countries">
                            <Stack>
                                <Stack spacing={1} marginBottom={2}>
                                    <Box>You are approaching the due date for filing of PCT based national and regional patent applications. The applications have to be translated and filed with the respective Patent Offices before this date, so we urge you to send instructions about two months before this date, and latest at least one month before this due date.</Box>
                                    <Box>The easiest way to file such PCT based applications is to select countries and regions below, select agents, answer some case relevant questions and send the filing order. Breeze IP will take care of the national/regional filings, appoint the local agents, file translations and power of attorney and assignment documents.</Box>
                                    <Box>We will send you one package of documents to be signed, one invoice and one filing report.</Box>
                                    <Box>We have agreement and fixed prices with agents in the first group of countries, and we can get a quote for countries in the second group of countries (show all designated states). The costs are based on an English application text (i.e. that the PCT application is published in English) and on an electronic counting of words, pages and claims.</Box>
                                </Stack>

                                <h4>Common states</h4>
                                <CheckboxGroupInput
                                    source="countries"
                                    choices={countryChoicesWithPrices}
                                    optionText={countryOption}
                                    sx={{ '& .MuiFormControlLabel-root': { width: 280 } }}
                                    row={true}
                                />

                                {/*<h4> </h4>*/}
                                <BooleanInput source={"show_all_designated_states"} label={"Show all designated states"}></BooleanInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        if (!formData.show_all_designated_states) return null;
                                        return (
                                            <>
                                                <CheckboxGroupInput
                                                    source="countries"
                                                    choices={countryChoicesWithoutPrices}
                                                    optionText={countryOption}
                                                    sx={{ '& .MuiFormControlLabel-root': { width: 280 } }}
                                                    row={true}
                                                />
                                            </>
                                        );
                                    }}
                                </FormDataConsumer>

                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        const countriesDatagrid = createCountriesDatagrid(formData, countryPriceCalc, defaultAgentId);
                                        if (!countriesDatagrid || countriesDatagrid.length === 0) return null;
                                        const inkSumLine = [...countriesDatagrid, { name: "Sum", total: sumField(countriesDatagrid, "total")}]
                                        return (
                                            <ListContextRawData data={inkSumLine}>
                                                <Datagrid bulkActionButtons={false} sort={{ field: "name", order: "ASC" }} expand={isAdmin ? <Expanded formData={formData}></Expanded> : undefined}>
                                                    <CountryFlagField source="name" countryCodeSource={"country_code"} label={"Country"} />
                                                    <WrapperField label={"Official fees"} >
                                                        <Box sx={{textWrap: "nowrap", display: "block"}}>
                                                            <CurrencyField source={"official_fees"} currency={"EUR"}></CurrencyField>
                                                            <WithRecord render={(r: any) =>
                                                                r?.country_code === "ep" ?
                                                                    <Tooltip title={
                                                                        <Stack sx={{ fontSize: "1rem", padding: 2 }} spacing={1}>
                                                                            We have not included the EP search fee as we assume that a search in the PCT phase has been conducted by EPO or USPTO.
                                                                        </Stack>
                                                                    }>
                                                                        <HelpIcon fontSize={"small"} sx={{verticalAlign: "bottom"}}></HelpIcon>
                                                                    </Tooltip> : null }
                                                            />
                                                        </Box>
                                                    </WrapperField>
                                                    <CurrencyField source={"service_fees"} currency={"EUR"}></CurrencyField>
                                                    <CurrencyField source={"translation_fees"} currency={"EUR"}></CurrencyField>
                                                    <CurrencyField source={"total"} label={"Total costs"} currency={"EUR"} fallback={"TBD"} convert={{ currency_code: tenant?.currency_code || "USD", factor: tenant?.currency_code === "NOK" ? oneEURToNOK : oneEURToUSD }}></CurrencyField>
                                                    {/*<CurrencyField source={"total"} label={"Total costs"} currency={"EUR"} fallback={"TBD"} convert={{ currency_code: "NOK", factor: oneEURToNOK }}></CurrencyField>*/}
                                                    {/*<SelectInput source="agent" choices={[{id: "1", name: "Agent 1"}, {id: "2", name: "Agent 2"}]}/>*/}
                                                </Datagrid>
                                            </ListContextRawData>
                                        )
                                    }
                                    }
                                </FormDataConsumer>
                            </Stack>
                        </WizardForm.Step>
                        <WizardForm.Step label="Questions">
                            Please answer the questions below. The answers may change the estimated costs.
                            <h3>General questions</h3>
                            {/*{*/}
                            {/*    questions.map((question, index) => (*/}
                            {/*        <BooleanInput key={index} source={`question_${index}`} label={question} variant="outlined"*/}
                            {/*                      validate={required()}/>*/}
                            {/*    ))*/}
                            {/*}*/}
                            {
                                questions.map((question, index) => (
                                    <Labelled label={question}>
                                        <SelectInput
                                            source={`question_${index}`}
                                            label={false}
                                            validate={required()}
                                            choices={yesNoChoices}
                                            sx={{ minWidth: 200}}
                                            fullWidth={false}
                                        ></SelectInput>
                                    </Labelled>
                                ))
                            }

                            <Labelled label={questionInventorRights.question}>
                                <SelectInput
                                    source={questionInventorRights.source}
                                    label={"Invention rights"}
                                    validate={required()}
                                    choices={
                                        [
                                            "Applicant is the inventor",
                                            "Inventor assigned the rights to the applicant",
                                            "The inventor was employed by applicant",
                                            "Other"
                                        ]
                                            .map((label:string) => ({ id: label, name: label }))}
                                    sx={{ minWidth: 200}}
                                    fullWidth={false}
                                ></SelectInput>
                            </Labelled>

                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    const selectedCountries = formData.countries || [];
                                    return (
                                        <>
                                            {
                                                selectedCountries.map((countryCode: string) => {
                                                    const questions = countryQuestions[countryCode];
                                                    if (!questions) return null;
                                                    return (
                                                            <CountryQuestions countryCode={countryCode} questions={questions}></CountryQuestions>
                                                        );
                                                })
                                            }
                                        </>
                                    );
                                }}
                            </FormDataConsumer>
                        </WizardForm.Step>
                        <WizardForm.Step label="Select Agents">
                            {
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        const countriesDatagrid = createCountriesDatagrid(formData, countryPriceCalc, defaultAgentId)
                                            ?.filter((record: any) => record.total && record.total > 0);
                                        if (!countriesDatagrid || countriesDatagrid.length === 0) return null;

                                        return (
                                            <ListContextRawData data={countriesDatagrid}>
                                                <p>Please select the local agents you want use. The costs will increase if you choose agents that we do not have agreements with.</p>
                                                <Datagrid bulkActionButtons={false} sort={{ field: "name", order: "ASC" }}>
                                                    <CountryFlagField source="name" countryCodeSource={"country_code"} label={"Country"} />
                                                    <CurrencyField label={"Total costs"} source={"total"} currency={"EUR"} convert={{ currency_code: tenant?.currency_code || "USD", factor: tenant?.currency_code === "NOK" ? oneEURToNOK : oneEURToUSD }}/>
                                                    {/*<CurrencyField label={"Total costs"} source={"total"} currency={"EUR"} convert={{ currency_code: "NOK", factor: oneEURToNOK }}/>*/}
                                                    <LocalAgentInput
                                                        countryCodeSource={"country_code"}
                                                        hidden={(record: any) => Boolean(!record.total)}
                                                        required={true}
                                                        defaultValueSource={"defaultAgentId"}></LocalAgentInput>
                                                </Datagrid>
                                            </ListContextRawData>
                                        )
                                    }
                                    }
                                </FormDataConsumer>
                            }
                        </WizardForm.Step>
                        <WizardForm.Step label="Review & send order">
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    const countriesDatagrid = createCountriesDatagrid(formData, countryPriceCalc, defaultAgentId)
                                    if (!countriesDatagrid || countriesDatagrid.length === 0) return null;
                                    const inkSumLine = [...countriesDatagrid, { name: "Total", total: sumField(countriesDatagrid, "total")}]

                                    console.log(formData);
                                    const questionsDatagrid = Object.keys(formData || {})
                                        .filter(key => key?.startsWith("question_"))
                                        .map((key: string) => {
                                            if (key === questionInventorRights.source) {
                                                return {
                                                    question: questionInventorRights.question,
                                                    answer: formData[key]
                                                }
                                            }
                                            return {
                                                question: questions[Number(key.split("_")[1])],
                                                answer: formData[key]
                                            };
                                        });
                                    console.log(questionsDatagrid)
                                    return (
                                        <ListContextRawData data={inkSumLine}>
                                            <Stack direction={"row"} justifyContent={"space-between"} my={2}>
                                                <Box>Please check the order, accept Breeze IP’s terms and conditions, and send the order.</Box>
                                                <Button label={`Excel Export ${currencyConvert.currency_code} Prices`} onClick={() => PCTPricesExportToExcel(inkSumLine, caseRecord, currencyConvert, tenant?.pct_fee)} endIcon={<DownloadIcon></DownloadIcon>} />
                                            </Stack>
                                            <Datagrid
                                                sort={{ field: "name", order: "ASC" }}
                                                bulkActionButtons={false}
                                                expand={isAdmin ? <Expanded formData={formData}></Expanded> : undefined}
                                                // rowSx={(record: any, index: number) => index === inkSumLine.length - 1 ? { fontWeight: "bold !important" } : { fontWeight: 700 }}
                                            >
                                                <CountryFlagField source="name" countryCodeSource={"country_code"} label={"Country"} />
                                                <LocalAgentField countryCodeSource={"country_code"} label={"Agent"}></LocalAgentField>
                                                <CurrencyField source={"official_fees"} currency={"EUR"}></CurrencyField>
                                                <CurrencyField source={"service_fees"} currency={"EUR"}></CurrencyField>
                                                <CurrencyField source={"translation_fees"} currency={"EUR"}></CurrencyField>
                                                <CurrencyField source={"total"} label={"Total costs"} currency={"EUR"} fallback={"TBD"} convert={currencyConvert}></CurrencyField>
                                                {/*<CurrencyField source={"total"} label={"Total costs"} currency={"EUR"} fallback={"TBD"} convert={{ currency_code: "NOK", factor: oneEURToNOK }}></CurrencyField>*/}
                                                {/*<SelectInput source="agent" choices={[{id: "1", name: "Agent 1"}, {id: "2", name: "Agent 2"}]}/>*/}
                                            </Datagrid>
                                            <Stack direction={"row"} spacing={15} justifyContent={"space-between"} marginTop={4}>
                                                <Box maxWidth={700} flex={1}>
                                                    <Datagrid data={questionsDatagrid || []} sort={{ field: "question", order: "ASC" }} bulkActionButtons={false}>
                                                        <TextField source="question" label={<strong>Questions</strong>}/>
                                                        <TextField source="answer" label={false} />
                                                    </Datagrid>
                                                </Box>
                                                <Box>
                                                    <Datagrid data={[
                                                        { count_name: "Number of words in application", count: caseRecord.word_count || "N/A" },
                                                        { count_name: "Number of words in claims", count: caseRecord.word_count_claims_only || "N/A" },
                                                        { count_name: "Number of claims", count: caseRecord.claims_count || "N/A" },
                                                        { count_name: "Number of independent claims", count: caseRecord.independent_claims_count || "N/A" },
                                                        { count_name: "Number of pages in application", count: caseRecord.page_count || "N/A" },
                                                    ]} sort={{ field: "name", order: "ASC" }} bulkActionButtons={false}>
                                                        <TextField source="count_name" label={<strong>Relevant counts</strong>}/>
                                                        <TextField source="count" label={false} textAlign={"right"}/>
                                                    </Datagrid>
                                                </Box>
                                            </Stack>

                                            <Box marginTop={4}><h4>Additional information</h4></Box>
                                            <Stack direction={"row"} spacing={15} marginTop={4}>
                                                <Box maxWidth={400}>
                                                    <Typography variant="body2" component="div" sx={{ maxWidth: "640px" }}>
                                                        <div>If you have a reference number or additional comments, please enter them below.</div>
                                                        {/*<div>({caseRecord.case_ref} is the reference number for this case in Breeze IP portal)</div>*/}
                                                    </Typography>
                                                    <TextInput source="your_reference" fullWidth defaultValue={caseRecord.tenant_case_ref || caseRecord.case_ref}></TextInput>
                                                    <TextInput source="comments" multiline fullWidth minRows={3}></TextInput>
                                                </Box>
                                                <Box flex={1}>
                                                    <Typography variant="body2" component="div" sx={{ maxWidth: "640px" }}>
                                                        <div>If you have made any changes to the application, please send us the revised version(s) to your Breeze contact or to post@breezeip.com</div>
                                                        {/*<div>({caseRecord.case_ref} is the reference number for this case in Breeze IP portal)</div>*/}
                                                    </Typography>

                                                    {/*<Typography variant="body2" component="div" sx={{ maxWidth: "640px" }}>*/}
                                                    {/*    <div>If you have made any changes to the application, you can upload the revised version(s) here</div>*/}
                                                    {/*    /!*<div>({caseRecord.case_ref} is the reference number for this case in Breeze IP portal)</div>*!/*/}
                                                    {/*</Typography>*/}

                                                    {/*<FileInput source="files" label="Upload files" isRequired multiple sx={{ '& .RaFileInput-dropZone': { height: "100%", backgroundColor: "#eee", display: "flex", alignItems: "center", justifyContent: "center" }, height: "100%" }}>*/}
                                                    {/*    <FileField source="src" title="title"/>*/}
                                                    {/*</FileInput>*/}
                                                </Box>
                                            </Stack>

                                            <Box marginTop={4}>
                                                <BooleanInput label={<span>I accept Breeze IP's <Link to={"/terms-pct"} target={"_blank"}>terms and conditions</Link></span>} source="terms_accepted" validate={choices([true], "The terms has to be accepted")} />
                                            </Box>
                                        </ListContextRawData>
                                    )
                                }}
                            </FormDataConsumer>
                        </WizardForm.Step>
                    </WizardForm>
                </Create>
            </Box>
        </Page>
    );
}


const createCountriesDatagrid = (formData: FieldValues, countryPriceCalc: any, defaultAgentId: any) => formData.countries?.map((code: string) => ({
    country_code: code,
    name: allCountries[code],
    ...countryPriceCalc(code, formData[code]?.applicant_employees),
    defaultAgentId: defaultAgentId(code)
}));


const Expanded = (props: any) => {
    const record = useRecordContext();
    console.log("EXPANDED", record);
    const calculations = record?.official_fee_calculations;
    if (!calculations) return null;
    return (
        <Box>
            <Box>Official fee calculations in EUR (1 {calculations.official_fee_currency} ≈ {calculations.official_fee_currency_factor} EUR)</Box>
            <Box>Basic fee: {calculations.basic_filing_fee_eur} ({calculations.basic_filing_fee} {calculations.official_fee_currency}{ calculations.applicant_employees ? <span> | Entity status: {calculations.applicant_employees}</span> : ""})</Box>

            <Box>Extra claims fee: {calculations.extra_claims_fee_eur}</Box>
            <Box>Extra independent claims fee: {calculations.extra_independent_claims_fee_eur}</Box>
            <Box>Extra pages fee: {calculations.extra_pages_fee_eur}</Box>
            <Box>Examination fee: {calculations.examination_fee}</Box>
            <Box>Official fee (total): {record.official_fees}</Box>
        </Box>
    );
}

const priceCalc = (caseRecord: any, priceTable: Array<any>) => (countryCode: string, employees?: string)  => {
    const employeesPredicate = (p: any) => !employees || employees === p.applicant_employees;
    const countryPrices = priceTable.filter((p: any) => p.country_code === countryCode);
    const priceEntry = countryPrices.length === 1 ?
        countryPrices[0] :
        countryPrices.find(employeesPredicate) || countryPrices[0];

    if (!priceEntry) return { total: 0 };

    const serviceFees = (priceEntry?.our_fee || 0) + (priceEntry?.local_agent_fee || 0);
    const translationFee = calcTranslationFee(caseRecord, priceEntry);
    const {official_fee_eur, ...official_fee_calculations } = calcOfficialFee(caseRecord, priceEntry);

    const total = official_fee_eur + serviceFees + translationFee
    return {
        official_fees: official_fee_eur,
        service_fees: serviceFees,
        translation_fees: translationFee,
        official_fee_calculations,
        total,
    }
};

const sumField = (array: Array<any>, fieldName: string): number => array.reduce((previousValue, currentValue, currentIndex, array) => {
    const field = currentValue[fieldName];
    const numberValue = field ? Number(field) : 0;
    const safeNumber = isNaN(numberValue) ? 0 : numberValue;
    console.log(previousValue, currentValue, field, numberValue, safeNumber);
    return previousValue + safeNumber;
}, 0);

const Labelled = (props: any) => {
    const { label, children, ...rest } = props;
    return (
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Box alignSelf={"center"} marginRight={1}>
                {label}
            </Box>
            { children }
        </Stack>
    );

}
const countryQuestions: {[key: string]: any} = {
    no: [{
        question: "How many employees does the applicant have?",
        label: "Number of employees",
        source: "applicant_employees",
        type: "select",
        choices: toChoices([
            "1-20",
            "21 or more",
        ])
    }],
    us: [
        {
            question: "How many employees does the applicant have?",
            label: "Number of employees",
            source: "applicant_employees",
            type: "select",
            choices: toChoices([
                "1 employee",
                "500 or less",
                "501 or more",
            ])
        },
        {
            question: "Does the claim set contain multiple dependent claims?",
            source: "multiple_dependent_claims",
            type: "select",
            choices: yesNoChoices
        }
    ],
    ep: [
        {
            question: "Would you like to extend this European application to the following extension and validation states: BA, KH, MD, ME, MA and TN?",
            source: "extension_and_validation",
            type: "select",
            choices: yesNoChoices
        },
        {
            question: "This application contains more than 15 claims. Would you like to reduce the official excess claims fees?",
            source: "reduce_claims",
            type: "select",
            choices: yesNoChoices
        }
    ],
    br: [
        {
            question: "Does the application contain biological material?",
            source: "biological_material",
            type: "select",
            choices: yesNoChoices
        }
    ]
};
const CountryQuestions = (props: any) => {
    const { countryCode, questions } = props;

    return (
        <Box>
            <h3><Flag countryCode={countryCode}></Flag>{` ${countries[countryCode]}`}</h3>
            {
                questions.map((question: any, index: number) => (
                    <Question countryCode={countryCode} questionText={question.question} questionSelectLabel={question.label} choices={question.choices} questionSource={question.source}></Question>
                ))
            }
        </Box>
    );
}
const Question = (props: any) => {
    const { countryCode, questionText, questionSelectLabel, questionSource, choices } = props;
    return (
            <Labelled label={questionText}>
                <SelectInput
                    source={`${countryCode}.${questionSource}`}
                    label={questionSelectLabel || false}
                    validate={required()}
                    choices={choices}
                    sx={{ minWidth: 200}}
                    fullWidth={false}
                ></SelectInput>
            </Labelled>
    );
}

const NorwayQuestions = () => {
    return (
        <>
            <h3><Flag countryCode={"no"}></Flag>{" Norway"}</h3>
            <Labelled label={"How many employees does the applicant have?"}>
                <SelectInput
                    source="no.applicant_employees"
                    label={"Number of employees"}
                    validate={required()}
                    choices={
                        [
                            "1-20",
                            "21 or more",
                        ].map((label:string) => ({ id: label, name: label }))}
                    sx={{ minWidth: 200}}
                ></SelectInput>
            </Labelled>
        </>
    );
}

const USQuestions = () => {
    return (
        <>
            <h3><Flag countryCode={"us"}></Flag>{" USA"}</h3>

            <Labelled label={"How many employees does the applicant have?"}>
                <SelectInput
                    source="us.applicant_employees"
                    label={"Number of employees"}
                    validate={required()}
                    choices={
                        [
                            "1 employee",
                            "500 or less",
                            "501 or more",
                        ].map((label:string) => ({ id: label, name: label }))}
                    sx={{ minWidth: 200}}
                ></SelectInput>
            </Labelled>
            <Labelled label={"Does the claim set contain multiple dependent claims?"}>
                <SelectInput
                    source="us.multiple_dependent_claims"
                    label={false}
                    validate={required()}
                    choices={yesNoChoices}
                    sx={{ minWidth: 200}}
                ></SelectInput>
            </Labelled>
        </>
    );
}

const extension_and_validation = {
    question: "Would you like to extend this European application to the following extension and validation states: BA, KH, MD, ME, MA and TN?",
    source: "ep.extension_and_validation",
}
const EPQuestions = () => {
    return (
        <>
            <h3>{"European Patent Application (EP)"}</h3>

            <Labelled label={"Would you like to extend this European application to the following extension and validation states: BA, KH, MD, ME, MA and TN?"}>
                <SelectInput
                    source="ep.extension_and_validation"
                    label={false}
                    validate={required()}
                    choices={yesNoChoices}
                    sx={{ minWidth: 200}}
                ></SelectInput>
            </Labelled>
            <Labelled label={"This application contains more than 15 claims. Would you like to reduce the official excess claims fees?"}>
                <SelectInput
                    source="ep.reduce_claims"
                    label={false}
                    validate={required()}
                    choices={yesNoChoices}
                    sx={{ minWidth: 200}}
                ></SelectInput>
            </Labelled>
        </>
    );
}

const BrazilQuestions = () => {
    return (
        <>
            <h3><Flag countryCode={"br"}></Flag>{" Brazil"}</h3>

            <Labelled label={"Does the application contain biological material?"}>
                <SelectInput
                    source="br.biological_material"
                    label={false}
                    validate={required()}
                    choices={yesNoChoices}
                    sx={{ minWidth: 200}}
                ></SelectInput>
            </Labelled>
        </>
    );
}
