import React, {Fragment, FunctionComponent, useEffect, useState} from "react";
import {countries} from "../../utils/countries";
import {CaseRoleList} from "./CaseRoleList";
import {PDFViewer} from "../../utils/PDFViewer";
import {AnnuityCaseActionsList, CaseActionsList} from "./CaseActionsList";
import {alternateBackgroundColor, CASE_FIELDS, CASE_TYPE_PATENT, caseActionsFilter, compactTable} from "../CaseList";
import {
    AutocompleteInput,
    BooleanInput,
    Button,
    Datagrid,
    DatagridBody,
    DatagridBodyProps,
    DatagridHeaderProps,
    DatagridProps,
    DatagridRowProps,
    DateField,
    Edit,
    EditButton,
    FieldProps,
    Filter,
    FunctionField,
    Link,
    List,
    ListButton,
    RecordContextProvider,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    ReferenceOneField,
    SaveButton,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
    useCreate,
    useDataProvider,
    useGetList,
    useNotify,
    useRecordContext,
    useRefresh,
    WithRecord
} from "react-admin";
import {CaseTitle} from "./CaseTitle";
import {CopyCaseButton} from "../CopyCaseButton";
import PlusIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/GetApp';
import {useIsAdmin} from "../../auth/utils";
import {Box, Chip, Grid, IconButton, Stack,
    TableCell, TableRow, Typography} from "@mui/material";
import {CreateActionsButton} from "../actions/CreateActionsButton";
import {CreateInDialogButton, EditInDialogButton, ShowInDialogButton} from "@react-admin/ra-form-layout";
import {DOCUMENT_FIELDS, documentTypes} from "../../documents/documents";
import {QuickFilter} from "../../utils/QuickFilter";
import {ANNUITY, RENEWAL} from "../actions/utils";
import {dateFormat} from "../actions/Actions";
import {TenantContext} from "../../react-admin-overrides/AppLayout";
import {CaseRefField} from "./CaseRefField";
import {FlagField} from "../../utils/FlagField";
import {ApplicationNumberField} from "./AppplicationNumberField";
import {CitationsField} from "./CitationsField";
import moment from "moment";
import {CASE_STATUS_CLOSED, CASE_STATUS_GRANTED} from "./utils";
import {SyncWithEPOButton} from "./CaseSyncWithEPO";
import {DesignRenewalsList} from "../design/DesignRenewals";
import { CreateNewFolder, Folder } from "@mui/icons-material";
import { useListContext } from 'react-admin';
import ArticleIcon from '@mui/icons-material/Article';
import {ChargesCreateInDialog, ChargesList} from "../../charges/charges";
import {ListContextRawData} from "../../utils/ListContextRawData";

export const PreviewButton: FunctionComponent = () => {
    const record = useRecordContext();

    if (!record?.original_file_name) {
        return null;
    }

    const unsupportedExtensions = ["doc", "docx", "xls", "xlsx", "msg"];
    const fileExtension = record.original_file_name.split(".").pop()?.toLowerCase();

    if (unsupportedExtensions.includes(fileExtension || "")) {
        return null;
    }

    return (
        <CreateInDialogButton
            resource="case_documents"
            title="Preview file"
            key={record.bucket_file_name}
            label="Preview"
            maxWidth={false}
            fullWidth
            record={{
                original_file_name: record.original_file_name,
                bucket_file_name: record.bucket_file_name,
            }}
        >
            <PDFViewer bucketFileName={record.bucket_file_name} />
        </CreateInDialogButton>
    );
};

const EmptyDocumentList = () => (
    <Typography variant="body1" color="textSecondary">
        <p>No documents associated with this case yet.</p>
        <AddDocumentButton/>
    </Typography>
);

interface File {
    id: number;
    file_name: string;
    uploaded_by: number;
}

interface RootDocumentOrFolder {
    id: number,
    file_name: string | null | undefined, // If file
    title: string | null | undefined, // If folder
    isFolder: boolean,
    folder_id: number | null,
    created_at: string,
    original_file_name: string | null,
    uploaded_by: number | null,
}

interface User {
    id: number;
    name: string;
}

const FileGrid = (folder: any, record: any) => {
    const dataProvider = useDataProvider();
    const [filesData, setFilesData] = React.useState<File[]>([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        dataProvider.getList<File>('case_documents', {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'id', order: 'ASC' },
            filter: {
                folder_id: folder.id
            }
        })
        .then(({ data }) => {
            setFilesData(data);
        })
        .finally(() => {
            setLoading(false);
        });
    }, [dataProvider]);


    return (
        <ListContextRawData data={filesData}>
            <Box sx={{
                pl: 4,
                pr: 0,
                width: "100%"
            }}>
                <Datagrid
                    sx={{
                        '& .RaDatagrid-headerCell': {
                            background: 'transparent',
                        },
                        '& .RaDatagrid-tbody': {
                            borderTop: 'none',
                        },
                        '& .RaDatagrid-row td:last-child': {
                            paddingRight: 0
                        },
                        // Add these styles to fix cell alignment when header is removed
                        '& .MuiTableCell-root': {
                            padding: '8px 16px',
                        },
                        '& .RaDatagrid-tbody .MuiTableCell-root': {
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        },
                        '.MuiTableHead-root': {
                            display: 'none'
                        }
                    }}
                    sort={{ field: "title", order: "ASC" }}
                    empty={loading ? <Typography>Loading...</Typography> : <Typography>Empty folder</Typography>}
                    isRowSelectable={() => false}
                    bulkActionButtons={false}
                >
                    <ArticleIcon/>
                    <TextField source={"title"} sx={{ width: '224px', display: 'block' }}/>
                    <DateField source={"created_at"} showTime label={"Date created"}/>
                    <TextField source={"original_file_name"} label={"File name"}/>
                    <ReferenceField source="uploaded_by" reference="users" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <PreviewButton/>
                    <DownloadDocumentButton/>
                    <EditDocumentButton caseId={record?.id}/>
                </Datagrid>
            </Box>
        </ListContextRawData>
    );
};

const BreezeDocumentsGrid = (props: DatagridProps) => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const {data} = useListContext();
    const isAdmin = useIsAdmin();

    const [rootDocumentData, setRootDocumentData] = React.useState<any[]>([]);
    const [allFilesData, setAllFilesData] = React.useState<any[]>([]);
    const [foldersData, setFoldersData] = React.useState<any[]>([]);
    const [combinedData, setCombinedData] = React.useState<any[]>([]);
    const caseRecord = useRecordContext();
    const [users, setUsers] = React.useState<User[]>([]);

    const isFolderEmpty = (folderId: any, filesData: any[]) => {
        return !filesData.some(file => file.folder_id === folderId);
    };

    React.useEffect(() => {
        const getFolders = dataProvider.getList<File>('case_document_folders', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'ASC' },
            filter: {case_id: record?.id}
        })

        const getDocumentData = dataProvider.getList<File>('case_documents', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'ASC' },
            filter: {case_id: record?.id, folder_id: {
                format: "hasura-raw-query",
                value: { _is_null: true },
            }}
        })

        const getFiles = dataProvider.getList<File>('case_documents', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'ASC' },
            filter: {case_id: record?.id}
        })

        Promise.all([getFolders, getDocumentData, getFiles]).then(([folders, documents, files]) => {
            setFoldersData(folders.data);
            setRootDocumentData(documents.data);
            setAllFilesData(files.data);
        });
    }, [dataProvider, record?.id]);

    React.useEffect(() => {
        const normalizedData = [
            ...rootDocumentData.map(doc => ({
                ...doc,
                isFolder: false,
                displayName: doc.title
            })),
            ...foldersData.map(folder => ({
                ...folder,
                isFolder: true,
                displayName: folder.name
            }))
        ];
        setCombinedData(normalizedData);
    }, [rootDocumentData, foldersData]);

    return (
        <>
            <Datagrid
                data={combinedData}
                sort={{ field: "displayName", order: "ASC" }}
                expand={<FileGrid folder={record?.id}/>}
                isRowExpandable={record => record && record.isFolder && !isFolderEmpty(record.id, allFilesData)}
                bulkActionButtons={false}
            >
                <FunctionField
                    render={(record: { isFolder: any; }) => record.isFolder ? <Folder/> : <ArticleIcon/>}
                />
                <TextField source="displayName" label={"Name"} sx={{ width: '200px',display: 'block' }}/>
                <DateField source={"created_at"} showTime label={"Date created"}/>
                {!record?.isFolder && <TextField source={"original_file_name"} label={"File name"}/>}
                {record && !record.isFolder && <ReferenceField source="uploaded_by" reference="users" link={false}>
                    <TextField source={record.name} />
                </ReferenceField>}
                <PreviewButton/>
                <DownloadDocumentButton/>
                <FunctionField
                    render={(record: { isFolder: any; id: any; }) => {
                        return record && !record.isFolder
                        ? <EditDocumentButton caseId={caseRecord?.id}/>
                        : <EditFolderButton/>
                    }}
                />
            </Datagrid>

        </>

    );
}

const CaseTabLayout = (props: any) => {
    const isAdmin = useIsAdmin();
    const record = useRecordContext();
    const {tenant} = React.useContext(TenantContext) || {};
    const documents = tenant?.feature_documents || isAdmin;

    return (
        <TabbedShowLayout>
            <Tab label="overview">
                <Grid container spacing={2} sx={{maxWidth: "800px"}}>
                    <Grid item xs={6}>
                        <WithRecord render={(record: any) =>
                            <SimpleShowLayout>
                                <TextField source={CASE_FIELDS.CASE_TYPE}/>
                                <TextField source={CASE_FIELDS.APPLICATION_NUMBER}/>
                                {/*<TextField source={CASE_FIELDS.COUNTRY_CODE} />*/}
                                <FunctionField
                                    label="Country"
                                    render={(record: any) => countries[record[CASE_FIELDS.COUNTRY_CODE]]}
                                />
                                {record[CASE_FIELDS.COUNTRY_CODE] === "pc" &&
                                    <TextField source={CASE_FIELDS.DEMAND_FOR_CHAPTER_2}
                                               label={"Demand for chapter II"}/>}
                                <TextField source={CASE_FIELDS.CATCHWORD}/>
                                <TextField source={CASE_FIELDS.INVENTION_TITLE}/>
                                <TextField source={CASE_FIELDS.APPLICATION_TYPE}/>
                                <TextField source={CASE_FIELDS.APPLICATION_TYPE_2}/>
                                <TextField source={CASE_FIELDS.BASED_ON_PCT}/>
                                <DateField source={CASE_FIELDS.FILING_DATE}/>
                                <DateField source={CASE_FIELDS.INTERNATIONAL_FILING_DATE}/>
                                <DateField source={CASE_FIELDS.NATIONAL_FILING_DATE}/>
                                <TextField source={"entity_size"} label={"Override entity size"}/>
                            </SimpleShowLayout>
                        }/>
                    </Grid>
                    <Grid item xs={6}>
                        <SimpleShowLayout>
                            <DateField source={CASE_FIELDS.REGISTRATION_DATE}/>
                            <TextField source={CASE_FIELDS.REGISTRATION_NUMBER}/>
                            <TextField source={CASE_FIELDS.STATUS}/>
                            <DateField source={CASE_FIELDS.FIRST_PRIORITY_FILING_DATE}/>
                            <TextField source={CASE_FIELDS.PRIORITY_APPLICATION_NUMBER}/>
                            <FunctionField
                                label="Priority country"
                                render={(record: any) => countries[record[CASE_FIELDS.PRIORITY_COUNTRY_CODE]]}
                            />
                            <TextField source={CASE_FIELDS.DESIGNATED_STATES}/>
                            <DateField source={"expiration_date"}/>
                            <DateField source={CASE_FIELDS.CREATED_AT}/>
                            <DateField source={CASE_FIELDS.UPDATED_AT}/>
                            {
                                isAdmin &&
                                <DateField source={"closed_at"}/>
                            }
                            {
                                isAdmin &&
                                <TextField source={"closed_event_description"}/>
                            }
                            {
                                isAdmin &&
                                <TextField source={"word_count"}/>
                            }
                        </SimpleShowLayout>
                    </Grid>
                </Grid>
            </Tab>
            {
                <Tab label="roles" path="roles">
                    <ReferenceManyField label="Roles" reference="case_roles" target="case_id">
                        <CaseRoleList {...props} />
                    </ReferenceManyField>
                </Tab>
            }
            {
                isAdmin &&
                <Tab label="admin" path="admin">
                    <Edit hasShow={false}>
                        <SimpleForm toolbar={
                            <Toolbar>
                                <SaveButton label="Save" />
                            </Toolbar>
                        }>
                            <Stack spacing={5}>
                                <BooleanInput source="calc_maintenance_actions_and_fees" helperText={"Switching this off will turn off future action calculations for Annuities and Renewals. It will not delete already existing actions"} />
                                <TextInput source="import_comments" helperText={"This text will be included in the Excel report we send to clients after we have concluded importing their data to the system"} />
                            </Stack>
                        </SimpleForm>
                    </Edit>
                </Tab>
            }
            {
                documents &&
                <Tab label="documents" path="documents">
                    <WithRecord render={caseRecord => {
                            return (
                                <>
                                    {isAdmin && <List
                                        resource={"incoming_mail"}
                                        exporter={false}
                                        sort={{field: "created_at", order: "ASC"}}
                                        filter={{case_id: caseRecord?.id}}
                                    >
                                        <Datagrid>
                                            <TextField source={"subject"} label={"Subject"}></TextField>
                                            <DateField source={"created_at"} label={"Received"} showTime></DateField>
                                            <FunctionField
                                                label="Case ref"
                                                render={(record: any) =>
                                                    record.case_id ? (
                                                        <>
                                                        <ReferenceField
                                                            source="case_id"
                                                            reference="cases"
                                                            record={record}
                                                        >
                                                            <TextField source="case_ref"/>
                                                        </ReferenceField>
                                                        <EditInDialogButton label="Override case" >
                                                            <SimpleForm>
                                                                <ReferenceInput source="case_id" reference="cases" title="subject">
                                                                    <AutocompleteInput optionText="application_number" />
                                                                </ReferenceInput>
                                                            </SimpleForm>
                                                        </EditInDialogButton>
                                                        </>
                                                    ) : <>
                                                        <Typography>No case reference found in email </Typography>
                                                        <EditInDialogButton label="Set case" >
                                                            <SimpleForm>
                                                                <ReferenceInput source="case_id" reference="cases" title="subject">
                                                                    <AutocompleteInput optionText="application_number" />
                                                                </ReferenceInput>
                                                            </SimpleForm>
                                                        </EditInDialogButton>
                                                    </>
                                                }
                                            />
                                            {/**<TextField source={"from"} label={"From"}></TextField>
                                            <FunctionField
                                                source="to"
                                                label="To"
                                                render={(record: any) => {
                                                    try {
                                                        const recipients = JSON.parse(record.to || '[]');
                                                        return recipients.map((recipient: any, index: number) => (
                                                            <div key={index}>
                                                                {typeof recipient === 'string' ? recipient : recipient.email}
                                                            </div>
                                                        ));
                                                    } catch (e) {
                                                        return record.to; // Fallback to raw text if parsing fails
                                                    }
                                                }}
                                            />*/}
                                            <FunctionField
                                                source="body"
                                                label="Body"
                                                render={(record: any) =>
                                                    record.body?.substring(0, 50) + (record.body?.length > 50 ? "..." : "")
                                                }
                                            />
                                            {//<TextField source={"attachments"} label={"Attachments"}></TextField>
                                            }
                                            <FunctionField
                                                source="attachments"
                                                label="Attachments"
                                                render={(record: any) => {
                                                    try {
                                                        const attachments = JSON.parse(record.attachments || '[]');
                                                        return attachments.map((attachment: any, index: number) => (
                                                            <div key={index}>{attachment.original_file_name }</div>
                                                        ));
                                                    } catch (e) {
                                                        return record.attachments; // Fallback to raw text if parsing fails
                                                    }
                                                }}
                                            />
                                        </Datagrid>
                                    </List>
                        }</>
                    )
                        }
                    }

                        />
                    <WithRecord render={(record: any) => (
                        <List
                            resource="case_documents"
                            filter={{case_id: record?.id}}
                            sort={{field: "created_at", order: "DESC"}}
                            filters={<DocumentsFilter/>}
                            exporter={false}
                            pagination={false}
                            perPage={50}
                            empty={<EmptyDocumentList />}
                        >
                            {/**<Datagrid>
                             <TextField source={"title"}/>
                             <ReferenceField source="folder_id" reference="case_document_folders" link={false}>
                             <TextField source="name" />
                             </ReferenceField>
                             <DateField source={"created_at"} showTime label={"Uploaded"}/>
                             <TextField source={"original_file_name"} label={"File name"}/>
                             <ReferenceField source="uploaded_by" reference="users" link={false}>
                             <TextField source="name" />
                             </ReferenceField>
                             <PreviewButton/>
                             <DownloadDocumentButton/>
                             <EditDocumentButton caseId={record?.id}/>
                             </Datagrid>*/}
                            <BreezeDocumentsGrid />
                        </List>
                    )}/>
                </Tab>
            }
            <Tab label="actions" path="actions">
                <div>
                    <h3>Actions</h3>
                    <ReferenceManyField label="Actions" reference="case_actions" target="case_id"
                                        sort={{field: "due_date", order: "ASC"}}
                                        filter={caseActionsFilter}>
                        <CaseActionsList/>
                    </ReferenceManyField>
                    <WithRecord render={(record: any) => (
                        record?.case_type === CASE_TYPE_PATENT ?
                            <Box>
                                <h3>Annuities</h3>
                                <ReferenceManyField label="Actions" reference="case_actions" target="case_id"
                                                    sort={{field: "due_date", order: "ASC"}}
                                                    filter={{"action_rule#action_type": ANNUITY}}>
                                    <AnnuityCaseActionsList/>
                                </ReferenceManyField>
                            </Box> :
                            <Box>
                                <h3>Renewals</h3>
                                <ReferenceManyField label="Actions" reference="case_actions" target="case_id"
                                                    sort={{field: "due_date", order: "ASC"}}
                                                    filter={{"action_rule#action_type": RENEWAL}}>
                                    <DesignRenewalsList/>
                                </ReferenceManyField>
                            </Box>
                    )} />
                </div>
                {/*<ReferenceManyField reference="case_actions" target="case_id"*/}
                {/*                    sort={{field: "due_date", order: "ASC"}}*/}
                {/*                    filter={caseActionsFilter}*/}
                {/*>*/}
                {/*    <CaseActionsList/>*/}
                {/*</ReferenceManyField>*/}
            </Tab>
            {
                isAdmin &&
                <Tab label="family" path="family">
                    <WithRecord render={(record: any) => {
                        if (record?.case_type === CASE_TYPE_PATENT && record?.case_ref) {
                            const familyPart = record?.case_ref?.match(/P\d+/)[0];
                            return (
                                <div>
                                    <h3>Family / case_ref starting with {familyPart}</h3>
                                    <List
                                        resource={"cases"}
                                        storeKey={"cases/family-cases"}
                                        sort={{field: "case_ref", order: "ASC"}}
                                        filter={{"case_ref": familyPart}}
                                        perPage={100}
                                    >
                                        <Datagrid bulkActionButtons={false} rowSx={alternateBackgroundColor}
                                                  sx={compactTable}
                                        >
                                            <CaseRefField source={CASE_FIELDS.CASE_REF}></CaseRefField>
                                            <TextField source={CASE_FIELDS.APPLICANT}/>
                                            <FlagField label={"Country"} source={"country_code"}
                                                       sx={{textWrap: "nowrap"}}></FlagField>
                                            <ApplicationNumberField source={CASE_FIELDS.APPLICATION_NUMBER}/>
                                            <TextField source={CASE_FIELDS.CATCHWORD}/>
                                            <FunctionField
                                                label={"Title"}
                                                source={CASE_FIELDS.INVENTION_TITLE}
                                                render={(record: any) => <span
                                                    title={record?.invention_title}>{record?.invention_title?.length > 50 ? record?.invention_title?.substr(0, 50) + "..." : record?.invention_title}</span>}
                                            ></FunctionField>
                                            <TextField source={CASE_FIELDS.APPLICATION_TYPE}/>
                                            <FunctionField
                                                label="Priority claim"
                                                render={(record: any) => record[CASE_FIELDS.PRIORITY_APPLICATION_NUMBER] ? "With priority" : "Without priority"}
                                            />
                                            <TextField source={CASE_FIELDS.BASED_ON_PCT}/>
                                            <DateField source={CASE_FIELDS.FILING_DATE}/>
                                            <DateField source={CASE_FIELDS.INTERNATIONAL_FILING_DATE}/>
                                            <DateField source={CASE_FIELDS.NATIONAL_FILING_DATE}/>
                                            <DateField source={CASE_FIELDS.REGISTRATION_DATE}/>
                                            <TextField source={CASE_FIELDS.REGISTRATION_NUMBER}/>
                                            <DateField source={CASE_FIELDS.PUBLICATION_DATE}/>
                                            <TextField source={CASE_FIELDS.PUBLICATION_NUMBER}/>
                                            <TextField source={CASE_FIELDS.STATUS}/>

                                            <DateField source={CASE_FIELDS.FIRST_PRIORITY_FILING_DATE}/>
                                            <TextField source={CASE_FIELDS.PRIORITY_APPLICATION_NUMBER}/>
                                            <FunctionField
                                                label="Priority Country"
                                                render={(record: any) => record[CASE_FIELDS.PRIORITY_COUNTRY_CODE]?.toUpperCase()}
                                            />

                                            <TextField source={CASE_FIELDS.PRODUCT}/>
                                            <TextField source={CASE_FIELDS.DEPARTMENT}/>
                                            <CitationsField label={"Citations"}/>
                                            <FunctionField
                                                label={"Priority claims"}
                                                render={(record: any) => <span>{(record?.priority_claims || []).map(
                                                    (c: any) => <Chip
                                                        label={`${c.priority_country_code} ${c.priority_application_number} ${c.priority_filing_date}`}
                                                        sx={{marginBottom: 1}}></Chip>)
                                                }</span>}
                                            ></FunctionField>
                                            <ReferenceField reference="names" source={"primary_agent_id"}
                                                            label={"Agent"}>
                                                <TextField source={"name"}/>
                                            </ReferenceField>
                                            <ReferenceField reference="names" source={"local_agent_id"}
                                                            label={"Local Agent"}>
                                                <TextField source={"name"}/>
                                            </ReferenceField>
                                            <ReferenceOneField
                                                label="Next annuity"
                                                reference="case_actions"
                                                target={"case_id"}
                                                sort={{field: "due_date", order: "ASC"}}
                                                filter={{
                                                    "completed_at@_is_null": true,
                                                    "action_rule#action_type": ANNUITY,
                                                    "due_date@_gte": moment().format(dateFormat)
                                                }}
                                            >
                                                <DateField source="due_date"/>
                                            </ReferenceOneField>
                                        </Datagrid>
                                    </List>
                                </div>
                            )
                        }
                        return null;
                    }}></WithRecord>
                    {/*<ReferenceManyField reference="case_actions" target="case_id"*/}
                    {/*                    sort={{field: "due_date", order: "ASC"}}*/}
                    {/*                    filter={caseActionsFilter}*/}
                    {/*>*/}
                    {/*    <CaseActionsList/>*/}
                    {/*</ReferenceManyField>*/}
                </Tab>
            }

            {
                isAdmin &&
                <Tab label="charging" path="charging">
                    <WithRecord render={(record: any) => {
                        return <ChargesList filter={{ case_id: record?.id }} storeKey={"cases/charges"} caseId={record?.id}></ChargesList>;
                    }}></WithRecord>
                </Tab>
            }
        </TabbedShowLayout>
    );
}

export const CaseShow = (props: any) => {
    const isAdmin = useIsAdmin();
    const record = useRecordContext();
    const {tenant} = React.useContext(TenantContext) || {};
    const documents = tenant?.feature_documents || isAdmin;

    return (
        <Show {...props} title={<CaseTitle/>} actions={<CaseShowActions/>}>
            <h1><CaseTitle/></h1>
            <CaseTabLayout {...props}></CaseTabLayout>
        </Show>
    )
};

export const CaseShowInDialog = (props: any) => {
    return (
        <ShowInDialogButton resource={"cases"} {...props} title={<CaseTitle/>} actions={<CaseShowActions/>} maxWidth={"xl"} fullWidth>
            <CaseTabLayout {...props}></CaseTabLayout>
        </ShowInDialogButton>
    )
};

const DocumentsFilter = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Filter {...props}>
            <SelectInput source={"type"} choices={documentTypes} emptyText={"All"}/>
            <QuickFilter source={"type@_neq"} label={"Hide citations"} defaultValue={"CITATION"}></QuickFilter>
            {/* Add Title filter */}
            <TextInput label="Search by Title" source="title" alwaysOn />

            {/* Add File name filter */}
            <TextInput label="Search by File Name" source="original_file_name" alwaysOn />
        </Filter>
    );
};


export const DownloadDocumentButton = () => {
    const record = useRecordContext();
    const [startDownload, setStartDownload] = useState<boolean>(false);
    const [downloadLink, setDownloadLink] = useState<string>();

    useEffect(() => {
        if (startDownload) {
            // fetch(`https://6pk5u8qwz3.execute-api.eu-north-1.amazonaws.com/getDownloadUrl/${record.bucket_file_name}/${record.original_file_name}`)
            fetch(`${process.env.REACT_APP_FUNCTIONS_URL}/getDownloadUrl?bucketFilename=${record?.bucket_file_name}&originalFilename=${record?.original_file_name}`)
                .then(r => r.json())
                .then((body) => setDownloadLink(body.url));
            setStartDownload(false);
        }
    }, [startDownload])

    useEffect(() => {
        if (downloadLink) {
            console.log("link", downloadLink);
            const link = document.createElement('a');
            link.href = `${downloadLink}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }, [downloadLink])

    if (!record?.bucket_file_name) return null;

    return (
        <Button
            label="Download"
            onClick={() => setStartDownload(true)}
        >
            <DownloadIcon/>
        </Button>
    )
};




const CaseShowActions = () => {
    const isAdmin = useIsAdmin();
    const record = useRecordContext();
    return (
        <TopToolbar>
            <ListButton label={"Cases"}/>
            {/*{isAdmin && <EditButton/>}*/}
            <EditButton/>
            {/*{isAdmin && <AddDocumentButton/>}*/}
            <AddDocumentButton/>
            <AddFolderButton/>
            {isAdmin && <CopyCaseButton label={"Copy to other country"}/>}
            {isAdmin && <CreateActionsButton/>}
            {/*<CopyCaseButton label={"Copy to other country"}/>*/}
            {isAdmin && <RecreateActionsButton/>}
            {isAdmin && <SyncWithEPOButton/>}
            {isAdmin && <ChargesCreateInDialog caseId={record?.id}/>}
        </TopToolbar>
    );
};

const AddDocumentButton = () => {
    const caseRecord = useRecordContext();
    const isAdmin = useIsAdmin();
    const {tenant} = React.useContext(TenantContext) || {};
    const documents = tenant?.feature_documents || isAdmin;
    return documents ? (
        <Button
            component={Link}
            to={{
                pathname: "/case_documents/create",
                // Here we specify the initial record for the create view
                state: {record: {case_id: caseRecord?.id}},
                search: caseRecord ? `case_id=${caseRecord.id}` : ""
            }}
            label="Add documents"
        >
            <PlusIcon/>
        </Button>
    ) : null;
};

const AddFolderButton = () => {
    const tenant = React.useContext(TenantContext);
    const isAdmin = useIsAdmin();
    const documents = tenant?.tenant?.feature_documents || isAdmin;
    const refresh = useRefresh();
    const [create] = useCreate();

    if (!documents) return null;
    const folderSave = (data: any) => {
        create('case_document_folders', { data });
    };



    // @ts-ignore
    return (
        <ReferenceManyField target="case_id" reference="case_document_folders">
            <WithRecord render={record => (
                <CreateInDialogButton
                record={{ case_id: record.id, tenant_id: tenant?.tenant.id }}
                label="Create Folder"
                icon={<CreateNewFolder/>}
                >
                    <SimpleForm>
                        <TextInput source="name" />
                    </SimpleForm>
                </CreateInDialogButton>
            )} />
        </ReferenceManyField>
    );
}
const EditFolderButton = () => {
    const record = useRecordContext();
    const refresh = useRefresh();

    if (!record?.isFolder) {
        return null;
    }

    return (
        <EditInDialogButton
            fullWidth
            mutationMode="pessimistic"
            title={`Edit folder '${record.name}'`}
            resource="case_document_folders"
            mutationOptions={{
                onSuccess: () => refresh()
            }}
        >
            <SimpleForm maxWidth="sm">
                <TextInput source="name" />
            </SimpleForm>
        </EditInDialogButton>
    );
};

const EditDocumentButton = (caseId: any) => {
    const refresh = useRefresh();
    const isAdmin = useIsAdmin();
    const caseDocument = useRecordContext();
    return (
        <EditInDialogButton
            fullWidth
            mutationMode={"pessimistic"}
            title={`Edit document '${caseDocument?.title}'`}
            mutationOptions={{
                onSuccess: () => refresh()
            }}
            >
            <Box sx={{minWidth: "600px"}}>
                <SimpleForm maxWidth={"sm"}>
                    {/*{ isAdmin && <SelectInput source={DOCUMENT_FIELDS.TYPE} isRequired choices={documentTypes}/>}*/}
                    <TextInput source={DOCUMENT_FIELDS.TITLE} resettable fullWidth/>
                    <Box display="flex" alignItems="center" gap={2}>
                        <ReferenceInput source="folder_id" reference={DOCUMENT_FIELDS.FOLDER} filter={{ case_id: caseId.caseId }}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <AddFolderButton />
                    </Box>
                    {/*{isAdmin && <TextInput source={DOCUMENT_FIELDS.DESCRIPTION} multiline resettable fullWidth/>}*/}
                    {/*<CitationSpecificFields/>*/}
                </SimpleForm>
            </Box>
        </EditInDialogButton>
    );
};


export const RecreateActionsButton = () => {
    const caseRecord = useRecordContext();
    if (!caseRecord) return null;

    return (
        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Recreate actions`} label={`Recreate actions`}
                            icon={<></>}>
            <RecreateActionsForm/>
        </EditInDialogButton>
    );
}

export const RecreateActionsForm = () => {
    const caseRecord = useRecordContext();
    return (
        <Box sx={{minWidth: "600px"}}>
            <SimpleForm
                record={{...caseRecord, recreate_case_actions_trigger: moment()}}
                maxWidth={"sm"}
                toolbar={
                    <Toolbar>
                        <SaveButton label={"Generate actions"} alwaysEnable/>
                    </Toolbar>
                }
            >
                {/*<BooleanInput source={"recreate_case_actions_trigger"} />*/}
                <Box>
                    <Typography>
                        Recreate all actions for this case. This will delete all existing, automatically created,
                        actions and recreate them based on the current case data and country rules.
                    </Typography>
                </Box>
                <br/>
                <Box>
                    <Typography>
                        The generation happens in the background and should be done in a few seconds after you click
                        "Generate actions". You can update the page manually by clicking the refresh button in the
                        browser or in the menu. The data (and new actions) will also be updated if you return to the
                        browser window after having used another application.
                    </Typography>
                </Box>
            </SimpleForm>
        </Box>
    );
}

export const CaseStatusField = (props: any) => {
    const caseRecord = useRecordContext();
    if (!caseRecord) return null;

    return caseRecord.status === CASE_STATUS_GRANTED ?
        <Chip label={ `${caseRecord.status} ${moment(caseRecord.registration_date).format(dateFormat)}` }></Chip> :
        caseRecord.status === CASE_STATUS_CLOSED ?
            <Chip label={ `${caseRecord.status} ${moment(caseRecord.registration_date).format(dateFormat)}` }></Chip> :
            <Chip label={caseRecord.status}></Chip>
}

export const FilingDateField = (props: any) => {
    const caseRecord = useRecordContext();
    if (!caseRecord) return null;

    return caseRecord[CASE_FIELDS.INTERNATIONAL_FILING_DATE] ?
        <Chip label={ `Int. filing date ${moment(caseRecord[CASE_FIELDS.INTERNATIONAL_FILING_DATE]).format(dateFormat)}` }></Chip> :
            <Chip label={ `Filing date ${moment(caseRecord[CASE_FIELDS.FILING_DATE]).format(dateFormat)}` }></Chip>
}
