import {Button, Link, useDataProvider, useRecordContext} from "react-admin";
import {countries as countryList, epStates, INTERNATIONAL_TRADEMARK_APPLICATION, pctStates} from "../utils/countries";
import PlusIcon from '@mui/icons-material/Add';
import {
    Button as MUIButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Backdrop,
    CircularProgress,
    Autocomplete,
    TextField as MUITextField
} from '@mui/material';
import React, {FunctionComponent, useState} from "react";
import {CASE_TYPE_TRADEMARK, PCTBased, ValidatedEPC} from "./CaseList";

enum CopyType {
    EP, PCT, TRADEMARK
}

export const CopyCaseButton: FunctionComponent<{ label: string }> = ({label}) => {
    const caseRecord = useRecordContext();
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);
    const close = () => setOpen(false);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [formInput, setFormInput] = useState({
        countryCodes: [] as string[],
    });
    if (!caseRecord) {
        // TODO: Move dialog logic to separate component and only excecute the logic when we know the caseRecord is fetched
        // It now fails when going directly to the link to the case show page (via Annuities or browser link)
        return null;
    }
    const caseRef = caseRecord.case_ref || "";
    const copyType = caseRecord.case_type === CASE_TYPE_TRADEMARK ? CopyType.TRADEMARK : caseRef.includes("EP") ? CopyType.EP : CopyType.PCT;

    const countries = copyType === CopyType.TRADEMARK ? countryList : copyType === CopyType.EP ? epStates : pctStates;
    const availableCountries = Object.keys(countries)
        .filter(countryCode => (caseRecord.designated_states || "").split(" ").includes(countryCode.toUpperCase()))
        .sort((a, b) => a.localeCompare(b));


    const startCopying = () => {
        console.log(formInput.countryCodes);
        const objects = formInput.countryCodes.map((countryCode) => {
            const {
                applicant,
                created_at,
                updated_at,
                id,
                designated_states,
                filing_date,
                next_annuity,
                next_maintenance_case_action,
                ...caseRest
            } = caseRecord;

            return copyType === CopyType.TRADEMARK ?
                {
                    ...caseRest,
                    country_code: countryCode,
                    case_ref: caseRef.split("IM")[0] + "IM" + countryCode === "eu" ? INTERNATIONAL_TRADEMARK_APPLICATION : countryCode.toUpperCase()
                } : copyType === CopyType.EP ? {
                    ...caseRest,
                    country_code: countryCode,
                    application_type_2: ValidatedEPC,
                    filing_date,
                    case_ref: caseRef.split("EP")[0] + countryCode.toUpperCase() + "EP" + caseRef.split("EP")[1]
                } : {
                    ...caseRest,
                    application_type: PCTBased,
                    based_on_pct: caseRecord.application_number,
                    application_number: undefined,
                    country_code: countryCode,
                    international_filing_date: filing_date,
                    case_ref: caseRef.split("PC")[0] + countryCode.toUpperCase() + "PC" + caseRef.split("PC")[1]
                }
        });
        console.log(objects);

        setIsLoading(true)
        dataProvider.getList("case_roles", {
            pagination: {page: 1, perPage: 10},
            sort: {field: "id", order: "ASC"},
            filter: {case_id: caseRecord.id}
        }).then(rolesResponse => {
            const roles = rolesResponse.data;
            const rolesToCopy = roles.map(r => ({name_id: r.name_id, role: r.role}));

            Promise.all(objects.map((object) => dataProvider.create("cases", {data: {...object, case_roles: {data: rolesToCopy}}})))
                .then(result => {
                    console.log("Roles result", result);
                    setIsLoading(false);
                    setSuccessMessage(`${result.length} cases successfully copied`);
                })
                .catch(error => {
                    console.log(error);
                    setIsLoading(false);
                });
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    }

    return (
        <>
            <Button
                label={label}
                onClick={() => setOpen(!open)}
            >
                <PlusIcon/>
            </Button>
            <Dialog open={open} onClose={close} maxWidth={"md"}>
                <DialogTitle>{`Copy case to other countries`}</DialogTitle>
                <DialogContent sx={{width: 600}}>
                    {
                        successMessage ?
                            <DialogContentText>
                                {successMessage}
                            </DialogContentText>
                            :
                            <>

                                <DialogContentText>
                                    This will copy the case to the countries you select below. Only designated countries
                                    will be available for selection.
                                    <br></br>
                                    <br></br>
                                    {
                                        copyType === CopyType.EP ?
                                            "Application type 2 will be set to \"Validated EPC\"." :
                                            `Application type will be set to "PCT Based". "Based on pct" will be set to this case's application number (${caseRecord.application_number})`
                                    }

                                    <br></br>
                                    <br></br>
                                    Case ref on the format P30100EP00 will be transformed to P30100XXEP00 where "XX" is
                                    replaced with the specific country code. Replace "EP" with "PC" for PCT.
                                </DialogContentText>
                                <Backdrop
                                    sx={{color: '#fff', zIndex: (theme: any) => theme.zIndex.drawer + 1}}
                                    open={isLoading}
                                    // onClick={handleClose}
                                >
                                    <CircularProgress color="inherit"/>
                                </Backdrop>
                                <br/>
                                <div style={{display: "flex", flex: 1, justifyContent: "right"}}>
                                    <Button label={"Select all"}
                                            onClick={() => setFormInput({...formInput, countryCodes: availableCountries})}></Button>
                                </div>
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={availableCountries}
                                    getOptionLabel={(option) => countries[option]}
                                    // defaultValue={["se"]}
                                    filterSelectedOptions
                                    value={formInput.countryCodes}
                                    onChange={(e, value) => setFormInput({...formInput, countryCodes: value})}
                                    renderInput={(params) => (
                                        <MUITextField
                                            {...params}
                                            label="Select countries to copy to"
                                            // placeholder="Countries"
                                        />
                                    )}
                                />

                            </>
                    }
                </DialogContent>
                <DialogActions>
                    <MUIButton onClick={close}>Cancel</MUIButton>
                    {!successMessage &&
                        <MUIButton onClick={startCopying} disabled={formInput.countryCodes.length === 0}>Start
                            copying</MUIButton>}
                </DialogActions>
            </Dialog>
        </>
    );
};