import {AutocompleteInput, ReferenceInput } from "react-admin";



export const OrganisationInput = (props: any) => {
    return (
        <ReferenceInput reference="tenants" source="tenant_id" label="Organisation" {...props}>
            <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Organisation"} />
        </ReferenceInput>
    );
}