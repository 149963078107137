import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppAuth0Provider from "./auth/AppAuth0Provider";
import { BrowserRouter } from "react-router-dom";
import EnsureAuth from "./auth/EnsureAuth";
import LogRocket from 'logrocket';

if (process.env.NODE_ENV === "production") {
    LogRocket.init('2utk0b/ip-management-system');
} else {
    // LogRocket.init('2utk0b/ip-management-system-local');
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppAuth0Provider>
                <EnsureAuth>
                    <App/>
                </EnsureAuth>
            </AppAuth0Provider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
