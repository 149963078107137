import {CreateInDialogButton} from "@react-admin/ra-form-layout";
import moment from "moment";
import {
    DateInput,
    NumberInput,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useDataProvider,
    useListContext,
    useRecordContext,
    useRefresh
} from "react-admin";
import {useUserId} from "../../auth/utils";
import {currencyOptions} from "../../tenants/TenantCreate";
import {Alert, AlertTitle, Box, Typography} from "@mui/material";
import {useHasuraRequest} from "../../utils/useHasuraRequest";
import {Spinner} from "../../utils/Spinner";
// @ts-ignore
import {v4 as uuidv4} from "uuid";
import {useEffect, useState} from "react";
import {countries} from "../../utils/countries";
import {ButtonDialog} from "../../utils/ButtonDialog";

const createInvoiceMutation = `
             mutation createInvoice($invoice: invoices_insert_input!, $instructions_received_id: uuid!, $invoice_id: uuid!) {
                 insert_invoices_one(
                     object: $invoice,
                 ) {
                     id
                 }
                 update_instructions_received_by_pk(
                     pk_columns: {id: $instructions_received_id},
                     _set: {invoice_id: $invoice_id}
                 ) {
                     id
                 }
             }
        `;

const appendInfoValueIfPresent = (value?: string) => value ? ` | ${value}` : "";
const invoiceItems = (instructionRecord: any) => {
    const caseData = instructionRecord?.case_action?.case;
    const annuityRenewal = caseData?.case_ref?.startsWith("P") ? "annuity" : "renewal";
    const itemName = instructionRecord?.case_action?.action_rule?.action_name;
    const country = countries[caseData?.country_code];
    const incVatRate = 1 + (instructionRecord.vat || 0);
    return [{
        title: `Payment of ${annuityRenewal} fee (including official & service fees)`,
        description: `${itemName || ""}${appendInfoValueIfPresent(caseData?.tenant_case_ref)}${appendInfoValueIfPresent(country)}${appendInfoValueIfPresent(caseData?.application_number)}`,
        quantity: 1,
        rate: instructionRecord?.price,
        vat: instructionRecord.vat || 0,
        total: parseFloat((instructionRecord?.price * incVatRate)?.toFixed(2)),
        instructions_received_id: instructionRecord?.id,
    }]
}

const generateInvoiceData = (instructionRecord: any, userId: any, tenant: any) => {
    const invoice_items = invoiceItems(instructionRecord);
    const vat_total = 0;
    // const vat_total = invoice_items.map((item) => )
    const invoice = {
        id: uuidv4(),
        created_by: userId,
        date: moment(),
        due_date: moment().add(30, 'days'),
        currency: instructionRecord?.price_currency || undefined,
        tenant_id: tenant?.id,
        case_id: instructionRecord?.case_action?.case?.id,
        total: instructionRecord?.price,
        vat_total: vat_total,
        our_reference: instructionRecord?.case_action?.case?.case_ref,
        your_reference: instructionRecord?.case_action?.case?.tenant_case_ref,
        invoice_items: {
            data: invoice_items
        }
    };

    return invoice;
}

export const CreateInvoice = () => {
    const userId = useUserId();
    const now = moment();
    const record = useRecordContext();
    const { loading, data, error, makeApiCall } = useHasuraRequest();
    const { refetch } = useListContext();
    const refresh = useRefresh();

    useEffect(() => {
        if (data) {
            // refetch();
            // refresh();
        }
    }, [data]);

    console.log(record);
    const tenant = record?.case_action?.case?.tenant;
    const defaultValues = generateInvoiceData(record, userId, tenant);

    const onSubmit = async (values: any) => {
        makeApiCall({
           query: createInvoiceMutation,
            variables: {
               invoice: defaultValues,
                instructions_received_id: record?.id,
                invoice_id: defaultValues.id,
           }
        });
    }

    const onClose = () => refresh();

    if (record?.price_currency !== "USD") return null;

    return (
        <CreateInDialogButton
            label={"Create Invoice"}
            close={onClose}
            onClose={onClose}
            resource={"invoices"} icon={<></>} fullWidth maxWidth={"lg"}>
            {loading && <Spinner></Spinner>}
            {error &&
                <Alert severity="error" sx={{marginBottom: 2}}>
                    <AlertTitle>Error</AlertTitle>
                    <Typography>Something went wrong when creating the invoice.</Typography>
                </Alert>
            }
            <Box sx={{minWidth: "600px"}}>
                {
                    data ?
                        <Alert severity="success" sx={{marginBottom: 2}}>
                            <AlertTitle>Success</AlertTitle>
                            <Typography>All good! You will receive a confirmation by email when we have verified that everything is okay.</Typography>
                        </Alert>
                        :
                            <SimpleForm
                                onSubmit={onSubmit}
                                toolbar={
                                    <Toolbar>
                                        <SaveButton label={"Save"} alwaysEnable />
                                    </Toolbar>
                                }
                                defaultValues={defaultValues}>
                                <DateInput source={"date"} label={"Invoice date"} validate={required()}></DateInput>
                                <DateInput source={"due_date"} label={"Due date"} validate={required()}></DateInput>
                                <TextInput source={"our_reference"} label={"Our ref."}></TextInput>
                                <TextInput source={"your_reference"} label={"Your ref."}></TextInput>
                                <SelectInput source={"currency"} choices={currencyOptions} validate={required()} disabled/>
                                <NumberInput source={"total"} validate={required()} disabled/>
                                {/*<ReferenceManyInput defaultValue={invoice_items} source={"invoice_items"} reference="invoice_items" target="invoice_id" sort={{ field: "created_at", order: "DESC" }}>*/}
                                {/*    <SimpleFormIterator inline>*/}
                                {/*        <TextInput source="title" validate={required()} />*/}
                                {/*        <TextInput source="description" validate={required()} />*/}
                                {/*        <NumberInput source="quantity" validate={required()} />*/}
                                {/*        <NumberInput source="rate" validate={required()} />*/}
                                {/*        <NumberInput source="total" validate={required()} />*/}
                                {/*    </SimpleFormIterator>*/}
                                {/*</ReferenceManyInput>*/}
                            </SimpleForm>
                }
            </Box>

        </CreateInDialogButton>
    );
}

export const BulkCreateInvoicesButton = () => {
    const [isSuccess, setIsSuccess] = useState(false);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const userId = useUserId();
    const {selectedIds, data, onUnselectItems} = useListContext();
    const selected = (data || [])
        .filter((d: any) => selectedIds.includes(d.id));

    const toBeCreated = selected
        .filter((d: any) => d.price_currency === "USD");

    const toBeCreatedSkipped = selected
        .filter((d: any) => d.price_currency !== "USD");

    const onSubmit = async (values: any) => {
        const result = await Promise.all(toBeCreated.map((instructionRecord: any) => {
            const tenant = instructionRecord?.case_action?.case?.tenant;
            const invoice = generateInvoiceData(instructionRecord, userId, tenant);

            return dataProvider.hasuraRequest({
                query: createInvoiceMutation,
                variables: {
                    invoice,
                    instructions_received_id: instructionRecord?.id,
                    invoice_id: invoice.id,
                }
            });
        }));
        console.log(result);
        refresh();
        setIsSuccess(true);
        onUnselectItems();
    }

    const count = toBeCreated.length;
    const skippedCount = toBeCreatedSkipped.length;

    return (
        <ButtonDialog buttonTitle={`Bulk create ${count} invoices`} dialogTitle={`Bulk create ${count} invoices`} >
            <SimpleForm
                onSubmit={onSubmit}
                toolbar={
                    <Toolbar>
                        <SaveButton label={`Create ${count} invoices`} alwaysEnable />
                    </Toolbar>
                }
                sx={{maxWidth: 400}}>
                {
                    skippedCount ?
                    <Alert  variant="outlined" severity="warning" sx={{marginBottom: 2}}>
                        <AlertTitle>Skipped some of the selected</AlertTitle>
                        <Box>Since we only support USD invoicing at the moment, {skippedCount} of the selected orders will be skipped in the invoice creation process.</Box>
                        <Box mt={2}>These will be skipped:</Box>
                        <Box>
                            {
                                toBeCreatedSkipped.map((instruction: any) => (
                                    <Box>
                                        {instruction?.case_action?.case?.tenant?.name} | {instruction?.case_action?.case?.case_ref} | {instruction?.price} {instruction?.price_currency}
                                    </Box>
                                ))
                            }
                        </Box>
                    </Alert>
                        : null
                }
                {
                    isSuccess ?
                        <Alert  variant="outlined" severity="success" sx={{marginBottom: 2}}>
                            <AlertTitle>Invoices created successfully</AlertTitle>
                            <Box>Go to the "Send Invoice" tab to email the invoices to the client</Box>
                        </Alert> : null
                }
            </SimpleForm>
        </ButtonDialog>
        // <BulkUpdateFormButton resource={"invoices"} label={"Create invoices"} icon={<></>}>
        //     <SimpleForm
        //         onSubmit={onSubmit}
        //         toolbar={
        //             <Toolbar>
        //                 <SaveButton label={"Create invoices"} alwaysEnable />
        //             </Toolbar>
        //         }
        //         sx={{maxWidth: 400}}>
        //
        //     </SimpleForm>
        // </BulkUpdateFormButton>
    );
}

