import { Box } from "@mui/system";
import moment from "moment";
import { useRecordContext } from "react-admin";
import {dateFormat} from "../cases/actions/Actions";
import {ShowEmailInDialogButton} from "../cases/instructions/InstructionsReceived";


export const OrderLineStatus = (props: any) => {
    const record = useRecordContext();
    if (!record) return null;


    const pct_or_ep_order = record?.ep_order || record?.pct_order;
    return (
        <Box>
            {pct_or_ep_order?.order?.confirmed_at &&
                <Box>
                    <Box sx={{textWrap: "nowrap" }}>Confirmed: {moment(pct_or_ep_order?.order?.confirmed_at).format(dateFormat)}</Box>
                    {
                        pct_or_ep_order?.order?.confirmation_email_id &&
                        <Box sx={{textWrap: "nowrap" }}><ShowEmailInDialogButton id={pct_or_ep_order?.order?.confirmation_email_id}></ShowEmailInDialogButton></Box>
                    }
                </Box>
            }
            {record?.instructions_to_agent_sent_at &&
                <Box>
                    <Box sx={{textWrap: "nowrap" }}>Sent to Agent: {moment(record?.instructions_to_agent_sent_at).format(dateFormat)}</Box>
                    {
                        record?.instructions_to_agent_email_id &&
                        <Box sx={{textWrap: "nowrap" }}><ShowEmailInDialogButton id={record?.instructions_to_agent_email_id}></ShowEmailInDialogButton></Box>
                    }
                </Box>
            }

            {record?.agent_confirmation_received_at && <Box sx={{textWrap: "nowrap" }}>Agent confirmed: {moment(record?.agent_confirmation_received_at).format(dateFormat)}</Box>}
        </Box>
    );
}

