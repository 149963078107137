import {useIsAdmin} from "../../auth/utils";
import {Page} from "../../utils/Page";
import {alternateBackgroundColor, CASE_FIELDS} from "../../cases/CaseList";
import {CaseRefField} from "../../cases/crud/CaseRefField";
import {countries} from "../../utils/countries";
import {EditTaskButton} from "../../tasks/EditTaskButton";
import {CompleteSubActionButton} from "../../cases/actions/CompleteSubActionButton";
import {TaskContextList} from "../../tasks/TaskList";
import {AutocompleteInput, Datagrid, DateField, FunctionField, List, NumberInput,
    ReferenceField, ReferenceInput, required, SelectInput, TextField, TextInput, useRecordContext } from "react-admin";
import {FlagField} from "../../utils/FlagField";
import {EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";


export const EPValidationPricesList = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Page title={"EP Validation Prices"}>
            <List {...props}
                  sort={{ field: "country_code", order: "ASC" }}
                  exporter={false}
                  perPage={1000}
                  pagination={false}
            >
                <EditableDatagrid
                    bulkActionButtons={false}
                    rowSx={alternateBackgroundColor}
                    editForm={<EPPriceRowForm></EPPriceRowForm>}
                    rowClick={"edit"}
                    mutationMode="pessimistic"
                    noDelete={true}
                >
                    {/*<TextField source={"country_code"} />*/}
                    <FlagField label={"Country"} source={"country_code"}></FlagField>
                    {/*<DateField source={"created_at"} showTime/>*/}
                    <DateField source={"updated_at"} showTime/>
                    <TextField source={"our_fee"} />
                    <TextField source={"local_agent_fee"} />
                    <TextField source={"official_fee"} />
                    <TextField source={"official_fee_currency"} />
                    <TextField source={"official_fee_eur"} />
                    <TextField source={"translation_requirements"} />
                    <TextField source={"translation_fee_per_word"} />
                    <TextField source={"power_of_attorney_type"} label={"Power of attorney"} />
                    <ReferenceField reference="names" source={"default_agent_id"} label={"Default Agent"}>
                        <TextField source={"name"}/>
                    </ReferenceField>
                </EditableDatagrid>
            </List>
        </Page>
    )
};

const translationRequirements = ["No translation", "Claims", "Application and Claims"]
const translationRequirementChoices = translationRequirements.map((choice: any) => ({ id: choice, name: choice}));

const poaTypes = ["No POA", "Electronic POA", "Original POA"];
const poaTypeChoices = poaTypes.map((choice: any) => ({ id: choice, name: choice}))
const EPPriceRowForm = () => (
    <RowForm>
        <FlagField label={"Country"} source={"country_code"}></FlagField>
        <DateField source={"updated_at"} showTime/>

        <NumberInput source={"our_fee"} validate={required()} fullWidth />
        <NumberInput source={"local_agent_fee"} validate={required()} fullWidth />
        <NumberInput source={"official_fee"} validate={required()} fullWidth />
        <TextInput source={"official_fee_currency"}  validate={required()} fullWidth defaultValue={"EUR"}/>
        <SelectInput source={"translation_requirements"} choices={translationRequirementChoices} validate={required()} fullWidth ></SelectInput>
        <NumberInput source={"translation_fee_per_word"} validate={required()} fullWidth />
        <SelectInput source={"power_of_attorney_type"} choices={poaTypeChoices} validate={required()} fullWidth ></SelectInput>

        <DefaultAgentInput source="default_agent_id" label="Default Agent"/>
    </RowForm>
);

const DefaultAgentInput = (props: any) => {
    const record = useRecordContext();
    console.log(record);
    return (
        <ReferenceInput reference="names" filter={{ type: "Agent", "agent_handles_patent_country_codes@_contains": [record?.country_code] }} {...props}>
            <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} sx={{ width: 200 }}/>
        </ReferenceInput>
    );
}

