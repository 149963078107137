import * as React from "react";
import {
    AutocompleteInput,
    BulkDeleteButton,
    Button,
    CreateButton,
    Datagrid,
    DateField,
    EditButton,
    ExportButton,
    Filter,
    FilterButton,
    FunctionField,
    ImageField,
    Link,
    List,
    Pagination,
    ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    ReferenceOneField,
    SelectInput,
    ShowButton,
    SimpleForm,
    TextField,
    TextInput,
    TopToolbar,
    useRecordContext,
    useResourceContext,
    useUnselectAll
} from 'react-admin';
import {BulkUpdateFormButton} from '@react-admin/ra-form-layout';
import WorkIcon from '@mui/icons-material/Work';
import PublishIcon from '@mui/icons-material/Publish';
import {CaseActionsList} from "./crud/CaseActionsList";
import {countries, countriesOptions} from "../utils/countries";
import {CopyCaseButton} from "./CopyCaseButton";
import {useNavigate} from "react-router";
import {useIsAdmin} from "../auth/utils";
import {ApplicantNameInput} from "./crud/ApplicantNameInput";
import {CreateActionsButton} from "./actions/CreateActionsButton";
import {Page} from "../utils/Page";
import {TenantField} from "../utils/tenant";
import { CountryInput } from "../utils/CountryInput";
import {alternateBackgroundColor, CASE_FIELDS, compactTable} from "./CaseList";
import {dateFormat} from "./actions/Actions";
import moment from "moment";
import {DepartmentInput, ProductInput} from "./crud/CaseInputFields";
import {ANNUITY, RENEWAL} from "./actions/utils";
import {DesignRenewalsList} from "./design/DesignRenewals";
import { useDefineAppLocation } from "@react-admin/ra-navigation";

const TrademarkFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Smart search" source="case_ref,tenant_case_ref,application_number,catchword,registration_number,trademark_text_in_the_mark,applicant" alwaysOn></TextInput>
        <CountryInput label={"Country"} source={"country_code"} />
        <TextInput source="case_ref"></TextInput>
        <TextInput source="application_number"></TextInput>
        <TextInput source="catchword"></TextInput>
        <TextInput source="registration_number"></TextInput>
        <TextInput source="applicant"></TextInput>
        <ProductInput source={"product"}></ProductInput>
        <DepartmentInput source={"department"}></DepartmentInput>
        <ReferenceInput reference="tenants" source="tenant_id" label="Organisation">
            <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Organisation"} />
        </ReferenceInput>
    </Filter>
);

const TrademarkActions = () => {
    const isAdmin = useIsAdmin();
    const commonFilters = [
        <CountryInput label={"Country"} source={"country_code"} />,
        <TextInput source="case_ref"></TextInput>,
        <TextInput source="application_number"></TextInput>,
        <TextInput source="registration_number"></TextInput>,
        <TextInput source="applicant"></TextInput>,
        <ProductInput source={"product"}></ProductInput>,
    ];

    const filters = isAdmin ? [
        ...commonFilters,
        <ReferenceInput reference="tenants" source="tenant_id" label="Organisation">
            <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label={"Organisation"} />
        </ReferenceInput>,
    ] : commonFilters;
    return (
        <TopToolbar>
            <FilterButton filters={filters}/>
            {isAdmin && <CreateButton/>}
            {isAdmin && <ExportButton/>}
        </TopToolbar>
    );
};

const TrademarkBulkUpdateButton = () => (
    <BulkUpdateFormButton>
        <SimpleForm sx={{maxWidth: 400}}>
            <ApplicantNameInput/>
            <ReferenceInput source="tenant_id" reference="tenants">
                <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Organisation"
                                   fullWidth/>
            </ReferenceInput>
        </SimpleForm>
    </BulkUpdateFormButton>
);

const bulkActionButtons: React.ReactElement = <>
    <TrademarkBulkUpdateButton/>
    <BulkDeleteButton mutationMode="pessimistic"/>
</>;

export const TrademarkList = (props: any) => {
    const isAdmin = useIsAdmin();
    const resource = useResourceContext();
    const unselectAll = useUnselectAll(resource);

    React.useEffect(() => {
        unselectAll()
    }, []);

    const config = {
        bulkActions: isAdmin ? bulkActionButtons : false as false,
        rowClick: isAdmin ? "expand" : undefined,
        expand: isAdmin ?
            <div>
                <h3>Actions</h3>
                <ReferenceManyField label="Actions" reference="case_actions" target="case_id"
                                    sort={{field: "due_date", order: "ASC"}} filter={{ "action_rule#action_type@_neq": RENEWAL }}>
                <CaseActionsList />
                </ReferenceManyField>
                <h3>Renewals</h3>
                <ReferenceManyField label="Actions" reference="case_actions" target="case_id"
                                    sort={{field: "due_date", order: "ASC"}} filter={{ "action_rule#action_type@_eq": RENEWAL }}>
                    <DesignRenewalsList />
                </ReferenceManyField>
            </div> : undefined
    }

    return (
        <Page title={"Trademarks"} body={<>
            The table below shows the trademark cases that have been imported to the portal. You can use the filters (available at the right, above the table) to narrow your list of cases.
            Further details on the case can be found via the "view details" button.
        </>}>
            <List {...props}
                  resource={resource || "trademarks"}
                  sort={{field: "case_ref", order: "ASC"}}
                  filter={{ case_type: "TM" }}
                  filters={<TrademarkFilter/>}
                  actions={<TrademarkActions/>}
                  perPage={100}
                  pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
                  storeKey={"trademarks"}
            >
                <Datagrid bulkActionButtons={config.bulkActions} rowClick={config.rowClick} rowSx={alternateBackgroundColor} sx={compactTable}
                          expand={config.expand}>
                    {/*<TextField source="id" />*/}
                    {isAdmin && <TenantField label={"Organisation"}/>}

                    <TextField source={CASE_FIELDS.CASE_REF}/>
                    <ImageField source={"trademark_logo.src"} title={"trademark_logo.title"} label={"Logo"} />
                    {/*<TextField source={CASE_FIELDS.CASE_TYPE}/>*/}
                    <TextField source={CASE_FIELDS.APPLICANT}/>
                    <FunctionField
                        label="Country"
                        render={(record: any) => countries[record[CASE_FIELDS.COUNTRY_CODE]]}
                    />
                    <TextField source={CASE_FIELDS.APPLICATION_NUMBER}/>
                    <TextField source={CASE_FIELDS.APPLICATION_TYPE}/>
                    <TextField source={"trademark_text_in_the_mark"}/>
                    <DateField source={CASE_FIELDS.FILING_DATE}/>
                    <DateField source={CASE_FIELDS.REGISTRATION_DATE}/>
                    <TextField source={CASE_FIELDS.REGISTRATION_NUMBER}/>
                    <TextField source={CASE_FIELDS.STATUS}/>

                    <DateField source={CASE_FIELDS.FIRST_PRIORITY_FILING_DATE}/>
                    <TextField source={CASE_FIELDS.PRIORITY_APPLICATION_NUMBER}/>
                    <FunctionField
                        label="Priority Country"
                        render={(record: any) => record[CASE_FIELDS.PRIORITY_COUNTRY_CODE]?.toUpperCase()}
                    />
                    <ReferenceOneField
                        label="Next renewal"
                        reference="case_actions"
                        target={"case_id"}
                        sort={{ field: "due_date", order: "ASC" }}
                        filter={{ "completed_at@_is_null": true, "action_rule#action_type": RENEWAL, "due_date@_gte": moment().format(dateFormat) }}
                    >
                        <DateField source="due_date" />
                    </ReferenceOneField>
                    {isAdmin && <EditButton/>}
                    <ShowButton label={"Details"}/>
                    <ActionsButton/>
                    {isAdmin && <CopyCaseButton label={"Copy"}/>}
                </Datagrid>
            </List>
        </Page>
    );
};

const ActionsButton = (props: any) => {
    const record = useRecordContext();
    const resource = useResourceContext();

    if (!record) return null;
    return (
        <Button
            {...props}
            label={"Actions"}
            component={Link}
            to={`/${resource}/${record.id}/show/actions`}
        >
        </Button>
    );
}

