import {useIsAdmin} from "../../auth/utils";
import {Page} from "../../utils/Page";
import {alternateBackgroundColor, CASE_FIELDS} from "../../cases/CaseList";
import {CaseRefField} from "../../cases/crud/CaseRefField";
import {countries} from "../../utils/countries";
import {EditTaskButton} from "../../tasks/EditTaskButton";
import {CompleteSubActionButton} from "../../cases/actions/CompleteSubActionButton";
import {TaskContextList} from "../../tasks/TaskList";
import {AutocompleteInput, CloneButton, Create, CreateButton, Datagrid, DateField, FunctionField, List, NumberInput,
    ReferenceField, ReferenceInput, required, SelectInput, SimpleForm, TextField, TextInput, useRecordContext } from "react-admin";
import {FlagField} from "../../utils/FlagField";
import {EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";
import {CreateActionsButton} from "../../cases/actions/CreateActionsButton";
import {CountryInput} from "../../utils/CountryInput";


export const PCTPricesList = (props: any) => {
    const isAdmin = useIsAdmin();
    return (
        <Page title={"PCT Prices"}>
            <List {...props}
                  sort={{ field: "country_code", order: "ASC" }}
                  exporter={false}
                  perPage={1000}
                  pagination={false}
            >
                <EditableDatagrid
                    bulkActionButtons={false}
                    rowSx={alternateBackgroundColor}
                    editForm={<EPPriceRowForm/>}
                    rowClick={"edit"}
                    mutationMode="pessimistic"
                    // noDelete={true}
                >
                    {/*<TextField source={"country_code"} />*/}
                    <FlagField label={"Country"} source={"country_code"}></FlagField>
                    {/*<DateField source={"created_at"} showTime/>*/}
                    <DateField source={"updated_at"} showTime/>
                    <TextField source={"our_fee"} />
                    <TextField source={"local_agent_fee"} />

                    <TextField source={"basic_filing_fee"} />
                    <TextField source={"basic_filing_fee_currency"} label={"Official fee currency"}/>

                    <TextField source={"basic_fee_num_included_claims"} />
                    <TextField source={"basic_fee_per_extra_claim"} />

                    <TextField source={"basic_fee_num_included_ind_claims"} />
                    <TextField source={"basic_fee_per_extra_ind_claim"} />

                    <TextField source={"basic_fee_num_included_pages"} />
                    <TextField source={"basic_fee_per_extra_page"} />
                    <TextField source={"examination_fee"} />

                    <TextField source={"translation_requirements"} />
                    <TextField source={"translation_fee_per_word"} />
                    <TextField source={"power_of_attorney_type"} label={"Power of attorney"} />
                    <TextField source={"power_of_attorney_notes"} label={"PoA Notes"} />

                    <TextField source={"official_fee_currency_factor"} label={"Currency factor"} />

                    <TextField source={"applicant_employees"} label={"Entity size"} />

                    <ReferenceField reference="names" source={"default_agent_id"} label={"Default Agent"}>
                        <TextField source={"name"}/>
                    </ReferenceField>
                    <ClonePriceButton />
                </EditableDatagrid>
            </List>
        </Page>
    )
};

const ClonePriceButton = () => {
    const record = useRecordContext();
    if (!record) return null;
    const { country_code, official_fee_currency_factor, updated_at, id, ...restRecord } = record;
    return (
        <CreateButton
            resource="pct_country_prices"
            label={"Copy"}
            // @ts-ignore
            state={{ record: restRecord }}
        />
    );
};

const translationRequirements = ["No translation", "Claims", "Whole application"]
const translationRequirementChoices = translationRequirements.map((choice: any) => ({ id: choice, name: choice}));

const poaTypes = ["No POA", "Electronic POA", "Original POA", "Electronic Assignment", "Original Assignment", "Original Declaration", "Electronic Declaration"];
const poaTypeChoices = poaTypes.map((choice: any) => ({ id: choice, name: choice}))
const EPPriceRowForm = () => (
    <RowForm>
        <FlagField label={"Country"} source={"country_code"}></FlagField>
        <DateField source={"updated_at"} showTime/>

        <NumberInput source={"our_fee"} validate={required()} fullWidth />
        <NumberInput source={"local_agent_fee"} validate={required()} fullWidth />

        <NumberInput source={"basic_filing_fee"} validate={required()} fullWidth />
        <TextInput source={"basic_filing_fee_currency"}  validate={required()} fullWidth defaultValue={"EUR"} label={"Official fee currency"}/>

        <NumberInput source={"basic_fee_num_included_claims"} validate={required()} fullWidth />
        <NumberInput source={"basic_fee_per_extra_claim"} validate={required()} fullWidth />

        <NumberInput source={"basic_fee_num_included_ind_claims"} validate={required()} fullWidth />
        <NumberInput source={"basic_fee_per_extra_ind_claim"} validate={required()} fullWidth />

        <NumberInput source={"basic_fee_num_included_pages"} validate={required()} fullWidth />
        <NumberInput source={"basic_fee_per_extra_page"} validate={required()} fullWidth />

        <NumberInput source={"examination_fee"} validate={required()} fullWidth />

        <SelectInput source={"translation_requirements"} choices={translationRequirementChoices} validate={required()} fullWidth ></SelectInput>
        <NumberInput source={"translation_fee_per_word"} validate={required()} fullWidth />

        <SelectInput source={"power_of_attorney_type"} choices={poaTypeChoices} validate={required()} fullWidth ></SelectInput>
        <TextInput source={"power_of_attorney_notes"} fullWidth sx={{ width: 200 }}></TextInput>

        <TextField source={"official_fee_currency_factor"} label={"Currency factor"} />

        <TextInput source={"applicant_employees"} fullWidth></TextInput>

        <DefaultAgentInput source="default_agent_id" label="Default Agent" />
    </RowForm>
);

export const PCTPricesCreate = (props: any) => (
    <Create title="Create PCT Price" {...props}>
        <SimpleForm sx={{ maxWidth: 300 }}>
            <CountryInput label={"Country"} source={"country_code"} defaultValue={null} ></CountryInput>

            <NumberInput source={"our_fee"} validate={required()} fullWidth />
            <NumberInput source={"local_agent_fee"} validate={required()} fullWidth />

            <NumberInput source={"basic_filing_fee"} validate={required()} fullWidth />
            <TextInput source={"basic_filing_fee_currency"}  validate={required()} fullWidth defaultValue={"EUR"} label={"Official fee currency"}/>

            <NumberInput source={"basic_fee_num_included_claims"} validate={required()} fullWidth />
            <NumberInput source={"basic_fee_per_extra_claim"} validate={required()} fullWidth />

            <NumberInput source={"basic_fee_num_included_ind_claims"} validate={required()} fullWidth />
            <NumberInput source={"basic_fee_per_extra_ind_claim"} validate={required()} fullWidth />

            <NumberInput source={"basic_fee_num_included_pages"} validate={required()} fullWidth />
            <NumberInput source={"basic_fee_per_extra_page"} validate={required()} fullWidth />

            <NumberInput source={"examination_fee"} validate={required()} fullWidth />

            <SelectInput source={"translation_requirements"} choices={translationRequirementChoices} validate={required()} fullWidth ></SelectInput>
            <NumberInput source={"translation_fee_per_word"} validate={required()} fullWidth />

            <SelectInput source={"power_of_attorney_type"} choices={poaTypeChoices} validate={required()} fullWidth ></SelectInput>
            <TextInput source={"power_of_attorney_notes"} fullWidth sx={{ width: 200 }}></TextInput>

            <TextInput source={"applicant_employees"} fullWidth></TextInput>

            <DefaultAgentInput source="default_agent_id" label="Default Agent" />
        </SimpleForm>
    </Create>
);
const DefaultAgentInput = (props: any) => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <ReferenceInput reference="names" filter={{ type: "Agent", "agent_handles_patent_country_codes@_contains": [record.country_code] }} {...props}>
            <AutocompleteInput filterToQuery={(searchText: string) => ({ "name,short_name": `${searchText}` })} sx={{ width: 200 }}/>
        </ReferenceInput>
    );
}

