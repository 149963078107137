import {Flag} from "../../utils/Flag";
import {countries} from "../../utils/countries";
import {FlagField} from "../../utils/FlagField";
import {dateFormat} from "./Annuities";
import { List, Datagrid, TextField, FunctionField, useRecordContext, Filter, DateInput, downloadCSV, TextInput, SelectInput, useGetList, ReferenceInput, Pagination, BooleanField, SimpleForm, DateTimeInput, BooleanInput, Toolbar, SaveButton, ReferenceField, FormDataConsumer, DateField, AutocompleteInput, NullableBooleanInput, WithListContext, SimpleShowLayout, Show, ShowBase, ReferenceOneField, WithRecord, Form } from 'react-admin';
import { AppBar, Chip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import {Alert, AlertTitle, Box, Button, Grid, Stack, Tooltip } from '@mui/material';
import { ShowInDialogButton } from "@react-admin/ra-form-layout";
import moment from "moment";

const countryRuleQuickDescription = (record: any) => {
    console.log(record)
    const days = record.days_until_due_date ? `${record.days_until_due_date} days` : "";
    const months = record.months_until_due_date ? `${record.months_until_due_date} months` : "";
    const years = record.years_until_due_date ? `${record.years_until_due_date} years` : "";

    const time = [days, months, years].filter((t) => t).join(", ");

    const entitySize = record.entity_size ? `(Entity size ${record.entity_size})` : "";
    const endOfMonth = record.end_of_month_due_date ? " (+end of month)" : "";

    return `${time}${endOfMonth} after ${record.trigger_date} ${entitySize}`;
};

export const ShowCountryRuleButton = () => {
    const record = useRecordContext();

    return (
        <ShowInDialogButton
            fullWidth maxWidth="md" resource={"action_country_rules"} id={record?.action_country_rule_id}
            label={"Country rule"}
            title={
                <WithRecord render={(record: any) => (
                    <Box>
                        <Box>{record?.action_rule?.action_name} for <Flag
                            countryCode={record?.country_code}></Flag> {countries[record?.country_code]} ({record?.country_code?.toUpperCase()})</Box>
                        <Box>{countryRuleQuickDescription(record)}</Box>
                    </Box>
                )}/>
            }
        >

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SimpleShowLayout>
                        <h4>Country Rule</h4>
                        <TextField source={"action_rule.action_name"} label={"Action"}/>
                        <TextField source={"override_action_name"}/>
                        <FlagField label={"Country"} source={"country_code"} sx={{textWrap: "nowrap"}}></FlagField>
                        <TextField source={"entity_size"} label={"Entity size"}/>
                        <TextField source={"trigger_date"}/>
                        <TextField source={"days_until_due_date"}/>
                        <TextField source={"months_until_due_date"}/>
                        <TextField source={"years_until_due_date"}/>
                        <BooleanField source={"end_of_month_due_date"}/>
                        <TextField source={"official_fee"}/>
                        <TextField source={"official_fee_currency"}/>
                        <TextField source={"agent_fee"}/>
                        <TextField source={"agent_fee_currency"}/>
                        <TextField source={"our_fee"}/>
                        <TextField source={"our_fee_currency"}/>
                        <TextField source={"translation_fee_per_word"}/>
                        <TextField source={"translation_fee_currency"}/>
                    </SimpleShowLayout>
                </Grid>
                <Grid item xs={6}>
                    <ShowBase resource={"cases"} id={record?.case_id}>
                        <SimpleShowLayout>
                            <h4>Case</h4>
                            <TextField source={"case_ref"}/>
                            <TextField source={"entity_size"} label={"Entity size (should take priority if set)"}/>
                            {
                                record?.country_code === "us" &&
                                <ReferenceField reference="tenants" source={"tenant_id"}
                                                label={"Client entity size for USA"} link={false}>
                                    <TextField source={"annuity_usa_entity_size"}/>
                                </ReferenceField>
                            }
                            {
                                record?.country_code === "ca" &&
                                <ReferenceField reference="tenants" source={"tenant_id"}
                                                label={"Client entity size for Canada"} link={false}>
                                    <TextField source={"annuity_canada_entity_size"}/>
                                </ReferenceField>
                            }
                        </SimpleShowLayout>
                    </ShowBase>
                </Grid>
            </Grid>
        </ShowInDialogButton>
    );
};

export const annuityIsPristine = (record: any) => {
    return !record.annuity_payment_paid &&
        !record.payment_instructions_sent_at &&
        !record.instructions_confirmed_by_payment_agent_at &&
        !record.annuity_abandoned_at &&
        !record.annuity_paid_elsewhere_at &&
        !record.instructions_wait_sent_at;

}
export const AnnuityStatus = (props: any) => {
    const record = useRecordContext();
    return record?.annuity_payment_paid ? <AnnuityPaidField/> :
        record?.payment_instructions_sent_at ? <InstructionsSentField/> :
            record?.annuity_paid_elsewhere_at ? <AnnuityPaidElsewhereField/> :
                record?.instructions_confirmed_by_payment_agent_at ? <InstructionsConfirmedField/> :
                    record?.annuity_abandoned_at ? <AnnuityAbandonedField/> :
                        record?.instructions_wait_sent_at ? <InstructionsWaitField/> : null;
}
export const AnnuityPaidField = (props: any) => {
    const annuityRecord = useRecordContext();
    if (!annuityRecord) return null;

    return (
        <ReferenceField source={"completed_by"} reference={"users"} link={false}>
            <FunctionField render={(userRecord: any) => (
                <Tooltip
                    title={`Marked paid by ${userRecord.name || "'the system'"} at ${moment(annuityRecord.completed_at).format("lll")}`}
                    arrow>
                    <span>Paid {moment(annuityRecord.completed_at).format(dateFormat)}</span>
                </Tooltip>
            )}/>
        </ReferenceField>
    );
}
export const AnnuityAbandonedField = (props: any) => {
    const annuityRecord = useRecordContext();
    if (!annuityRecord) return null;

    return (
        <ReferenceField source={"annuity_abandoned_by"} reference={"users"} link={false}>
            <FunctionField render={(userRecord: any) => (
                <Tooltip
                    title={`Marked Abandoned by ${userRecord.name} at ${moment(annuityRecord.annuity_abandoned_at).format("lll")}`}
                    arrow>
                    <span>Abandoned {moment(annuityRecord.annuity_abandoned_at).format(dateFormat)}</span>
                </Tooltip>
            )}/>
        </ReferenceField>
    );
}
export const AnnuityPaidElsewhereField = (props: any) => {
    const annuityRecord = useRecordContext();
    if (!annuityRecord) return null;

    return (
        <ReferenceField source={"annuity_paid_elsewhere_by"} reference={"users"} link={false}>
            <FunctionField render={(userRecord: any) => (
                // <Tooltip title={`Marked 'Paid elsewhere' by ${userRecord.name} at ${moment(annuityRecord.annuity_paid_elsewhere_at).format("lll")}`} arrow>
                //     <span>Paid elsewhere {moment(annuityRecord.annuity_paid_elsewhere_at).format(dateFormat)}</span>
                // </Tooltip>
                <Tooltip
                    title={`Marked 'Do not pay' by ${userRecord.name} at ${moment(annuityRecord.annuity_paid_elsewhere_at).format("lll")}`}
                    arrow>
                    <div>
                        <div>Do not pay {moment(annuityRecord.annuity_paid_elsewhere_at).format(dateFormat)}</div>
                        {
                            annuityRecord.instructions_confirmed_by_payment_agent_at ?
                                <div>Confirmed by Breeze, {moment(annuityRecord.instructions_confirmed_by_payment_agent_at).format(dateFormat)}</div> :
                                <div>Waiting for confirmation</div>
                        }
                    </div>
                </Tooltip>
            )}/>
        </ReferenceField>
    );
}
export const InstructionsWaitField = (props: any) => {
    const annuityRecord = useRecordContext();
    if (!annuityRecord) return null;

    return (
        <ReferenceField source={"instructions_wait_sent_by"} reference={"users"} link={false}>
            <FunctionField render={(userRecord: any) => (
                <Tooltip
                    title={`Marked 'Wait' by ${userRecord.name} at ${moment(annuityRecord.instructions_wait_sent_at).format("lll")}`}
                    arrow>
                    <span>Wait {moment(annuityRecord.instructions_wait_sent_at).format(dateFormat)}</span>
                </Tooltip>
            )}/>
        </ReferenceField>
    );
}
export const InstructionsSentField = (props: any) => {
    const annuityRecord = useRecordContext();
    if (!annuityRecord) return null;

    return (
        <ReferenceField source={"annuity_payment_instructions_sent_by"} reference={"users"} link={false}>
            <FunctionField render={(userRecord: any) => (
                <Tooltip
                    title={
                    <Box>
                        {`Pay instructions sent by ${userRecord.name || "'the system'"} at ${moment(annuityRecord.payment_instructions_sent_at).format("lll")}`}
                    </Box>
                }
                    arrow>
                    <div>
                        <div>Pay {moment(annuityRecord.payment_instructions_sent_at).format(dateFormat)}</div>
                        {
                            annuityRecord.instructions_confirmed_by_payment_agent_at ?
                                <div>Confirmed by Breeze, {moment(annuityRecord.instructions_confirmed_by_payment_agent_at).format(dateFormat)}</div> :
                                <div>Waiting for confirmation</div>
                        }
                    </div>
                </Tooltip>
            )}/>
        </ReferenceField>
    );
}
export const InstructionsConfirmedField = (props: any) => {
    const annuityRecord = useRecordContext();
    if (!annuityRecord) return null;

    return (
        <ReferenceField source={"instructions_confirmed_by_payment_agent_by"} reference={"users"} link={false}>
            <FunctionField render={(userRecord: any) => (
                <Tooltip
                    title={`Instructions confirmed by Breeze (${userRecord.name})"} at ${moment(annuityRecord.instructions_confirmed_by_payment_agent_at).format("lll")}`}
                    arrow>
                    <span>Instructions confirmed {moment(annuityRecord.instructions_confirmed_by_payment_agent_at).format(dateFormat)}</span>
                </Tooltip>
            )}/>
        </ReferenceField>
    );
}
export const CaseButton = () => {
    const annuity = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/cases/${annuity?.case_id}/show`}
        >
            Case details
        </Button>
    );
};