import * as React from "react";
import { BulkDeleteButton, TextField, DateField, WithRecord, Button, Datagrid, ListContextProvider, ReferenceManyField, FunctionField, List, ReferenceField, TextInput, SelectInput, ReferenceInput, AutocompleteInput, SimpleForm, NumberInput, Pagination, BulkExportButton, ReferenceArrayInput, Link, Count, WrapperField, SingleFieldList, ReferenceManyCount, WithListContext, useUnselectAll, NumberField, useRecordContext, ShowGuesser, EditGuesser, Toolbar, SaveButton, DeleteButton, useRefresh, ChipField } from "react-admin";
import {countries} from "../../utils/countries";
import {Page} from "../../utils/Page";
import {TenantField} from "../../utils/tenant";
import {CountryInput} from "../../utils/CountryInput";
import {DepartmentInput, ProductInput} from "../crud/CaseInputFields";
import {toChoices} from "../../common/common-utils";
import {INSTRUCTION_DO_NOT_PAY, INSTRUCTION_PAY, INSTRUCTION_WAIT} from "../annuities/Annuities";
import {Flag} from "../../utils/Flag";
import {Alert, Box, Card, Chip, DialogContent, Stack, Tab } from "@mui/material";
import { EditInDialogButton, ShowInDialogButton } from "@react-admin/ra-form-layout";
import {ConfirmInstructionsButton} from "./ConfirmInstructions";
import {MarkPaidButton} from "./MarkPaid";
import { Tabs } from "@mui/material";
import {instructionsExportToExcel} from "./instructions-exporter";
import moment from "moment";
import { SendConfirmationEmailButton } from "./SendConfirmationEmailButton";
import {MarkInvoiceSent} from "./MarkInvoiceSent";
import {HANDLER_BREEZE, SetBreezeAsHandlerButton} from "./SetBreezeAsHandlerButton";
import {HANDLER_ACUMASS, SendToAcumassEmailButton} from "./SendToAcumassEmailButton";
import {CASE_STATUS_CLOSED} from "../crud/utils";
import {emojis} from "../../utils/utils";
import {ANNUITY, RENEWAL} from "../actions/utils";
import {AnnuityCaseActionsList} from "../crud/CaseActionsList";
import {dateFormat} from "../actions/Actions";
import {norwegianInstructionsExportToExcel} from "./norwegian-instructions-exporter";
import {invoiceExportToExcel} from "./invoice-data-exporter";
import { HappyEmpty } from "../../utils/HappyEmpty";
import {BulkCreateInvoicesButton, CreateInvoice} from "./CreateInvoice";
import {SendInvoiceEmailButton} from "./SendInvoiceEmailButton";
import {FlagField} from "../../utils/FlagField";
import {MarkAbandonButton} from "./MarkAbandon";
import {CaseLink, CaseRefField} from "../crud/CaseRefField";
import {CaseShowInDialog} from "../crud/CaseShow";

const Filter = (props: any) => (
    <Filter {...props}>
        <TextInput source="instruction" alwaysOn></TextInput>
    </Filter>
);


const bulkActionButtons = <>
    {/*<BulkDeleteButton mutationMode="pessimistic" />*/}
    <ConfirmInstructionsButton></ConfirmInstructionsButton>
    <MarkPaidButton></MarkPaidButton>
</>;

const createInvoiceFilter = {
    "instruction@_nin": [INSTRUCTION_WAIT, INSTRUCTION_DO_NOT_PAY],
    "invoiced_at@_is_null": true,
    "invoice_id@_is_null": true,
};

const sendInvoiceFilter = {
    "instruction@_nin": [INSTRUCTION_WAIT, INSTRUCTION_DO_NOT_PAY],
    "invoice_id@_is_null": false,
    "invoiced_at@_is_null": true,
};

const receivedFilter = {
    "instruction@_neq": INSTRUCTION_WAIT,
    "case_action#instructions_confirmed_by_payment_agent_at@_is_null": true,
    "case_action#completed_at@_is_null": true,
};

const confirmedFilter = {
    "instruction@_eq": INSTRUCTION_PAY,
    "case_action#instructions_confirmed_by_payment_agent_at@_is_null": false,
    "case_action#completed_at@_is_null": true,
    "handler@_is_null": true,
};

const doNotPayFilter = {
    "instruction@_eq": INSTRUCTION_DO_NOT_PAY,
    "case_action#instructions_confirmed_by_payment_agent_at@_is_null": false,
    "case_action#completed_at@_is_null": true,
};
const acumassFilter = {
    "instruction@_eq": INSTRUCTION_PAY,
    "case_action#instructions_confirmed_by_payment_agent_at@_is_null": false,
    "case_action#completed_at@_is_null": true,
    "handler@_eq": HANDLER_ACUMASS,
};

const breezeFilter = {
    "instruction@_eq": INSTRUCTION_PAY,
    "case_action#instructions_confirmed_by_payment_agent_at@_is_null": false,
    "case_action#completed_at@_is_null": true,
    "handler@_eq": HANDLER_BREEZE,
};

export const InstructionsReceived = (props: any) => {
    const [value, setValue] = React.useState("received");
    const unselectAll = useUnselectAll("instructions_received");
    React.useEffect(() => {
        unselectAll()
    }, []);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        unselectAll();
    }

    return (
        <Page title={"Annuity & Renewal orders"}>
            <Box>Numbers indicate how many outstanding tasks there are to be performed at each stage</Box>
            <Tabs
                value={value}
                onChange={handleChange}
            >
                <Tab
                    value="received"
                    label={<Box>Received <Count filter={receivedFilter}></Count></Box>}
                />
                <Tab value="confirmed" label={<Box>Confirmed <Count filter={confirmedFilter}></Count></Box>} />
                <Tab value="acumass" label={<Box>Sent to Acumass <Count filter={acumassFilter}></Count></Box>} />
                <Tab value="breeze" label={<Box>Breeze Direct Payment <Count filter={breezeFilter}></Count></Box>} />
                <Tab value="donotpay" label={<Box>Do not pay <Count filter={doNotPayFilter}></Count></Box>} />
                <Tab value="paid" label="Instructions Paid/Completed" />
                <Tab value="all" label="ALL" />
                <Tab value="create-invoice" label={<Box>Create invoice <Count filter={createInvoiceFilter}></Count></Box>} />
                <Tab value="send-invoice" label={<Box>Send invoice <Count filter={sendInvoiceFilter}></Count></Box>} />
                <Tab value="invoice-history" label="Invoice history" />
            </Tabs>
            {
                value === "received" &&
                <Box>
                    <Alert severity="info" sx={{ maxWidth: "600px" }}>
                        <Box>Status: Order received</Box>
                        <Box>Task: Send confirmation</Box>
                    </Alert>
                    <List {...props}
                          sort={{ field: "created_at", order: "DESC" }}
                          filters={[
                              <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                                  <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                              </ReferenceArrayInput>,
                              <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                              <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                                  <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Organisation"
                                                     fullWidth/>
                              </ReferenceInput>,
                          ]}
                          filter={receivedFilter}
                          perPage={50}
                          exporter={instructionsExportToExcel}
                          pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                          empty={<HappyEmpty/>}
                    >
                        <Datagrid bulkActionButtons={
                            <>
                                <SendConfirmationEmailButton></SendConfirmationEmailButton>
                                <ConfirmInstructionsButton></ConfirmInstructionsButton>
                                <BulkExportButton></BulkExportButton>
                            </>
                        }>
                            {commonFields}
                            <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                                <Box sx={{minWidth: "600px"}}>
                                    <SimpleForm maxWidth={"sm"}>
                                        <TextInput source={"price_currency"} fullWidth />
                                        <NumberInput source={"official_fee"} fullWidth/>
                                        <NumberInput source={"our_fee"} fullWidth/>
                                        <NumberInput source={"agent_fee"} fullWidth/>
                                        <NumberInput source={"price"} fullWidth/>
                                    </SimpleForm>
                                </Box>
                            </EditInDialogButton>
                        </Datagrid>
                    </List>
                </Box>
            }

            {
                value === "confirmed" &&
                <Box>
                    <Alert severity="info" sx={{ maxWidth: "600px" }}>
                        <Box>Status: Confirmation sent</Box>
                        <Box>Task: Send instructions by email to payment agent (only Acumass atm) or mark as handled by Breeze</Box>
                    </Alert>
                    <List {...props}
                          sort={{ field: "created_at", order: "DESC" }}
                          filters={[
                              <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                                  <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                              </ReferenceArrayInput>,
                              <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                              <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                                  <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Organisation"
                                                     fullWidth/>
                              </ReferenceInput>,
                          ]}
                          filter={confirmedFilter}
                          perPage={50}
                          exporter={instructionsExportToExcel}
                          pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                          empty={<HappyEmpty></HappyEmpty>}
                    >

                        <Datagrid bulkActionButtons={
                            <>
                                <SetBreezeAsHandlerButton></SetBreezeAsHandlerButton>
                                <SendToAcumassEmailButton></SendToAcumassEmailButton>
                                <BulkExportButton></BulkExportButton>
                            </>
                        }>
                            {commonFields}
                            <ConfirmedField label="Confirmed"></ConfirmedField>
                            <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                                <Box sx={{minWidth: "600px"}}>
                                    <SimpleForm maxWidth={"sm"}>
                                        <TextInput source={"price_currency"} fullWidth />
                                        <NumberInput source={"official_fee"} fullWidth/>
                                        <NumberInput source={"our_fee"} fullWidth/>
                                        <NumberInput source={"agent_fee"} fullWidth/>
                                        <NumberInput source={"price"} fullWidth/>
                                    </SimpleForm>
                                </Box>
                            </EditInDialogButton>
                        </Datagrid>
                    </List>
                </Box>
            }

            {
                value === "donotpay" &&
                <Box>
                    <Alert severity="info" sx={{ maxWidth: "600px" }}>
                        <Box>Status: Confirmation sent</Box>
                        <Box>Task: Close case, remove annuities/renewals, remove future actions</Box>
                    </Alert>
                    <List {...props}
                          sort={{ field: "created_at", order: "DESC" }}
                          filters={[
                              <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                                  <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                              </ReferenceArrayInput>,
                              <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                              <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                                  <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Organisation"
                                                     fullWidth/>
                              </ReferenceInput>,
                          ]}
                          filter={doNotPayFilter}
                          perPage={50}
                          exporter={instructionsExportToExcel}
                          pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                          empty={<HappyEmpty></HappyEmpty>}
                    >
                        <Datagrid bulkActionButtons={
                            <>
                                <MarkAbandonButton></MarkAbandonButton>
                                <BulkExportButton></BulkExportButton>
                            </>
                        }>
                            {commonFields}
                            <ConfirmedField label="Confirmed"></ConfirmedField>
                            <FunctionField label={"Case closed?"} render={(record: any) => (
                                <Box sx={{ textWrap: "nowrap"}}>
                                    {
                                        record?.case_action?.case?.status === CASE_STATUS_CLOSED ?
                                            <span>{emojis.whiteCheckmark}</span> : <span>{emojis.redCircle}</span>
                                    }
                                    <span> {record?.case_action?.case?.status}</span>
                                </Box>
                            )}></FunctionField>
                            <WithRecord label="Remaining annuities" render={(record: any) => (
                                <ReferenceManyField label="Actions" reference="case_actions" target="case_id" record={{id: record?.case_action?.case?.id}}
                                                    sort={{field: "due_date", order: "ASC"}}
                                                    filter={{
                                                        "action_rule#action_type@_in": [ANNUITY, RENEWAL],
                                                        "annuity_payment_instructions_sent_at@_is_null": true,
                                                        "id@_neq": record?.case_action?.id,
                                                    }}>
                                    <WithListContext render={({ data, isLoading, total }) => (
                                        !isLoading &&
                                        <Box>
                                            <Box sx={{ textWrap: "nowrap"}} mb={2}>{total ? <span>{emojis.redCircle}</span> : <span>{emojis.whiteCheckmark}</span>} {total}</Box>
                                            {
                                                (data || []).map((record: any) => (
                                                    <Box sx={{ textWrap: "nowrap"}}>{moment(record?.due_date).format(dateFormat)}</Box>
                                                ))
                                            }
                                        </Box>
                                    )} />
                                </ReferenceManyField>
                            )}></WithRecord>
                            {/*<WithRecord label="Open case" render={(record: any) => (*/}
                            {/*    <CaseShowInDialog id={record?.case_action?.case?.id}>*/}

                            {/*    </CaseShowInDialog>*/}
                            {/*)}></WithRecord>*/}

                            <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                                <Box sx={{minWidth: "600px"}}>
                                    <SimpleForm maxWidth={"sm"}>
                                        <TextInput source={"price_currency"} fullWidth />
                                        <NumberInput source={"official_fee"} fullWidth/>
                                        <NumberInput source={"our_fee"} fullWidth/>
                                        <NumberInput source={"agent_fee"} fullWidth/>
                                        <NumberInput source={"price"} fullWidth/>
                                    </SimpleForm>
                                </Box>
                            </EditInDialogButton>
                        </Datagrid>
                    </List>
                </Box>
            }

            {
                value === "acumass" &&
                <Box>
                    <Alert severity="info" sx={{ maxWidth: "600px" }}>
                        <Box>Status: Email with instructions to Acumass sent</Box>
                        <Box>Task: Wait for Acumass confirmation and mark as paid when confirmation is received</Box>
                    </Alert>
                    <List {...props}
                          sort={{ field: "created_at", order: "DESC" }}
                          filters={[
                              <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                                  <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                              </ReferenceArrayInput>,
                              <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                              <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                                  <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Organisation"
                                                     fullWidth/>
                              </ReferenceInput>,
                          ]}
                          filter={acumassFilter}
                          perPage={50}
                          exporter={instructionsExportToExcel}
                          pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                          empty={<HappyEmpty></HappyEmpty>}
                    >
                        <Datagrid bulkActionButtons={
                            <>
                                <MarkPaidButton></MarkPaidButton>
                                <BulkExportButton></BulkExportButton>
                            </>
                        }>
                            {commonFields}
                            <ConfirmedField label="Confirmed"></ConfirmedField>
                            <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                                <Box sx={{minWidth: "600px"}}>
                                    <SimpleForm maxWidth={"sm"}>
                                        <TextInput source={"price_currency"} fullWidth />
                                        <NumberInput source={"official_fee"} fullWidth/>
                                        <NumberInput source={"our_fee"} fullWidth/>
                                        <NumberInput source={"agent_fee"} fullWidth/>
                                        <NumberInput source={"price"} fullWidth/>
                                    </SimpleForm>
                                </Box>
                            </EditInDialogButton>
                        </Datagrid>
                    </List>
                </Box>
            }


            {
                value === "breeze" &&
                <Box>
                    <Alert severity="info" sx={{ maxWidth: "600px" }}>
                        <Box>Status: Confirmation sent to client and marked as handled by Breeze</Box>
                        <Box>Task: Pay the annuity/renewal with the local Patent Office and mark as paid when done</Box>
                        <Box mt={2}>EP: For EP annuities, the official fee can only be paid 3 months before the due date</Box>
                    </Alert>
                    <List {...props}
                          sort={{ field: "created_at", order: "DESC" }}
                          filters={[
                              <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                                  <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                              </ReferenceArrayInput>,
                              <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                              <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                                  <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Organisation"
                                                     fullWidth/>
                              </ReferenceInput>,
                          ]}
                          filter={breezeFilter}
                          perPage={50}
                          exporter={instructionsExportToExcel}
                          pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                          empty={<HappyEmpty></HappyEmpty>}
                    >
                        <Datagrid bulkActionButtons={
                            <>
                                <MarkPaidButton></MarkPaidButton>
                                <BulkExportButton exporter={norwegianInstructionsExportToExcel} label={"Export Norwegian PO format"}></BulkExportButton>
                            </>
                        }>
                            {commonFields}
                            <ConfirmedField label="Confirmed"></ConfirmedField>
                            <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                                <Box sx={{minWidth: "600px"}}>
                                    <SimpleForm maxWidth={"sm"}>
                                        <TextInput source={"price_currency"} fullWidth />
                                        <NumberInput source={"official_fee"} fullWidth/>
                                        <NumberInput source={"our_fee"} fullWidth/>
                                        <NumberInput source={"agent_fee"} fullWidth/>
                                        <NumberInput source={"price"} fullWidth/>
                                    </SimpleForm>
                                </Box>
                            </EditInDialogButton>
                        </Datagrid>
                    </List>
                </Box>
            }


            {
                value === "paid" &&
                <Box>
                    <Alert severity="info" sx={{ maxWidth: "600px" }}>
                        <Box>Status: Marked Paid, either by confirmation from payment agent (Acumass) or by performing the payment ourselves</Box>
                        <Box>Task: No task – all work is done if invoiced</Box>
                    </Alert>
                    <List {...props}
                          sort={{ field: "created_at", order: "DESC" }}
                          filters={[
                              <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                                  <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                              </ReferenceArrayInput>,
                              <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                              <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                                  <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Organisation"
                                                     fullWidth/>
                              </ReferenceInput>,
                          ]}
                          filter={{
                              "instruction@_neq": INSTRUCTION_WAIT,
                              "instruction@_eq": INSTRUCTION_PAY,
                              "case_action#instructions_confirmed_by_payment_agent_at@_is_null": false,
                              "case_action#completed_at@_is_null": false,
                          }}
                          perPage={50}
                          pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                          empty={false}
                    >
                        <Datagrid bulkActionButtons={false}>
                            {commonFields}
                            <ConfirmedField label="Confirmed"></ConfirmedField>
                            <CompletedField label={"Paid"}></CompletedField>
                            <TextField source={"case_action.completion_notes"} label={"Notes"} />
                            <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                                <Box sx={{minWidth: "600px"}}>
                                    <SimpleForm maxWidth={"sm"}>
                                        <TextInput source={"price_currency"} fullWidth />
                                        <NumberInput source={"official_fee"} fullWidth/>
                                        <NumberInput source={"our_fee"} fullWidth/>
                                        <NumberInput source={"agent_fee"} fullWidth/>
                                        <NumberInput source={"price"} fullWidth/>
                                    </SimpleForm>
                                </Box>
                            </EditInDialogButton>
                        </Datagrid>
                    </List>
                </Box>
            }

            {
                value === "all" &&
                <List {...props}
                      sort={{ field: "created_at", order: "DESC" }}
                      filters={[
                          <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                              <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                          </ReferenceArrayInput>,
                          <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                          <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                              <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Organisation"
                                                 fullWidth/>
                          </ReferenceInput>,
                      ]}
                      filter={{
                          // "instruction@_neq": INSTRUCTION_WAIT,
                      }}
                      perPage={50}
                      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                >
                    <Datagrid bulkActionButtons={
                        false
                        // <BulkDeleteButton mutationMode="pessimistic"></BulkDeleteButton>
                    }>
                        {commonFields}
                        <ConfirmedField label="Confirmed"></ConfirmedField>
                        <CompletedField label={"Paid/Abandoned"}></CompletedField>
                        <TextField source={"case_action.completion_notes"} />
                        <EditInDialogButton fullWidth mutationMode={"pessimistic"} title={`Edit instruction`}>
                            <Box sx={{minWidth: "600px"}}>
                                <SimpleForm maxWidth={"sm"}>
                                    <TextInput source={"price_currency"} fullWidth />
                                    <NumberInput source={"official_fee"} fullWidth/>
                                    <NumberInput source={"our_fee"} fullWidth/>
                                    <NumberInput source={"agent_fee"} fullWidth/>
                                    <NumberInput source={"price"} fullWidth/>
                                </SimpleForm>
                            </Box>
                        </EditInDialogButton>
                    </Datagrid>
                </List>
            }


            {
                value === "create-invoice" &&
                <Box>
                    <Alert severity="info" sx={{ maxWidth: "600px" }}>
                        <Box>Status: Order received</Box>
                        <Box>Task: Create invoice.</Box>
                        <Box>This step does not send the invoice but creates it with the correct next invoice number from our sequence</Box>
                    </Alert>
                    <List {...props}
                          sort={{ field: "created_at", order: "DESC" }}
                          filters={[
                              <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                                  <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                              </ReferenceArrayInput>,
                              <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                              <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                                  <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Organisation"
                                                     fullWidth/>
                              </ReferenceInput>,
                          ]}
                          filter={createInvoiceFilter}
                          perPage={50}
                          pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                          empty={<HappyEmpty></HappyEmpty>}
                          exporter={invoiceExportToExcel}
                    >
                        <Datagrid bulkActionButtons={<>
                            <BulkCreateInvoicesButton></BulkCreateInvoicesButton>
                            <MarkInvoiceSent></MarkInvoiceSent>
                            <BulkExportButton></BulkExportButton>
                        </>}>
                            {commonFields}
                            <ConfirmedField label="Confirmed"></ConfirmedField>
                            <CompletedField label={"Paid"}></CompletedField>
                            <TextField source={"case_action.completion_notes"} label={"Notes"}/>
                            <CreateInvoice></CreateInvoice>
                        </Datagrid>
                    </List>
                </Box>
            }
            {
                value === "send-invoice" &&
                <Box>
                    <Alert severity="info" sx={{ maxWidth: "600px" }}>
                        <Box>Status: Invoice created</Box>
                        <Box>Task: Send invoice by email to the client with invoice pdf as attachment(s)</Box>
                    </Alert>
                    <List {...props}
                          sort={{ field: "created_at", order: "DESC" }}
                          filters={[
                              <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                                  <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                              </ReferenceArrayInput>,
                              <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                              <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                                  <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Organisation"
                                                     fullWidth/>
                              </ReferenceInput>,
                          ]}
                          filter={sendInvoiceFilter}
                          perPage={50}
                          pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                          empty={<HappyEmpty></HappyEmpty>}
                          exporter={invoiceExportToExcel}
                    >
                        <Datagrid bulkActionButtons={<>
                            <SendInvoiceEmailButton></SendInvoiceEmailButton>
                            <MarkInvoiceSent></MarkInvoiceSent>
                            <BulkExportButton></BulkExportButton>
                        </>}>
                            {commonFields}
                            <ConfirmedField label="Confirmed"></ConfirmedField>
                            {/*<DateField label="Paid/Completed at" source={"case_action.completed_at"} showTime />*/}
                            {/*<ReferenceField reference="users" source={"case_action.completed_by"} label={"Completed by"} link={false}>*/}
                            {/*    <TextField source={"name"}/>*/}
                            {/*</ReferenceField>*/}
                            <CompletedField label={"Paid"}></CompletedField>
                            <TextField source={"case_action.completion_notes"} label={"Notes"}/>
                            <InvoiceField label={"Invoice"}></InvoiceField>
                            <FunctionField render={(record: any) =>
                                <ShowEmailInDialogButton id={record?.invoice?.invoice_email_id}></ShowEmailInDialogButton>
                            }></FunctionField>
                        </Datagrid>
                    </List>
                </Box>
            }
            {
                value === "invoice-history" &&
                <Box>
                    <Alert severity="info" sx={{ maxWidth: "600px" }}>
                        <Box>Status: Invoice sent (either manually or via the system)</Box>
                        <Box>Task: No next task</Box>
                    </Alert>
                    <List {...props}
                          sort={{ field: "invoiced_at", order: "DESC" }}
                          filters={[
                              <ReferenceArrayInput label="Instruction" reference="instructions_received_event" source="instructions_received_event_id" sort={{field: "created_at", order: "DESC" }} alwaysOn>
                                  <SelectInput label={"Instruction"} optionText={(record: any) => `${moment(record.created_at).format("DD.MM.YY HH:mm")} – ${record.instructions_received?.[0]?.case_action?.case?.tenant?.name || ""} – ${record.user?.name || ""}`}/>
                              </ReferenceArrayInput>,
                              <SelectInput source="instruction@_eq" alwaysOn choices={toChoices([INSTRUCTION_PAY, INSTRUCTION_DO_NOT_PAY])}></SelectInput>,
                              <ReferenceInput source="case_action#case#tenant_id" reference="tenants" alwaysOn>
                                  <AutocompleteInput filterToQuery={(searchText: string) => ({name: `${searchText}`})} label="Organisation"
                                                     fullWidth/>
                              </ReferenceInput>,
                          ]}
                          filter={{
                              "instruction@_nin": [INSTRUCTION_WAIT, INSTRUCTION_DO_NOT_PAY],
                              "invoiced_at@_is_null": false,
                          }}
                          perPage={50}
                          pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
                          empty={false}
                          exporter={invoiceExportToExcel}
                    >
                        <Datagrid bulkActionButtons={<>
                            <BulkExportButton></BulkExportButton>
                        </>}>
                            {commonFields}
                            <NumberField source={"our_fee"}></NumberField>
                            <NumberField source={"agent_fee"}></NumberField>
                            <ConfirmedField label="Confirmed"></ConfirmedField>
                            <CompletedField label={"Paid/Completed"}></CompletedField>
                            {/*<DateField label="Paid/Completed at" source={"case_action.completed_at"} showTime />*/}
                            <TextField source={"case_action.completion_notes"} label={"Notes"}/>
                            <DateField label="Invoiced at" source={"invoiced_at"} showTime />
                            <InvoiceField label={"Invoice"}></InvoiceField>
                            <ReferenceField reference="users" source={"invoiced_by"} label={"Invoiced by"} link={false}>
                                <TextField source={"name"}/>
                            </ReferenceField>
                        </Datagrid>
                    </List>
                </Box>
            }
        </Page>
    );
};

const InstructionField = (props: any) => {
    const record = useRecordContext();
    const color = record?.instruction === INSTRUCTION_PAY ? "primary" :
        record?.instruction === INSTRUCTION_DO_NOT_PAY ? "error" : "secondary";

    return <ChipField source={"instruction"} color={color} {...props}></ChipField>
}

const InstructedByField = (props: any) => (
    <WrapperField label={"Instructed by"} {...props}>
        <Box><DateField label="Instructed at" source={"created_at"} showTime sx={{ textWrap: "nowrap" }}/></Box>
        <Box><TextField source={"user.name"} sx={{ textWrap: "nowrap" }}></TextField></Box>
        <Box><TextField source={"case_action.case.tenant.name"} sx={{ textWrap: "nowrap" }}></TextField></Box>
    </WrapperField>
);

const commonFields = [
    <TextField label="Organisation" source={"case_action.case.tenant.name"} ></TextField>,
    <FunctionField label={"Order #"} render={(record: any) => record?.instructions_received_event?.id?.split("-")[0]?.toUpperCase()} sortBy={"id"} />,
    <FunctionField label={"Case"} render={(record: any) => {
        return (
            <Box>
                <Box sx={{ textWrap: "nowrap" }}>
                    <CaseLink caseId={record?.case_action?.case?.id} linkText={record?.case_action?.case?.case_ref}></CaseLink>
                </Box>
                <Box sx={{ textWrap: "nowrap" }}><TextField label={"Applicant"} source={"case_action.case.tenant_case_ref"} /></Box>
                <Box><FlagField record={record?.case_action?.case} source={"country_code"} sx={{ textWrap: "nowrap" }}></FlagField></Box>
                <Box sx={{ textWrap: "nowrap" }}>A#: {record?.case_action?.case.application_number}</Box>
                <Box sx={{ textWrap: "nowrap" }}>R#: {record?.case_action?.case.registration_number}</Box>
            </Box>
        );
    }} />,
    // <FunctionField
    //     label="Case ref"
    //     sx={{ textWrap: "nowrap" }}
    //     render={(record: any) => record ?
    //         <Link
    //             to={`/cases/${record?.case_action?.case?.id}/show`}
    //             target={"_blank"}
    //         >
    //             {record?.case_action?.case?.case_ref}
    //         </Link> : null
    //     }
    // />,
    // <TextField label={"Client ref"} source={"case_action.case.tenant_case_ref"} ></TextField>,
    // <FunctionField
    //     label="Country"
    //     sx={{ textWrap: "nowrap" }}
    //     render={(record: any) => record?.case_action?.case?.country_code ?
    //         <Box><Flag countryCode={record.case_action.case.country_code === "up" ? "eu" : record.case_action.case.country_code}></Flag>{"  " + countries[record.case_action.case.country_code]}</Box> : null
    //     }
    // />,
    // <TextField source="case_action.case.application_number" label={"Application number"} />,
    // <TextField source="case_action.case.registration_number" label={"Registration number"}/>,
    <TextField source="case_action.action_rule.action_name" label={"Annuity"} />,
    <DateField source="case_action.due_date" label={"Due date"} />,
    <DateField source="case_action.with_fine_due_date" label={"Grace date"} />,
    <InstructionField label={"Instruction"}></InstructionField>,
    <TextField source={"official_fee"}></TextField>,
    <TextField source={"our_fee"}></TextField>,
    <TextField source={"agent_fee"}></TextField>,
    <FunctionField
        label="Price"
        sx={{ textWrap: "nowrap" }}
        render={(record: any) => {
            const priceMismatch = record?.price !== record?.portal_price;
            return record.price ? `${record.price} ${record.price_currency}${priceMismatch ? " NB – Mismatch" : ""}` : "N/A";
        }}
    />,
    <TextField source={"portal_price"}></TextField>,
    <InstructedByField label={"Instructed"}></InstructedByField>,
    // <TextField source={"user.name"} label={"Instructed by"}></TextField>,
    // <DateField label="Instructed at" source={"created_at"} showTime />,
];

const ConfirmedField = (props: any) => (
    <WrapperField label={"Confirmed"} {...props}>
        <DateField label="Confirmed at" source={"case_action.instructions_confirmed_by_payment_agent_at"} showTime sx={{ textWrap: "nowrap" }}/>
        <ReferenceField reference="users" source={"case_action.instructions_confirmed_by_payment_agent_by"} link={false}>
            <TextField source={"name"} sx={{ textWrap: "nowrap", display: "block" }} />
        </ReferenceField>
        <WithRecord render={(record: any) =>
            record?.confirmation_email_id &&
            <ShowEmailInDialogButton id={record.confirmation_email_id}></ShowEmailInDialogButton>
        }></WithRecord>
    </WrapperField>
);
const CompletedField = (props: any) => {
    const record = useRecordContext();
    if (!record?.case_action?.completed_at) {
        return null;
    }

    const handler = record?.handler === HANDLER_ACUMASS ? "Acumass" : "Breeze";
    const variant = record?.handler === HANDLER_ACUMASS ? "outlined" : "filled";

    return (
        <WrapperField label={"Paid/Abandoned"} {...props}>
            {
                record?.case_action.annuity_abandoned_at ?
                    <Box sx={{ textWrap: "nowrap" }}><Chip label={"Abandoned"} color={"error"}></Chip></Box> :
                    <Box sx={{ textWrap: "nowrap" }}><Chip label={`Paid by ${handler}`} color={"primary"} variant={variant}></Chip></Box>
            }
            {
                record?.case_action.annuity_abandoned_at ?
                    <DateField source={"case_action.annuity_abandoned_at"} showTime sx={{ textWrap: "nowrap" }}/> :
                    <DateField source={"case_action.completed_at"} showTime sx={{ textWrap: "nowrap" }}/>
            }
            <ReferenceField reference="users" source={record?.case_action.annuity_abandoned_at ? "case_action.annuity_abandoned_by" : "case_action.completed_by"} link={false}>
                <TextField source={"name"} sx={{ textWrap: "nowrap", display: "block" }} />
            </ReferenceField>

            {
                record?.acumass_email_id ? <ShowEmailInDialogButton id={record.acumass_email_id} label={"Acumass email"}> </ShowEmailInDialogButton> : null
            }
        </WrapperField>
    );
};

const InvoiceField = (props: any) => {
    const record = useRecordContext();
    const refresh = useRefresh();

    if (!record?.invoice_id) {

    }


    return (
        <WrapperField label={"Invoice"} {...props}>
            <WithRecord render={(record: any) =>
                record?.invoice?.created_at ?
                    <DateField label="Invoiced at" source={"invoice.created_at"} showTime sx={{ textWrap: "nowrap" }}/> :
                    <DateField label="Invoiced at" source={"invoiced_at"} showTime sx={{ textWrap: "nowrap" }}/>
            }></WithRecord>
            <ReferenceField reference="users" source={"invoice.created_by"} link={false}>
                <TextField source={"name"} sx={{ textWrap: "nowrap", display: "block" }} />
            </ReferenceField>
            <WithRecord render={(record: any) =>
                record?.invoice?.invoice_email_id &&
                <ShowEmailInDialogButton id={record.invoice?.invoice_email_id}></ShowEmailInDialogButton>
            }></WithRecord>
            <ShowPDFInvoiceInDialogButton></ShowPDFInvoiceInDialogButton>
            <ShowInvoiceInDialogButton></ShowInvoiceInDialogButton>
            {/*<EditInvoiceInDialogButton></EditInvoiceInDialogButton>*/}
            {
                record?.invoice_id &&
                <DeleteButton
                    mutationMode={"pessimistic"}
                    resource={"invoices"}
                    record={record?.invoice}
                    label={`Delete invoice`}
                    mutationOptions={{ onSuccess: () => refresh() }}
                    redirect={false}
                />
            }
        </WrapperField>
    );
};


export const ShowEmailInDialogButton = (props: any) => {
    const id = props.id;

    if (!id) return null;

    return (
        <ShowInDialogButton
            fullWidth maxWidth="md" resource={"emails"} id={id}
            label={"View Email"}
            title={<WithRecord render={(record: any) => (
                <Box>
                    {
                        record.sending_status === "SENT" ?
                            `${emojis.whiteCheckmark} Email sent ${moment(record.created_at).format("lll")}` :
                            `${emojis.redCircle} Failed to send email (attempted to send ${moment(record.created_at).format("lll")})`
                    }
                </Box>
            )}></WithRecord>}
            {...props}
        >

            <WithRecord render={(record: any) => (
                <DialogContent>
                    <Stack spacing={1} mb={2}>
                        <Box><strong>To:</strong> {record?.recipient_email}</Box>
                        <Box><strong>CC:</strong> {record?.recipient_cc_emails?.join(", ")}</Box>
                        <Box><strong>BCC:</strong> {record?.recipient_bcc_emails?.join(", ")}</Box>
                    </Stack>
                    <Box mb={5}><strong>Subject:</strong> {record?.subject}</Box>
                    <Box mb={2}><strong>Email:</strong></Box>
                    <div dangerouslySetInnerHTML={{ __html: record?.body }} />
                </DialogContent>
            )}/>
        </ShowInDialogButton>
    );
}

const ShowInvoiceInDialogButton = (props: any) => {
    const record = useRecordContext();
    if (!record?.invoice_id) return null;

    return (
        <ShowInDialogButton
            resource={"invoices"}
            id={record?.invoice_id}
            fullWidth maxWidth="lg"
            label={"HTML Invoice"}
            title={"Invoice"}
        >

            <WithRecord render={(record: any) => (
                <DialogContent>
                    <Box mb={2}><strong>Html Invoice:</strong></Box>
                    {/*<div dangerouslySetInnerHTML={{ __html: record?.id }} />*/}
                    <div dangerouslySetInnerHTML={{ __html: record?.html }} />
                </DialogContent>
            )}/>
        </ShowInDialogButton>
    );
}

const ShowPDFInvoiceInDialogButton = (props: any) => {
    const record = useRecordContext();
    if (!record?.invoice_id) return null;

    return (
        <ShowInDialogButton
            resource={"invoices"}
            id={record?.invoice_id}
            fullWidth maxWidth="lg"
            label={"PDF Invoice"}
            title={"Invoice"}
        >

            <WithRecord render={(record: any) => (
                <DialogContent>
                    <Box mb={2}><strong>PDF Invoice:</strong></Box>
                    <Box height={"1000px"}>
                        <object width="100%" height={"100%"} data={`data:application/pdf;base64,${record?.pdf}`} type="application/pdf"></object>
                    </Box>
                </DialogContent>
            )}/>
        </ShowInDialogButton>
    );
}


const EditInvoiceInDialogButton = (props: any) => {
    const record = useRecordContext();
    if (!record?.invoice_id) return null;

    return (
        <EditInDialogButton
            resource={"invoices"}
            id={record?.invoice_id}
            fullWidth maxWidth="lg"
            label={"Edit Invoice"}
            title={"Invoice"}
            mutationMode={"pessimistic"}
        >
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} width={"100%"}>
                            <SaveButton
                                label={"Save"}
                                alwaysEnable
                                icon={<></>}
                                variant={"text"}
                            />
                            <DeleteButton mutationMode="pessimistic" resource={"invoices"} redirect={""}></DeleteButton>
                        </Stack>
                    </Toolbar>
                }
            >
                <TextField source={"id"} />
            </SimpleForm>
        </EditInDialogButton>
    );
}

const InvoicePreview = (props: any) => {
    const [previewHtml, setPreviewHtml] = React.useState<string | null>(null);

    React.useEffect(() => {

    }, []);
    return (
        previewHtml ? <div dangerouslySetInnerHTML={{ __html: previewHtml }} /> : null
    )
}

