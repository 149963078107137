import { ReferenceManyInput } from "@react-admin/ra-relationships";
import * as React from "react";
import {
    Edit,
    Datagrid,
    DateField,
    TextInput,
    EditButton,
    ShowButton,
    SingleFieldList,
    ChipField,
    ReferenceManyField,
    ReferenceField,
    TextField,
    SimpleForm,
    SimpleFormIterator,
    ReferenceArrayInput,
    AutocompleteInput,
    SelectInput,
} from 'react-admin';


export const UserEdit = (props: any) => (
    <Edit {...props} mutationMode="pessimistic">
        <SimpleForm>
            <TextField source="id"/>
            <DateField source={"created_at"}/>
            <DateField source={"updated_at"}/>
            <TextInput source={"name"}/>
            <TextInput source="email"/>
            <TextInput source="external_auth_id"/>
            <ReferenceManyInput label="Access to clients" reference="tenant_users" target="user_id">
                <SimpleFormIterator inline>
                    <ReferenceArrayInput label="Organisation" reference="tenants" source="tenant_id" isRequired>
                        <AutocompleteInput label="Organisation" filterToQuery={(searchText: string) => ({name: `${searchText}`})} sx={{ minWidth: 200 }}/>
                    </ReferenceArrayInput>
                </SimpleFormIterator>
            </ReferenceManyInput>
        </SimpleForm>
    </Edit>
);
